import { gql } from "@apollo/client";
import { CONFIGUREABLE_PRODUCT_FRAGMENT, DOWNLOADABLE_FRAGMENT, PRICE_FRAGMENT } from "../fragments";

export const WISHLIST_FRAGMENT = gql`
fragment WishlistsFragment on Wishlist {
  id
  items_count
  items_v2(currentPage: $currentPage, pageSize: $pageSize) {
    page_info {
      current_page
      page_size
      total_pages
    }
    items {
      id
      product {
        id
        name
        sku
        canonical_url
        brand
        eu_norm_60: itg_202402051411156623960939
        stock_status
        energy_efficiency_stage
        energy_efficiency_stage2
        energy_efficiency_label_1 {
          url
        }
        small_image{
                url
        }
        energy_efficiency_label_2 {
          url
        }
        price_range {
          maximum_price {
            discount {
              amount_off
              percent_off
            }
            final_price {
              currency
              value
            }
            fixed_product_taxes {
              amount {
                currency
                value
              }
              label
            }
            regular_price {
              currency
              value
            }
          }
        }
        custom_stock_item {
          qty_increments
          min_sale_qty
          qty
        } 

        rating_summary
        custom_attributes {
          attribute_metadata {
            code
            label
            ui_input {
              ui_input_type
            }
            storefront_properties {
              visible_on_catalog_pages
            }
          }
          entered_attribute_value {
            value
          }
          selected_attribute_options {
            attribute_option {
              label
              swatch_image
            }
          }
        }
        __typename
        url_key
      }
    }
  }
  __typename
}
`

export const WISHLIST_FRAGMENT_FOR_MUTATIONS = gql`
fragment WishlistsFragmentM on Wishlist {
  id
  items_count
  items_v2 {
    page_info {
      current_page
      page_size
      total_pages
    }
    items {
      id
      product {
        id
        name
        sku
        canonical_url
        brand
        eu_norm_60: itg_202402051411156623960939
        stock_status
        energy_efficiency_stage
        energy_efficiency_stage2
        energy_efficiency_label_1 {
          url
        }
        small_image{
                url
        }
        energy_efficiency_label_2 {
          url
        }
        price_range {
          maximum_price {
            discount {
              amount_off
              percent_off
            }
            final_price {
              currency
              value
            }
            fixed_product_taxes {
              amount {
                currency
                value
              }
              label
            }
            regular_price {
              currency
              value
            }
          }
        }
        custom_stock_item {
          qty_increments
          min_sale_qty
          qty
        } 

        rating_summary
        custom_attributes {
          attribute_metadata {
            code
            label
            ui_input {
              ui_input_type
            }
            storefront_properties {
              visible_on_catalog_pages
            }
          }
          entered_attribute_value {
            value
          }
          selected_attribute_options {
            attribute_option {
              label
              swatch_image
            }
          }
        }
        __typename
        url_key
      }
    }
  }
  __typename
}
`
