import { useRouter } from "next/router";
import { useEffect } from "react";
import NProgress from 'nprogress'
import { useCacheStore } from "../stores/cacheStore";


const useRouterEvents = () => {
    const router = useRouter()



    useEffect(() => {
        const handleRouteChangeStart = (url, { shallow }) => {
            if (!shallow) {
                NProgress.start();
            }
        };

        const handleRouteChangeComplete = (url, { shallow }) => {
            if (!shallow) {
                NProgress.done();
            }
        };

        const handleRouteChangeError = () => {
            NProgress.done();
        };

        router.events.on("routeChangeStart", handleRouteChangeStart);
        router.events.on("routeChangeComplete", handleRouteChangeComplete);
        router.events.on("routeChangeError", handleRouteChangeError);

        return () => {
            router.events.off("routeChangeStart", handleRouteChangeStart);
            router.events.off("routeChangeComplete", handleRouteChangeComplete);
            router.events.off("routeChangeError", handleRouteChangeError);
        }
    }, []);

    const storeConfig = useCacheStore(s => s.storeConfig)
    useEffect(() => {
        if (router.locale && storeConfig && router.locale !== storeConfig.store_code) {
            useCacheStore.setState({ storeConfig: null, availableStores: null, navbarCategories: null, footer: null, navbarSpecialCategories: null, countries: null })
        }
    }, [router.locale, storeConfig])

    return router
}

export default useRouterEvents;