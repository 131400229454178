import { Grid, Tooltip } from "@mui/material";
import styles from "../../styles/product-card.module.scss";
import CheckCricleIcon from "../../components/icons/check";
import CloseIcon from "@mui/icons-material/Close";
import useTranslation from "../../hooks/useTranslation";
import WarningIcon from '@mui/icons-material/Warning';

const OrangeStatus = ({ status, justify }) => {
    return (
        <Grid container justifyContent={justify} >
            <Tooltip title={status} placement="top-start">
                <span className={styles.stack + " yellow fw-600 fs-13" + " " + styles.yellow}>
                    {status}
                </span>
            </Tooltip>
            <WarningIcon
                className="yellow"
                sx={{
                    width: "1.2rem",
                    height: "1.2rem",
                    ml: "4px",
                }}
            />
        </Grid>
    )
}

const GreenStatus = ({ status, justify }) => {
    return (
        <Grid container justifyContent={justify} mt="2px">
            <span className={styles.stack + " light-green fs-13 fw-600"}>
                {status}
            </span>
            <CheckCricleIcon
                className="light-green fs-13"
                height="16"
                width="16"
                style={{ marginTop: "0.1rem", marginLeft: "0.3rem" }}
            />
        </Grid>
    )
}

const RedStatus = ({ status, justify }) => {
    return (
        <Grid container justifyContent={justify}>
            <span className={styles.stack + " red fw-600 fs-13"}>
                {status}
            </span>
            <CloseIcon
                className={"bg-red f-white"}
                sx={{
                    borderRadius: "50%",
                    width: "0.9rem",
                    height: "0.9rem",
                    p: "1.8px",
                    ml: "4px",
                    mt: "0.2rem",
                }}
            />
        </Grid>
    );
}

const Stockstate = ({ product, justify = "flex-end" }) => {
    const { t } = useTranslation();
    let color = 'red'
    let status = t("Sold out")

    color = (product.stock_color_label || product.custom_attributes?.find((obj) => obj.attribute_metadata.code === "stock_color")?.selected_attribute_options.attribute_option.at(0).label || "")
    status = (product.stock_text || product.custom_attributes?.find((obj) => obj.attribute_metadata?.code === "stock_text")?.entered_attribute_value?.value)

    switch (color) {
        case "orange":
            return <OrangeStatus status={status} justify={justify} />
        case "green":
            return <GreenStatus status={status} justify={justify} />
        default:
            return <RedStatus status={t("Sold out")} justify={justify} />
    }
};

export default Stockstate;
