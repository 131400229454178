import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { useSnackbar } from 'notistack';
import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { useState } from 'react';
import { ADD_BUNDLE_PRODUCT_TO_CART, ADD_PRODUCT_TO_CART, ADD_PROMO_ITEM, APPLY_COUPON_TO_CART, CANCEL_WALLEE, CREATE_GUEST_CART, CREATE_PAYPAL_EXPRESS_TOKEN, MERGE_CARTS, PLACE_ORDER, REMOVE_COUPON_FROM_CART, REMOVE_PRODUCT_FROM_CART, REMOVE_PROMO_ITEM, RESTORE_WALLEE, SET_BILLING_ADDRESS_ON_CART, SET_CUSTOMER_BILLING_ADDRESS_ON_CART, SET_CUSTOMER_SHIPPING_ADDRESS_ON_CART, SET_DILEVERY_DATE_AND_TIME, SET_EMAIL_ON_GUEST_CART, SET_PAYMENT_METHOD, SET_PAYPAL_ON_CART, SET_SHIPPING_ADDRESS_ON_CART, SET_SHIPPING_METHOD_ON_CART, SUCCESS_WALLEE, UPDATE_ALL_CART_ITEMS, UPDATE_CART_DONATION, UPDATE_CART_INSURANCE, UPDATE_CART_ITEMS, UPDATE_CART_ITEMS_SHIPPING_OPTION, UPDATE_CART_ITEM_SHIPPING_OPTION, UPDATE_CART_ITEM_WARRANTY_OPTION, UPDATE_CART_OLD_ITEM_BACK, UPDATE_CART_STORE_CODE, WALLEE_SET_TRANSACTION_URLS } from '../graphql/cart/mutations';
import { CHECKOUT_AGREEMENT, CUSTOM_DELIVERY_TIME, GET_CART_DETAILS, GET_CART_PRICES, GET_FREE_ITEMS, GET_PAYENGINE_FUNC } from '../graphql/cart/queries';
import useTranslation from '../hooks/useTranslation';
import { GetPostSperrgutItems, HasOrangeProduct, realItems, selectedShippingMethodsSet } from "../helpers/cart"
import PopUp from '../components/pop-up/popUp';
import { CustomerContext } from './CustomerProvider';
import { validAdress } from '../helpers/validation';
import moment from 'moment';
import { useRouter } from 'next/router';
import { useAuthStore } from '../stores/authStore';
import cookie from 'react-cookies';
import { getCookie } from '../helpers/cookie';
import { usePaymentStore } from '../stores/paymentStore';
import { useGlobalStore } from "../stores/globalStore";
import { useCacheStore } from '../stores/cacheStore';


export const CartContext = React.createContext();

export const CartProvider = props => {
    const router = useRouter()
    const [orderNum, setOrderNum] = useState(null)
    const [orderGuestCred, setOrderGuestCred] = useState()
    const cartId = getCookie("guestCartID") || getCookie("customerCartID")
    const [open, setOpen] = useState(false)
    const [addLoading, setAddLoading] = useState(false)
    const [productInfo, setProductInfo] = useState()
    const [skip1, setSkip1] = useState(true)
    const [skip2, setSkip2] = useState(true)
    const [skip4, setSkip4] = useState(true)
    const [skipCustomDeliveryData, setSkipCustomDeliveryData] = useState(false)
    const [uidsToCompare, setUidsToCompare] = useState()
    const isAuthenticated = useAuthStore(state => Boolean(state.token))
    const authReady = useAuthStore(state => Boolean(state.ready))
    const token = useAuthStore(state => state.token)
    const guestEmail = useGlobalStore(state => state.guestEmail)
    const setGuestEmail = useGlobalStore(state => state.setGuestEmail)
    const [googleCartItems, setGoogleCartItems] = useState([])
    const [orderTotal, setOrderTotal] = useState(null)


    const { customerData, refetchCustomerData } = useContext(CustomerContext)
    const [customerCartID, setCustomerCartID] = useState('');
    const [guestCartID, setGuestCartID] = useState('');
    const [customDeliveryTime, setCustomDeliveryTime] = useState({})
    const [checkoutAgreements, setCheckoutAgreements] = useState({})
    const [freeItems, setFreeItems] = useState()
    const [shippingStep, setShippingStep] = useState(false)
    const [cartPrices, setCartPrices] = useState({})
    const [processStep, setProcessStep] = useState("")
    const [proccessStepLock, setProccessStepLock] = useState(false)
    const [readyToPay, setReadyToPay] = useState(false)
    const [count, setCount] = useState(0)
    const [enabledTransport, setEnabledTransport] = useState(false)
    const [times, setTimes] = useState({ floor_delivery_date: "", floor_delivery_time: "", delivery_date: "", delivery_time: "" })
    const [selectedShippingMethod, setSelectedShippingMethod] = useState({})
    const [isPickup, setIsPickup] = useState(null)
    const [popupErrorMessage, setPopupErrorMessage] = useState('')
    const [paymentMethods, setPaymentMethods] = useState([])
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation()

    const [cart, setCart] = useState({ items: [] })
    const [createEmptyCart] = useMutation(CREATE_GUEST_CART, {
        onCompleted: (data) => {
            if (!isAuthenticated) {
                setGuestCartID(data.cart_id)
                cookie.save('guestCartID', data.cart_id, { path: "/", maxAge: 2 * 60 * 60 })
                setCustomerCartID('')
                cookie.save('customerCartID', "", { path: "/" })
            } else {
                setGuestCartID("")
                cookie.save('guestCartID', "", { path: "/" })
                setCustomerCartID(data.cart_id)
                cookie.save('customerCartID', data.cart_id, { path: "/", maxAge: 23 * 60 * 60 })
            }
        },
        onError: (err) => {
            enqueueSnackbar(err.message, { variant: 'error' })
        }
    })
    const empty = { data: {}, loading: false, error: false }

    // wallee
    const paymentOngoing = usePaymentStore(state => state.paymentOngoing)

    const [setTransactionUrls, { data: SetTransactionUrlsData, loading: SetTransactionUrlsLoading }] = useMutation(WALLEE_SET_TRANSACTION_URLS)
    const [cancelWallee, { data: cancelData, loading: cancelWalleeLoading }] = useMutation(RESTORE_WALLEE)
    const [successWallee, { data: waleeData }] = useMutation(SUCCESS_WALLEE)
    const skipOnWalleeCancel = router.asPath.includes("wallee_payment/transaction/failure") || cancelWalleeLoading

    const storeConfig = useCacheStore(state => state.storeConfig)
    const storeData = { storeConfig: storeConfig }

    const { data: data1, loading: cartLoading, error: cartError } = useQuery(GET_CART_DETAILS, { variables: { cart_id: (isAuthenticated ? customerCartID : guestCartID), count: count }, skip: skip1 || paymentOngoing || skipOnWalleeCancel || (isAuthenticated ? !customerCartID : !guestCartID), fetchPolicy: 'network-only', context: { fetchOptions: { method: "POST" } } })
    const { data: data2 } = useQuery(GET_CART_PRICES, { variables: { cart_id: (isAuthenticated ? customerCartID : guestCartID), count: count }, skip: skip2 || paymentOngoing || (isAuthenticated ? !customerCartID : !guestCartID), fetchPolicy: 'network-only', context: { fetchOptions: { method: "POST" } } })
    const { data: deliveryTimeData } = useQuery(CUSTOM_DELIVERY_TIME, { skip: skipCustomDeliveryData || paymentOngoing })
    const { data: listFreeItems, loading: listFreeItemsLoading, error: listFreeItemsError } = useQuery(GET_FREE_ITEMS, { variables: { cartId: (isAuthenticated ? customerCartID : guestCartID), count }, skip: skip4 || paymentOngoing, fetchPolicy: 'network-only', context: { fetchOptions: { method: "POST" } } })
    const [mergeCarts, { data: mergeData }] = useMutation(MERGE_CARTS)
    const [addProductsToCart, { data: addData, loading: addProductLoading }] = useMutation(ADD_PRODUCT_TO_CART)
    const [addBundleProductsToCart, { data: addBundleData, loading: addBundleProductLoading }] = useMutation(ADD_BUNDLE_PRODUCT_TO_CART);
    const [removeItemFromCart, { data: removeData, loading: removeProductLoading }] = useMutation(REMOVE_PRODUCT_FROM_CART)
    const [updateCartItem, { data: updateData, loading: updateCartLoading }] = useMutation(UPDATE_CART_ITEMS)
    const [updateAllCartItem, { data: updateAllCartData, loading: updateAllCartLoading }] = useMutation(UPDATE_ALL_CART_ITEMS)
    const [applyCouponToCart, { data: couponData, loading: couponLoading }] = useMutation(APPLY_COUPON_TO_CART)
    const [removeCouponFromCart, { data: couponRemoveData, loading: removeCouponLoading }] = useMutation(REMOVE_COUPON_FROM_CART)
    const [setShippingAddressesOnCart, { data: ssaoCart, loading: ssaoCartLoading, error: ssaoCartError }] = useMutation(SET_SHIPPING_ADDRESS_ON_CART)
    const [setCustomerShippingAddressesOnCart, { data: scsaoCart, loading: scsaoCartLoading, error: scsaoCartError }] = useMutation(SET_CUSTOMER_SHIPPING_ADDRESS_ON_CART)
    const [setBillingAddressOnCart, { data: sbaoCart, loading: sbaoCartLoading }] = useMutation(SET_BILLING_ADDRESS_ON_CART)
    const [setCustomerBillingAddressOnCart, { data: scbaoCart, loading: scbaoCartLoading }] = useMutation(SET_CUSTOMER_BILLING_ADDRESS_ON_CART)
    const [setShippingMethodsOnCart, { data: ssmoCart, loading: ssmoCartLoading }] = useMutation(SET_SHIPPING_METHOD_ON_CART)
    const [setGuestEmailOnCart, { data: sgeoCart, loading: segoCartLoading }] = useMutation(SET_EMAIL_ON_GUEST_CART)
    const [setPaymentMethodOnCart, { data: spmCart, loading: spmCartLoading }] = useMutation(SET_PAYMENT_METHOD)
    const [placeOrder, { data: poData, placeOrderLoading }] = useMutation(PLACE_ORDER, { variables: { cart_id: cartId } })
    const [updateCartItemWarranty, { data: updateCartWarrantyData, loading: updateCartWarrantyLoading }] = useMutation(UPDATE_CART_ITEM_WARRANTY_OPTION)
    const [updateCartInsurance, { data: updateCartInsuranceData, loading: updateCartInsuranceLoading }] = useMutation(UPDATE_CART_INSURANCE)
    const [updateCartDonation, { data: updateCartDonationData, loading: updateCartDonationLoading }] = useMutation(UPDATE_CART_DONATION)
    const [updateCartItemShippingOption, { data: updateCartItemShippingOptionData, loading: updateCartItemShippingOptionLoading }] = useMutation(UPDATE_CART_ITEM_SHIPPING_OPTION)
    const [updateCartItemsShippingOption, { data: updateCartItemsShippingOptionData, loading: updateCartItemsShippingOptionLoading }] = useMutation(UPDATE_CART_ITEMS_SHIPPING_OPTION)
    const [updateCartOldItemBack, { data: updateCartOldItemBackData, loading: updateCartOldItemBackLoading }] = useMutation(UPDATE_CART_OLD_ITEM_BACK)
    const [getPayPalTocken, { data: payPalData, loading: payPalLoading }] = useMutation(CREATE_PAYPAL_EXPRESS_TOKEN, { variables: { cart_id: cart.id } })
    const [getPayEnginFunc, { data: payEnginFuncData, loading: payEnginFuncLoading }] = useLazyQuery(GET_PAYENGINE_FUNC)
    const [MpDeliveryTime, { data: MpDeliveryTimeData, loading: MpDeliveryTimeLoading }] = useMutation(SET_DILEVERY_DATE_AND_TIME)
    const [updateCartStoreCode, { data: storeCodeData, loading: storeCodeLoading }] = useMutation(UPDATE_CART_STORE_CODE)
    const [removePromoItem, { data: removePromoItemData, loading: removePromoItemLoading }] = useMutation(REMOVE_PROMO_ITEM)
    const [AddPromoItem, { data: addPromoItemData, loading: addPromoItemLoading }] = useMutation(ADD_PROMO_ITEM)

    const setPickup = useGlobalStore(state => state.setPickup)

    useEffect(() => {
        if (cancelData && !paymentOngoing) {
            setSkip1(false)
            setSkip2(false)
            setSkip4(false)
        }
    }, [cancelData, paymentOngoing])



    // updates qty of items
    useEffect(() => {
        if (updateData) {
            setCart({ ...cart, items: updateData.action.cart.items })
        }
    }, [updateData])

    useEffect(() => {
        if (cart.id) {
            if (cart.items.length > 0 && !cart.order) {
                refetchCart("promo")
                if (isPickup == null || !(document.getElementById("pickup-switch")))
                    setIsPickup(cart?.shipping_addresses?.at(0)?.selected_shipping_method?.method_code?.includes("pickup"))
                setCustomDeliveryTime(customDeliveryTime)
                if (cart?.shipping_addresses[0]?.selected_shipping_method && cart?.shipping_addresses[0].available_shipping_methods.length > 0 && !selectedShippingMethod?.method_code?.includes("post")) {
                    const { method_code = "shopshipping - sieber_bordsteinkante", carrier_code = "shopshipping", amount: { currency = "CHF", value = "0" } } = cart?.shipping_addresses[0].available_shipping_methods[0] || {}
                    setSelectedShippingMethod({ method_code, carrier_code, currency, value })
                }
            }

            if (cart?.shipping_addresses?.at(0)?.selected_shipping_method?.method_code === "shopshipping - pickup") {
                setEnabledTransport(false)
            }
            else if (cart?.shipping_addresses?.at(0)?.selected_shipping_method?.method_code === null) {
                setEnabledTransport(false)
            }
            else {
                setEnabledTransport(true)
            }

            if (cart.selected_store_code) {
                setPickup(cart.selected_store_code)
            } else {
                setPickup("")
            }
        }
    }, [cart])

    useEffect(() => {
        if (cart.delivery_date_values && Boolean(deliveryTimeData?.customDeliveryTime?.deliveryDateFormat)) {

            let format = deliveryTimeData.customDeliveryTime.deliveryDateFormat?.toUpperCase()
            let temp = times
            if (cart.delivery_date_values.delivery_date_value)
                temp.delivery_date = moment(cart.delivery_date_values.delivery_date_value.split('T')[0], "YYYY-MM-DD").format(format)
            if (cart.delivery_date_values.floor_delivery_date_value)
                temp.floor_delivery_date = moment(cart.delivery_date_values.floor_delivery_date_value.split('T')[0], "YYYY-MM-DD").format(format)

            if (cart.delivery_date_values.house_security_code > "")
                temp.delivery_time = cart.delivery_date_values.house_security_code
            else
                temp.delivery_time = cart.delivery_date_values.delivery_time_value
            if (cart.delivery_date_values.floor_house_security_code > "")
                temp.floor_delivery_time = cart.delivery_date_values.floor_house_security_code
            else
                temp.floor_delivery_time = cart.delivery_date_values.floor_delivery_time_value

            setTimes(temp)
        }
    }, [cart, deliveryTimeData])

    useEffect(() => {
        setCustomerCartID(cookie.load('customerCartID'))
        setGuestCartID(cookie.load('guestCartID'))
    }, [])

    useEffect(() => {
        if (cartError) {
            if (cartError.message === "The cart isn't active." ||
                cartError.message === 'Erforderlicher Parameter "cart_id" fehlt' ||
                cartError.message === 'Le panier n\'est pas actif' ||
                cartError.message.includes("$cart_id")) {
                createEmptyCart({
                    onError: (err) => {
                        enqueueSnackbar(err.message, { variant: 'error' })
                    }
                })
            }
        }
    }, [cartError])

    // useEffect(() => {
    //     if (MpDeliveryTimeData) {
    //         refetchCart()
    //     }
    // }, [MpDeliveryTimeData])

    useEffect(() => {
        if (isAuthenticated ? Boolean(customerCartID) : Boolean(guestCartID)) {
            refetchCart()
        }
    }, [guestCartID, customerCartID])

    useEffect(() => {
        if (ssaoCart) {
            setCart({ ...cart, shipping_addresses: ssaoCart.setShippingAddressesOnCart.cart.shipping_addresses })
            setCartPrices(ssaoCart.setShippingAddressesOnCart.cartPrices.prices)
        }
    }, [ssaoCart])

    useEffect(() => {
        if (scsaoCart) {
            setCart({ ...cart, shipping_addresses: scsaoCart.setShippingAddressesOnCart.cart.shipping_addresses })
            setCartPrices(scsaoCart.setShippingAddressesOnCart.cartPrices.prices)
        }
    }, [scsaoCart])

    useEffect(() => {
        if (sbaoCart) {
            setCart({ ...cart, billing_address: sbaoCart.setBillingAddressOnCart.cart.billing_address })
        }
    }, [sbaoCart])

    useEffect(() => {
        if (scbaoCart) {
            setCart({ ...cart, billing_address: scbaoCart.setBillingAddressOnCart.cart.billing_address })
        }
    }, [scbaoCart])

    useEffect(() => {
        if (ssmoCart) {
            setCart({ ...cart })
            refetchCart("cart")
            refetchCart("prices")
        }
    }, [ssmoCart])

    useEffect(() => {
        if (sgeoCart) {
            refetchCart()
        }
    }, [sgeoCart])

    useEffect(() => {
        if (spmCart) {
            refetchCart("prices")
        }
    }, [spmCart])


    useEffect(() => {
        if (poData) {
            setOrderNum(poData.placeOrder.order.order_number)
            setProccessStepLock(false)
            setGuestEmail(null)
            const { email, firstname, lastname } = poData.placeOrder.order
            setOrderGuestCred({ email, firstname, lastname })
            if (!paymentOngoing) {
                setCart({ items: [] })
                createEmptyCart()
            }
            refetchCustomerData()
        }
    }, [poData])

    useEffect(() => {
        if (waleeData) {
            const { email, firstname, lastname } = waleeData?.order
            setOrderGuestCred({ email, firstname, lastname })
        }
    }, [waleeData])


    useEffect(() => {
        if (updateCartItemsShippingOptionData) {
            setCart({ ...cart, ...updateCartItemsShippingOptionData.action.cart })
            setCartPrices(updateCartItemsShippingOptionData.action.cartPrices.prices)
        }

    }, [updateCartItemsShippingOptionData])

    useEffect(() => {
        if (authReady && !router.asPath.includes('wallee_payment')) {
            if (!isAuthenticated) {
                if (!Boolean(cookie.load('guestCartID'))) {
                    createEmptyCart()
                }
            }
            else {
                if (Boolean(guestCartID)) {
                    mergeCarts({
                        variables: {
                            source_cart_id: guestCartID,
                        },
                        onCompleted: (data) => {
                            if (data) {
                                setCustomerCartID(data.mergeCarts.id)
                                cookie.save('customerCartID', data.mergeCarts.id, { path: "/", maxAge: 23 * 60 * 60 })
                                setGuestCartID('')
                                cookie.save('guestCartID', "", { path: "/" })
                            }
                        },
                        onError: (err) => {
                            cookie.save('guestCartID', "", { path: "/" })
                            createEmptyCart()
                            console.log(err.message)
                        }
                    })
                }
            }
        }
    }, [token, authReady])

    useEffect(() => {
        if (data1) {
            setCart(data1.cart)
            setSkip1(true)
            if (uidsToCompare && uidsToCompare !== JSON.stringify(data1.cart.items.map(item => item.uid))) {
                setUidsToCompare(null)
                if (processStep === "PaymentMethods") setProcessStep("ShippingMethods")
            }
            if (isAuthenticated) {
                if (customerData?.addresses?.length === 1 && data1.cart?.shipping_addresses?.length === 0) {
                    if (validAdress(customerData?.addresses[0])) {
                        setCustomerShippingAddressesOnCart({
                            variables: {
                                cart_id: data1.cart.id,
                                customer_address_id: customerData?.addresses[0].id
                            },
                            onCompleted: (data) => {
                                setCustomerBillingAddressOnCart({
                                    variables: {
                                        cart_id: data1.cart.id,
                                        customer_address_id: customerData?.addresses[0].id
                                    },
                                    onCompleted: () => {
                                        // setProcessStep("ShippingMethods")
                                    }
                                })
                            },
                            onError: (err) => {
                                enqueueSnackbar(err.message, { variant: 'error' })
                            }
                        })
                    }
                }
            }
        }
    }, [data1])

    useEffect(() => {
        if (guestEmail == null && cart.email) setGuestEmail(cart.email)
    }, [guestEmail, cart.email])

    useEffect(() => {
        if (deliveryTimeData) {
            setCustomDeliveryTime(deliveryTimeData.customDeliveryTime)
            setSkipCustomDeliveryData(true)
        }
    }, [deliveryTimeData])

    useEffect(() => {
        if (data2) {
            setCartPrices(data2.cartPrices.prices)
            setSkip2(true)
        }
    }, [data2])

    useEffect(() => {
        if (removeData) {
            refetchCart()
        }
    }, [removeData])

    useEffect(() => {
        if (updateData) {
            setCart({ ...cart, items: updateData.action.cart.items })
            setCartPrices(updateData.action.cartPrices.prices)
            refetchCart("promo")
        }
    }, [updateData])

    useEffect(() => {
        if (updateCartWarrantyData) {
            setCart({ ...cart, ...updateCartWarrantyData.action.cart })
            setCartPrices(updateCartWarrantyData.action.cartPrices.prices)
        }
    }, [updateCartWarrantyData])

    useEffect(() => {
        if (updateCartInsuranceData) {
            refetchCart("cart")
            refetchCart("prices")
        }
    }, [updateCartInsuranceData])

    useEffect(() => {
        if (updateCartDonationData) {
            setCart({ ...cart, items: updateCartDonationData.action.cart.items })
            setCartPrices(updateCartDonationData.action.cartPrices.prices)
        }
    }, [updateCartDonationData])

    useEffect(() => {

        if (updateCartItemShippingOptionData) {
            setCart({ ...cart, ...updateCartItemShippingOptionData.action.cart })
            setCartPrices(updateCartItemShippingOptionData.action.cartPrices.prices)
        }
    }, [updateCartItemShippingOptionData])

    useEffect(() => {
        if (couponData) {
            setCart({ ...cart, ...couponData.action.cart })
            setCartPrices(couponData.action.cartPrices.prices)
        }
    }, [couponData])

    useEffect(() => {
        if (couponRemoveData) {
            setCart({ ...cart, ...couponRemoveData.action.cart })
            setCartPrices(couponRemoveData.action.cartPrices.prices)
        }
    }, [couponRemoveData])

    useEffect(() => {
        if (updateCartOldItemBackData) {
            setCart({ ...cart, ...updateCartOldItemBackData.action.cart })
            setCartPrices(updateCartOldItemBackData.action.cartPrices.prices)
        }
    }, [updateCartOldItemBackData])

    useEffect(() => {
        if (removePromoItemData) {
            refetchCart("cart,promo")
        }
    }, [removePromoItemData])

    useEffect(() => {
        if (addPromoItemData) {
            refetchCart("cart,promo")
        }
    }, [addPromoItemData])

    useEffect(() => {
        if (listFreeItems) {
            setFreeItems(listFreeItems)
            setSkip4(true)
        }
    }, [listFreeItems])

    const addToCart = useCallback(({ quantity, product, onComplete }) => {
        setOrderNum(null)
        setAddLoading(product.sku)
        setProductInfo({ product: product, cartPrices, items: cart.items })
        if (!product.sku || product.productType === "ConfigurableProduct") {
            setAddLoading(false)
            return;
        }
        setOpen(true)
        const add = (product.productType === "BundleProduct") ? addBundleProductsToCart : addProductsToCart
        setProccessStepLock(false)
        add({
            variables: {
                cart_id: cart.id,
                sku: product.sku,
                quantity,
            },
            onCompleted: (data) => {
                const methods = data.action?.cart?.items?.filter(itm => itm.product.sku === product.sku)?.at(0)?.product?.extra_options?.shipping_method_list
                const dMethod = methods?.length > 0 ?
                    cart.shipping_addresses?.at(0)?.selectedShippingMethod?.method_code?.includes('pickup') ? methods.filter(m => m.code.includes('pickup')).at(0).code :
                        methods.filter(m => m.code.includes('sieber')).at(0).code ||
                        methods.filter(m => m.code.includes('post')).at(0).code ||
                        methods.filter(m => m.code.includes('floor')).at(0).code ||
                        methods.at(0).code
                    :
                    null
                setAddLoading(false)
                if (data?.action?.user_errors?.length > 0) {
                    enqueueSnackbar(data.action.user_errors[0].message, { variant: 'error' })
                } else {
                    if (dMethod) {
                        updateCartItemShippingOption({
                            variables: {
                                cart_id: cart.id,
                                cart_item_uid: data.action.cart.items.filter((item) => item.product.sku === product.sku)[0].uid,
                                shipping_method: dMethod
                            }
                        })
                    }
                    if (onComplete) onComplete(data)
                }
                refetchCart("promo")
            },
            onError: (err) => {
                setAddLoading(false)
                enqueueSnackbar(err.message, { variant: 'error' })
            }
        });
    }, [cart.id, cartPrices])

    const handleGoogleCartEvent = (data, eventName) => {
        if (window.gtag) {
            gtag("event", eventName, {
                currency: data.cart.prices.grand_total.currency,
                value: data.cart.prices.grand_total.value,
                items: data.cart.items.map(item => {
                    return {
                        item_id: item.product.sku,
                        item_name: item.product.name,
                        price: item.prices.price_including_tax.value,
                        quantity: item.quantity
                    }

                })
            })
        }
    }

    useEffect(() => {
        if (addData) {
            handleGoogleCartEvent(addData.action, "add_to_cart")
            if (cart.items?.length === 0) setOrderGuestCred({})
            setCart({ ...cart, items: addData.action.cart.items, shipping_addresses: addData.action.cart.shipping_addresses })
            setCartPrices(addData.action.cart.prices)
            refetchCart("promo")
        }
    }, [addData])


    const total_quantity = useCallback(() => {
        return cart.items.filter(item => item.__typename !== "VirtualCartItem").reduce((ret, item) => ret += item.quantity, 0) || null
    }, [cart.items])

    useEffect(() => {
        if (cart)
            console.log("dsad");

    }, [])
    const refetchCart = useCallback((refreshPart = "all") => {
        setCount((prev) => prev + 1)
        if (isAuthenticated) {
            if (customerCartID) cookie.save('customerCartID', customerCartID, { path: "/", maxAge: 23 * 60 * 60 })
        } else {
            if (guestCartID) {
                cookie.save('guestCartID', guestCartID, { path: "/", maxAge: 2 * 60 * 60 })
            }
        }
        switch (refreshPart) {
            case "cart":
                setSkip1(false)
                break;
            case "prices":
                setSkip2(false)
                break;
            case "promo":
                setSkip4(false)
                break;
            case "cart,promo":
                setSkip1(false)
                setSkip4(false)
                break;
            default:
                setSkip1(false)
                setSkip2(false)
                setSkip4(false)
                break;
        }
    }, [customerCartID, guestCartID])

    const setOrder = (orderNumber) => {
        setCart({ ...cart, order: orderNumber })
    }

    const multiGroup = useCallback(() => {
        if (isPickup) return false
        let group = selectedShippingMethodsSet(cart.items)
        let PostSperrgutLength = GetPostSperrgutItems(cart.items).length
        let groupLength = group.length + PostSperrgutLength
        if (group.includes("sieber-bordsteinkante") && group.includes("posts")) groupLength--
        if (PostSperrgutLength === realItems(cart.items).length) return false
        return groupLength > 1
    }, [cart.items, isPickup])
    useEffect(() => {
        console.log(cart);

    }, [])

    const contextValue = useMemo(() => (
        {
            cartReady: Boolean(cart.id),
            cart,
            orderGuestCred,
            setCart,
            cartItems: cart?.items || [],
            googleCartItems,
            setGoogleCartItems,
            orderTotal,
            setOrderTotal,
            paymentMethods,
            refetchCart,
            processStep,
            setProcessStep,
            readyToPay,
            setReadyToPay,
            cartLoading: (!skip1 || !skip2 || addProductLoading || removeProductLoading || updateCartLoading || addBundleProductLoading || spmCartLoading),
            cartPricesLoading: (addProductLoading || removeProductLoading || updateCartLoading || addBundleProductLoading),
            cartPrices,
            selectedShippingMethod,
            isPickup,
            times,
            cancelWalleeLoading,
            total_quantity,
            setTimes,
            createEmptyCart,
            addToCart,
            AddPromoItem,
            removePromoItem,
            addProductsToCart,
            addBundleProductsToCart,
            removeItemFromCart,
            updateCartItem,
            applyCouponToCart,
            removeCouponFromCart,
            setShippingAddressesOnCart,
            setCustomerShippingAddressesOnCart,
            setBillingAddressOnCart,
            setCustomerBillingAddressOnCart,
            setShippingMethodsOnCart,
            setGuestEmailOnCart,
            setPaymentMethodOnCart,
            setSelectedShippingMethod,
            placeOrder,
            updateCartItemWarranty,
            updateCartInsurance,
            updateCartDonation,
            enabledTransport,
            setEnabledTransport,
            updateCartItemShippingOption,
            updateCartItemShippingOptionLoading,
            updateCartItemsShippingOption,
            updateCartItemsShippingOptionLoading,
            SetTransactionUrlsLoading,
            updateCartOldItemBack,
            getPayPalTocken,
            getPayEnginFunc,
            setIsPickup,
            MpDeliveryTime,
            updateCartStoreCode,
            setTransactionUrls,
            cancelWallee,
            successWallee,
            addProductLoading,
            addBundleProductLoading,
            removeProductLoading,
            updateCartLoading,
            couponLoading,
            removeCouponLoading,
            ssaoCartLoading,
            scsaoCartLoading,
            sbaoCartLoading,
            scbaoCartLoading,
            ssmoCartLoading,
            segoCartLoading,
            spmCartLoading,
            placeOrderLoading,
            payEnginFuncLoading,
            storeCodeLoading,
            setOrder,
            setProductInfo,
            setOpen,
            checkoutAgreements,
            proccessStepLock,
            setProccessStepLock,
            shippingStep,
            setShippingStep,
            setPopupErrorMessage,
            deliveryTimeData: customDeliveryTime,
            addLoading,
            listFreeItems: freeItems,
            orderNum,
            setOrderNum,
            multiGroup,
            cartLoading,
            setGuestCartID,
            setCustomerCartID,
            updateAllCartItem,
            updateAllCartLoading
        }
    ), [
        cart,
        paymentMethods,
        orderGuestCred?.email,
        processStep,
        readyToPay,
        skip1,
        skip2,
        cartPrices,
        selectedShippingMethod,
        isPickup,
        shippingStep,
        enabledTransport,
        times,
        updateCartItemShippingOptionLoading,
        updateCartItemsShippingOptionLoading,
        SetTransactionUrlsLoading,
        addProductLoading,
        addBundleProductLoading,
        removeProductLoading,
        updateCartLoading,
        couponLoading,
        removeCouponLoading,
        ssaoCartLoading,
        scsaoCartLoading,
        sbaoCartLoading,
        scbaoCartLoading,
        ssmoCartLoading,
        segoCartLoading,
        spmCartLoading,
        placeOrderLoading,
        payEnginFuncLoading,
        storeCodeLoading,
        checkoutAgreements,
        deliveryTimeData,
        addLoading,
        cart?.items,
        listFreeItems,
        orderNum,
        cartLoading,
        cancelWalleeLoading,
        updateAllCartItem,
        updateAllCartLoading,
        googleCartItems,
        setGoogleCartItems,
        orderTotal,
        setOrderTotal,
    ])

    // useEffect(() => {
    //     const handleVisibilityChange = () => {
    //         if (document.visibilityState === 'visible') {
    //             refetchCart()
    //         } else {
    //             if (cart && cart.items.length > 0) {
    //                 setUidsToCompare(JSON.stringify(cart.items.map(item => item.uid)))
    //             }
    //         }
    //     };

    //     document.addEventListener('visibilitychange', handleVisibilityChange);

    //     return () => {
    //         document.removeEventListener('visibilitychange', handleVisibilityChange);
    //     };
    // }, []);

    return (
        <CartContext.Provider
            value={contextValue}
        >
            {props.children}
            {open
                ?
                <PopUp
                    productInfo={productInfo}
                    button1={t("Continue shopping")} button2={t("View Cart")}
                    open={open} setOpen={() => setOpen()}
                    storeData={storeData}
                    handleButton2={() => { router.push("/cart.html") }}
                    popupErrorMessage={popupErrorMessage}
                />
                :
                null}
        </CartContext.Provider >
    );
};
