import { create } from 'zustand';
import { mountStoreDevtool } from 'simple-zustand-devtools';
import { createJSONStorage, persist } from 'zustand/middleware';


interface CacheStoreType {
    ready: boolean
    setReady: (ready: boolean) => void
    footer: any
    setFooter: (footer: any) => void
    availableStores: any
    setAvailableStores: (availableStores: any) => void
    storeConfig: any
    setStoreConfig: (logo: any) => void
    navbarCategories: any
    setNavbarCategories: (navbarCategories: any) => void
    navbarSpecialCategories: any
    setNavbarSpecialCategories: (navbarCategories: any) => void
    countries: any,
    setCountries: (countries: any) => void
}

export const useCacheStore = create<CacheStoreType>()(
    persist((set) => ({
        ready: false,
        setReady: (status) => {
            set(() => ({ ready: status }));
        },
        footer: null,
        setFooter: (footer) => {
            set(() => ({ footer }));
        },
        availableStores: null,
        setAvailableStores: (availableStores) => {
            set(() => ({ availableStores }));
        },
        storeConfig: null,
        setStoreConfig: (storeConfig) => {
            set(() => ({ storeConfig }));
        },
        navbarCategories: null,
        setNavbarCategories: (navbarCategories) => {
            set(() => ({ navbarCategories }));
        },
        navbarSpecialCategories: null,
        setNavbarSpecialCategories: (navbarSpecialCategories) => {
            set(() => ({ navbarSpecialCategories }));
        },
        countries: null,
        setCountries: (countries) => {
            set(() => ({ countries }));
        },
    }), {
        name: "cache-store",
        storage: createJSONStorage(() => sessionStorage),
        partialize: (state) => ({
            footer: state.footer,
            availableStores: state.availableStores,
            storeConfig: state.storeConfig,
            navbarCategories: state.navbarCategories,
            navbarSpecialCategories: state.navbarSpecialCategories,
            countries: state.countries,
        }),
        onRehydrateStorage: () => {
            return (state, error) => {
                if (error) {
                    console.error("an error happened during hydration of cache store", error);
                } else {
                    state?.setReady(true);
                }
            };
        },
    }
    )
);

if (process.env.NODE_ENV === 'development') {
    mountStoreDevtool('cacheStore', useCacheStore);
}
