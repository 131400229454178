import { Badge, Grid, Tooltip, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import useCss from '../../hooks/useCss'
import CompareIcon from '../icons/compare'
import FavIcon from '../icons/fav'
import BasketIcon from '../icons/basket'
import { CartContext } from '../../Providers/Cartprovider'
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { useRouter } from 'next/router'
import useTranslation from '../../hooks/useTranslation'
import { CompareListContext } from '../../Providers/CompareListProvider'
import { WishListContext } from '../../Providers/WishListContext'
import Link from 'next/link'
import { CustomerContext } from '../../Providers/CustomerProvider'
import { useAuthStore } from '../../stores/authStore'
import styles from '../../styles/topBar.module.scss'
import MenuUser from './menuUser'
import PhonelinkSetupOutlinedIcon from '@mui/icons-material/PhonelinkSetupOutlined';
import { useGlobalStore } from '../../stores/globalStore'
import { useCacheStore } from '../../stores/cacheStore'

const QuickLinks = (props) => {
    const storeConfig = useCacheStore(state => state.storeConfig)
    const isAuthenticated = useAuthStore(state => Boolean(state.token))
    const { c } = useCss()
    const { t } = useTranslation()
    const router = useRouter()
    const { total_quantity } = useContext(CartContext)
    const { compareList } = useContext(CompareListContext)
    const { wishList } = useContext(WishListContext)

    const { customerData } = useContext(CustomerContext)

    const [color1, setColor1] = useState('#00000000')
    const [color2, setColor2] = useState('#00000000')
    const [textColor, setTextColor] = useState('#000')

    useEffect(() => {
        if (storeConfig) {
            if (storeConfig.black_friday_enabled) {
                setColor1(storeConfig.black_friday_backend_color1)
                setColor2(storeConfig.black_friday_backend_color2)
                setTextColor('#fff')
            } else {
                setColor1(c('yellow'))
                setColor2(c('yellow'))
                setTextColor(c('black'))
            }
        }

    }, [storeConfig])

    return (
        <>
            <Grid className="quick_links" alignItems="center" display="flex" justifyContent="space-between" sx={{ ">*": { ml: { lg: "52px", sm: "45px", xs: "0px" } }, "a>svg:hover,#person-icon:hover": { color: c("blue") } }} >
                {router.asPath !== "/check-out" ?
                    <>
                        <MenuUser styles={styles} />
                        <Tooltip title={<Typography variant="span" sx={{ fontSize: "14px" }} >{t("SERVICE CENTER")}</Typography>} disableTouchListener>
                            <Link href='https://rma.frankenspalter.ch' style={{ color: c("dark-gray") }} >
                                <PhonelinkSetupOutlinedIcon />
                            </Link>
                        </Tooltip>
                        <Tooltip title={<Typography variant="span" sx={{ fontSize: "14px" }}>{t('compare products')}</Typography>} disableTouchListener>
                            <Link className={props.styles.headerLink} href='/productCompare.html' >
                                <Badge badgeContent={compareList?.items?.length ? compareList?.items?.length : 0}
                                    sx={{
                                        "& .MuiBadge-badge": {
                                            background: color1,
                                            color: textColor,
                                            right: "-3px", top: "-8px !important", fontSize: "12px !important"
                                        },
                                        "&:hover .MuiBadge-badge": {
                                            background: color2
                                        }
                                    }} >
                                    <CompareIcon />
                                </Badge>
                            </Link>
                        </Tooltip>
                        <Tooltip title={<Typography variant="span" sx={{ fontSize: "14px" }}>{t('Favourites')}</Typography>} disableTouchListener>
                            <Link className={props.styles.headerLink} href='/customer/account/favorites.html' >
                                <Badge badgeContent={isAuthenticated ? wishList.items_count : 0}
                                    sx={{
                                        "& .MuiBadge-badge": {
                                            background: color1,
                                            color: textColor,
                                            right: "-3px", top: "-8px !important", fontSize: "12px !important"
                                        },
                                        "&:hover .MuiBadge-badge": {
                                            background: color2
                                        }
                                    }}>
                                    <FavIcon />
                                </Badge>
                            </Link>
                        </Tooltip>
                    </>
                    :
                    <Grid className={props.styles.headerLink} sx={{ display: "flex", flexDirection: { lg: "column !important", xs: "row !important" } }}
                        onClick={() => router.push(isAuthenticated ? '/customer/account/my-dashboard.html' : '/customer/login.html')}
                    >
                        <PersonOutlineIcon style={{ width: "24px", height: "24px", marginRight: "2px" }} />
                        {
                            isAuthenticated ?
                                <Typography variant='span' sx={{ textTransform: 'capitalize' }}>
                                    {customerData?.firstname}
                                </Typography>
                                :
                                <Typography variant='span'>
                                    {t("login")}
                                </Typography>
                        }
                    </Grid>
                }
                <Tooltip title={<Typography variant="span" sx={{ fontSize: "14px" }}>{t('View Cart')}</Typography>} disableTouchListener>
                    <Link className={props.styles.headerLink} href='/cart.html'>
                        <Badge badgeContent={total_quantity() || null} sx={{
                            "& .MuiBadge-badge": {
                                background: color1,
                                color: textColor,
                                right: "-3px", top: "-8px !important", fontSize: "12px !important",
                            },
                            "&:hover .MuiBadge-badge": {
                                background: color2
                            }
                        }} >
                            <BasketIcon />
                        </Badge>
                    </Link>
                </Tooltip>
            </Grid >
        </>
    )
}

export default QuickLinks