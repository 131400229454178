import QuickLinks from './quickLinks'
import { Box, Container, Grid } from '@mui/material'
import styles from '../../styles/header.module.scss'
import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { CATEGORY_LIST, CATEGORY_LIST_BY_ATTRIBUTE, GET_STORE_CONFIG } from "../../graphql/queries";
import { useRouter } from 'next/router'
import useCss from "../../hooks/useCss";
import DesktopCatagoryMenu from "./desktopCatagoryMenu";
import SearchInput from "./searchInput";
import Logo from "../logo";
import MobileHeader from "./mobileHeader";
import LocationLang from "./location-lang";
import Script from 'next/script';
import { useCookieSettingsStore } from '../../stores/cookieBar';
import { useGlobalStore } from '../../stores/globalStore';
import { useCacheStore } from '../../stores/cacheStore';

const Header = () => {

    const router = useRouter()
    const { c } = useCss()

    const showZopimState = useCookieSettingsStore(state => state.cookieOptions['Zopim'])
    const [showZopim, setShowZopim] = useState(false)
    const showGoogleTagsState = useCookieSettingsStore(state => state.cookieOptions['Google Analytics'])
    const [showGoogleTags, setShowGoogleTags] = useState(false)
    const showCookieBar = useCookieSettingsStore(state => state.showCookieBar)



    const cacheReady = useCacheStore(s => s.ready)
    const storeConfig = useCacheStore(s => s.storeConfig)
    const navbarCategories = useCacheStore(s => s.navbarCategories)
    const navbarSpecialCategories = useCacheStore(s => s.navbarSpecialCategories)

    const setStoreConfig = useCacheStore(s => s.setStoreConfig)
    const setNavbarCategories = useCacheStore(s => s.setNavbarCategories)
    const setNavbarSpecialCategories = useCacheStore(s => s.setNavbarSpecialCategories)

    const skip1 = !cacheReady || navbarCategories
    const skip2 = !cacheReady || navbarSpecialCategories
    const skip3 = !cacheReady || storeConfig

    const { data: menuData, loading: menuLoading } = useQuery(CATEGORY_LIST, { variables: { parent: "2" }, skip: skip1, context: { fetchOptions: { method: "GET" } } })
    const { data: specialCatData, loading: specialCatLoading, error: specialCatError } = useQuery(CATEGORY_LIST_BY_ATTRIBUTE, { skip: skip2, context: { fetchOptions: { method: "GET" } } });
    const { data: storeData, loading: storeLoading, error: storeError } = useQuery(GET_STORE_CONFIG, { skip: skip3, context: { fetchOptions: { method: "GET" } } });



    useEffect(() => {
        if (menuData) {
            setNavbarCategories(menuData.categories?.items?.filter(cat => (cat.include_in_menu && !cat.is_special)))
        }
    }, [menuData])

    useEffect(() => {
        if (specialCatData) {
            setNavbarSpecialCategories(specialCatData)
        }
    }, [specialCatData])

    useEffect(() => {
        if (storeData) {
            setStoreConfig(storeData.storeConfig)
        }
    }, [storeData])

    const categoryList = navbarCategories
    const specials = navbarSpecialCategories


    useEffect(() => {
        setShowZopim(showZopimState)
    }, [showZopimState])

    useEffect(() => {
        setShowGoogleTags(showGoogleTagsState)
    }, [showGoogleTagsState])


    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        const isLg = window.innerWidth > 1200
        if (isLg && document.querySelector('#header-top-bar')) {
            if (scrolled > parseInt(document.querySelector('#header-top-bar')?.style?.height) + 177) {
                if (document.querySelector('#left-side-sticky'))
                    document.querySelector('#left-side-sticky').style.top = "153px"
                if (document.querySelector('#header-top-bar'))
                    document.querySelector('#header-top-bar').style.height = "0px"
            }
            else {
                if (document.querySelector('#left-side-sticky'))
                    document.querySelector('#left-side-sticky').style.top = "190px"
                if (document.querySelector('#header-top-bar'))
                    document.querySelector('#header-top-bar').style.height = "37px"
            }
        }
        else if (document.querySelector('#mob-top-bar')) {
            if (scrolled > 160 & document.querySelector('#mob-top-bar').style.display !== "none") {
                document.querySelector('#mob-top-bar').style.display = "none"
                // window.scrollTo({ top: window.scrollY - 56 })
            }
            else if (scrolled < 120 & document.querySelector('#mob-top-bar').style.display === "none") {
                document.querySelector('#mob-top-bar').style.display = "block"
                // window.scrollTo({ top: window.scrollY + 56 })
            }
        }
    }

    const [borderColor, setBorderColor] = useState('#00000000')
    const [borderImage, setBorderImage] = useState('')
    useEffect(() => {
        window.addEventListener('scroll', toggleVisible)
    }, [])

    useEffect(() => {
        if (storeConfig) {
            if (storeConfig.black_friday_enabled) {
                setBorderColor('')
                setBorderImage(storeConfig?.black_friday_backend_color1 + 1)
            } else {
                setBorderColor(c('yellow'))
                setBorderImage("")
            }
        }

    }, [storeConfig])

    return (
        <>
            {
                showZopim
                    ?
                    <Script strategy="afterInteractive" charset="utf-8" src="https://v2.zopim.com/?1IMTFP7nXxvq0ed3rjXST2JsSd9mLQ16" />
                    :
                    null
            }
            {
                showGoogleTags
                    ?
                    <>
                        <Script id="google-tags" strategy="afterInteractive">
                            {`(function(w,d,s,l,i){w[l] = w[l] || [];
                                    w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});
                                    var f=d.getElementsByTagName(s)[0],
                                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
                                    j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                                    })(window,document,'script','dataLayer','GTM-K32CSH6');`
                            }
                        </Script>
                        <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-K32CSH6"
                            height="0" width="0" style={{ display: "none", visibility: "hidden" }}>
                        </iframe>
                    </>
                    :
                    null
            }

            <Box className={styles.header} id="all_header"
                sx={{
                    boxShadow: "0 3px 10px 2px #22222217",
                    backgroundColor: "unset",
                    borderBottom: null,
                    displayPrint: "none",
                    zIndex: "10 !important"
                }}
            >
                {
                    (router.asPath === "/check-out") || (router.asPath === "/i18n")
                        ?
                        <Container maxWidth="xl" sx={{ backgroundColor: "white" }}>
                            <Grid container sx={{ "img": { zoom: { xs: 0.8, md: 0.8 } }, py: 1, alignItems: "center", px: { lg: 15, xs: 0 }, justifyContent: { xs: 'center', md: 'space-between' } }}>
                                <Logo />
                                <Grid sx={{ display: { md: 'block', xs: 'none' } }}>
                                    <QuickLinks styles={styles} />
                                </Grid>
                            </Grid>
                        </Container>
                        :
                        <>
                            <Grid sx={{
                                borderBottom: `3px solid ` + borderColor,
                                borderImage: borderImage,
                                pb: { xl: '0px', xs: '10px' }, backgroundColor: c("white")
                            }}>
                                <Container maxWidth="xl" >
                                    <Grid flexWrap="wrap" justifyContent={{ xs: "space-between" }} alignItems="center" sx={{ display: "flex", minHeight: { xs: "unset", md: "107px" } }}>
                                        <Box sx={{ display: { xs: "none", md: 'flex' }, alignItems: "center", "img": { zoom: { xs: 0.8, lg: 1 } } }}>
                                            <Logo />
                                            <LocationLang sx={{ ml: 6 }} />
                                        </Box>

                                        <SearchInput sx={{ width: { xl: "calc(100% - 1100px)", lg: "calc(100% - 800px)", md: "calc(100% - 720px)" }, display: { md: 'flex', xs: "none" } }} />

                                        {/***************** Mobile Header ********************/}
                                        <Grid sx={{ display: { xs: "block", sm: "block", md: "none" }, justifyContent: "center", width: "100%" }}>
                                            <MobileHeader categoryList={categoryList} specials={specials} />
                                        </Grid>

                                        <Grid sx={{ display: { md: "flex", xs: "none" } }}>
                                            <QuickLinks styles={styles} />
                                        </Grid>
                                    </Grid>

                                </Container>
                            </Grid >
                            <DesktopCatagoryMenu key={JSON.stringify(specials) + (storeConfig?.black_friday_category_id || "_")} categoryList={categoryList} specialCatData={specials} />

                        </>
                }

            </Box >
        </>
    );
}

export default Header;