import { gql } from "@apollo/client";

export const LOGIN = gql`
mutation generateCustomerToken($email:String!,$password:String!){
	login:generateCustomerToken(email:$email,password:$password){
        token
    }
}
`;

export const LOGOUT = gql`
mutation{
    logout:revokeCustomerToken{
    result
  }
}
`;

export const REQUEST_NEW_PASSWORD = gql`
 mutation requestPasswordResetEmail($email:String!){
       requestPasswordResetEmail(
         email: $email
       )
     }
`;

export const RESET_PASSWORD = gql`
mutation resetPassword($email: String!, $resetPasswordToken:String!,$newPassword:String!) {
      resetPassword(
        email: $email
        resetPasswordToken: $resetPasswordToken
        newPassword: $newPassword
    )
}
`;

export const ADD_PRODUCTS_TO_WISH_LIST = gql`
mutation addProductsToWishlist($wishlistId: ID!, $sku:String!, $selected_options:[ID]){
  addProductsToWishlist(wishlistId:$wishlistId,
    wishlistItems:[
      {
        sku:$sku
        quantity:1
        selected_options:$selected_options
      }
    ]
  ){
    user_errors {
      code
      message
    }
    wishlist {
      id
      items_v2 (currentPage: 1, pageSize: 300){
        page_info {
          total_pages
          current_page
          page_size
        }
        items{
          id
          ... on ConfigurableWishlistItem {
            child_sku
            configurable_options{
              id
              option_label
              value_id
              value_label
              __typename
            }
          }
          product{
            uid
            name
            pack
            sku
            material_filter
            material_filter_actual
            thumbnail{
              url
              label
            }
            __typename
            color
            color_filter
          } 
        }  
      }
    }
    __typename
  }
}
`;

export const REMOVE_PRODUCTS_FROM_WISH_LIST = gql`
mutation removeProductsFromWishlist($wishlistId: ID!, $wishlistItemIds: ID!){
  removeProductsFromWishlist(
  wishlistId: $wishlistId
  wishlistItemsIds: [
    $wishlistItemIds
  ]){
    user_errors {
      code
      message
    }
    wishlist {
      id
      items_v2 (currentPage: 1, pageSize: 300){
        page_info {
          total_pages
          current_page
          page_size
        }
        items{
          id
          ... on ConfigurableWishlistItem {
            child_sku
            configurable_options{
              id
              option_label
              value_id
              value_label
              __typename
            }
          }
          product{
            uid
            name
            pack
            sku
            material_filter
            material_filter_actual
            thumbnail{
              url
              label
            }
            __typename
            color
            color_filter
          } 
        }  
      }
    }
    __typename
  }
}
`;

export const CREATE_PRODUCT_REVIEW = gql`
mutation ($sku: String!, $nickname: String!, $summary: String!, $text: String!, $ratings: [ProductReviewRatingInput!]!  ){
  createProductReview(
  input: {
      sku: $sku,
      nickname: $nickname,
      summary: $summary,
      text: $text,
      ratings: $ratings
    }
) {
  review {
      nickname
      summary
      text
      average_rating
      ratings_breakdown {
        name
        value
      }
    }
  }
}
`;

export const CONTACTUS_FORM = gql`
mutation($comment: String!,$email: String!,$name: String!,$telephone: String!) {
  contactUs(
    input: {
      comment: $comment,
      email: $email,
      telephone: $telephone,
      name: $name }
      ) {
    status
  }
}`;

export const SET_RECENT_VIEWED_PRODUCTS = gql`
mutation ($product_ids: [Int]!){
  recentViewedProducts(product_ids: $product_ids) 
  {
    message
    total_count
  }
}
`;



