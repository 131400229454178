import { gql } from "@apollo/client";
import { CART_PAYMENTS_FRAGMENT, CART_FRAGMENT, CART_PRICES_FRAGMENT } from "./fragments";

export const GET_CART_DETAILS = gql`
${CART_FRAGMENT}
query cart($cart_id: String!){
  cart(cart_id:$cart_id) {
    ...CartFragment
  }
}
`



export const CART_PAYMENTS = gql`
${CART_PAYMENTS_FRAGMENT}
query cart_payments($cart_id: String!){
  cart(cart_id:$cart_id) {
    ...cartPayments
  }
}
`

export const GET_CART_PRICES = gql`
${CART_PRICES_FRAGMENT}
query cartPrices($cart_id: String!){
  cartPrices:cart(cart_id:$cart_id) {
    ...CartPricesFragment
  }
}
`
export const GET_FREE_ITEMS = gql`
query listPormoItem($cartId: String!) {
  amPromoGetAvailableFreeGifts (cartId: $cartId){
    available_gifts_qty
    promo_items {
      sku
      product{
        name
        image{
          url
        }
        small_image{
          url
        }
      }
      available_qty
      price
    }
  } 
}
`

export const CUSTOM_DELIVERY_TIME = gql`
query customDeliveryTime{
  customDeliveryTime{
      isEnabledDeliveryTime     
      isEnabledHouseSecurityCode     
      isEnabledDeliveryComment  
      deliveryDateFormat        
      deliveryDaysOff           
      deliveryDateOff
      deliveryTime
      isEnabledFloorDeliveryTime   
      floorDeliveryDaysOff:deliveryFloorDaysOff  
      floorDeliveryDateOff    
      floorDeliveryTime 
      hasFloorDeliveryDate
      hasDeliveryDate
      customTimeDelivery
      floorCustomTimeDelivery
  }
}
`
export const CHECKOUT_AGREEMENT = gql`
query {
  checkoutAgreements {
    agreement_id
    checkbox_text
    content
    content_height
    is_html
    mode
    name
  }
}
`;

////////////////////// payEngine /////////////////////////
export const GET_PAYENGINE_FORM = gql`
query getPaymentForm($cart_id: String!) {
  form:getPaymentForm (cart_id: $cart_id, payment_method:"payengine3cw_creditcard")
}
`

export const GET_PAYENGINE_FUNC = gql`
query getPayEnginFunc($number: String!){
  payEngine3CwAuthorize(number: $number) {
    ajax_file_url
    java_script_callback_function
  }
}
`


export const WALLEE_GET_ORDER_TRANSACTION = gql`
query GetOrderTransactionSettings($order_number: String!, $integrationType: String!) {
  customerOrderTransaction(
    order_id: $order_number
    integration_type: $integrationType
  ) {
    order_id
    integration_type
    transaction_id
    transaction_state
    payment_url
  }
}
`