import { useQuery } from "@apollo/client";
import { GET_CMS_BLOCK } from "../../graphql/queries";
import { useEffect, useState } from "react";
import { useRouter } from 'next/router';
import { Box } from "@mui/material";
import { useCacheStore } from "../../stores/cacheStore";

const Footer = () => {

    const footerData = useCacheStore(s => s.footer)
    const setFooterData = useCacheStore(s => s.setFooter)
    const cacheReady = useCacheStore(s => s.ready)
    const { data } = useQuery(GET_CMS_BLOCK, { variables: { id: 'new_footer' }, skip: (footerData || !cacheReady), context: { fetchOptions: { method: "GET" } } })
    const router = useRouter()


    useEffect(() => {
        if (footerData) {
            const links = document.querySelectorAll('#new-footer a')
            links.forEach(element => {
                element.addEventListener("click", (e) => {
                    e.preventDefault()
                    if (element.getAttribute('target') === '_blank') {
                        window.open(element.getAttribute('href'))
                    }
                    else if (element.getAttribute('href').startsWith('#') && element.getAttribute('href').length > 1) {
                        document.getElementById(element.getAttribute('href').substring(1)).scrollIntoView({ behavior: 'smooth' })
                    }
                    else {
                        router.push(element.getAttribute('href'))
                    }
                })
            });
        }
    }, [footerData])

    useEffect(() => {
        if (data) {
            setFooterData(data)
        }
    }, [data])

    return (
        <Box displayPrint="none" component="footer" id="new-footer" dangerouslySetInnerHTML={{ __html: footerData?.cmsBlocks.items[0].content }} className=' w-100 footer' sx={
            footerData?.cmsBlocks.items[0].content
                ?
                {
                    display: ((router.asPath.split("/")[1] === "check-out") || (router.asPath.split("/")[1] === "cart.html")) ? "none" : "block",
                    fontSize: "0.875rem", borderTop: "solid 2px rgba(255, 187, 0, 0.71)",
                    color: "#878787", background: "#FAFAFA", marginTop: "3rem", '@media(max-width:900px)': { pb: "75px !important" }
                }
                :
                {}
        }
        >
        </Box>
    );
}

export default Footer;