import { Backdrop, Box, ClickAwayListener, IconButton, InputAdornment, OutlinedInput, Popper } from '@mui/material';
import { Grid } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search';
import SrchRes from './searchRes'
import useCss from '../../hooks/useCss';
import useTranslation from '../../hooks/useTranslation';
import { useEffect, useRef, useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_AUTO_COMPLETE_RESULTS } from '../../graphql/queries';
import styles from '../../styles/header.module.scss'
import { useRouter } from 'next/router';
import parseSearchParams, { strEncode } from '../../helpers/SearchParams';

const SearchInput = (props) => {

    const { t } = useTranslation()
    const { c } = useCss()
    const [search, setSearch] = useState('')
    const [skipSearch, setSkipSearch] = useState(true)
    const [srchData, setSrchData] = useState()
    const [showSearchRes, setShowSearchRes] = useState(false)
    const srchRef = useRef()
    const srcIcon = useRef()
    const router = useRouter()
    const searchParams = parseSearchParams(router)
    const [open, setOpen] = useState()
    const { data: searchCatalog } = useQuery(GET_AUTO_COMPLETE_RESULTS, { variables: { inputText: search.trim() }, skip: skipSearch })
    useEffect(() => {
        if (searchCatalog) {
            setSrchData(searchCatalog)
            setSkipSearch(true)
        }
    }, [searchCatalog])

    useEffect(() => {
        setShowSearchRes(Boolean(srchData))
        if (Boolean(srchData)) {
            document.getElementById('overlay')?.classList.add('active')
        } else {
            document.getElementById('overlay')?.classList.remove('active')
        }
    }, [srchData])

    useEffect(() => {
        if (search.length > 2) {
            if (skipSearch)
                setSkipSearch(false)
        }
        else {
            setSkipSearch(true)
            setSrchData(null)
        }
    }, [search])

    useEffect(() => {
        if (search.length > 2) {
            setSearch('')
            setSrchData(null)
        }
    }, [])

    const searchHandler = () => {
        if (search.length > 2) {
            setSearch('')
            setSrchData(null)
            router.push(`/catalogsearch/result/${strEncode('{"q":"' + search + '"}')}`,)
        }

    }

    return (
        <Grid item xs={10} xl={20} sx={{ width: "100%", ...props?.sx }}  >
            <ClickAwayListener onClickAway={() => { setShowSearchRes(false); document.getElementById('overlay')?.classList.remove('active') }} >
                <Grid sx={{ p: "0 !important", m: "0 !important", width: "100%" }}>
                    <Grid ref={srchRef}
                        container
                        variant="standard"
                        color="secondary"
                        // parentId='srch'
                        sx={{
                            width: "100% ",
                            minWidth: { lg: "310px", xs: "unset" },
                            p: 1, zIndex: "1202 !important", position: "relative"
                        }}

                    >
                        <OutlinedInput
                            id="search_input"
                            style={{ borderRadius: "5px", width: "100%" }}
                            size="small"
                            sx={{ zIndex: "1202", opacity: "1", backgroundColor: c('white') }}
                            placeholder={t("Search")}
                            value={search} onChange={(e) => { setSearch(e.target.value) }}
                            onFocus={() => setShowSearchRes(Boolean(srchData))}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    searchHandler()
                                    document.activeElement.blur()
                                    navigator.virtualKeyboard.hide();
                                }
                            }}
                            onKeyUp={(e) => {
                                if (e.key === 27) {
                                    document.getElementById('overlay')?.classList.remove('active')
                                }
                            }
                            }
                            endAdornment={
                                <InputAdornment position="end" >
                                    <IconButton
                                        ref={srcIcon}
                                        aria-label="toggle password visibility"
                                        edge="end"
                                        onClick={() => {
                                            searchHandler();
                                            setSearch('')
                                        }}
                                    >
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </Grid>
                    <Popper
                        anchorEl={srchRef.current}
                        open={showSearchRes}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}

                        sx={{
                            px: { sm: 2.5, xs: 2 },
                            mt: 2, borderRadius: c('borderRadius'), zIndex: 1201,
                        }}
                    >
                        <SrchRes srchData={srchData} search={search} setSearch={setSearch} />
                    </Popper>

                </Grid>
            </ClickAwayListener>
            {
                showSearchRes
                    ?
                    <Backdrop
                        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: "rgba(0, 0, 0, 0.2) !important" }}
                        open={showSearchRes}
                    />
                    :
                    null
            }
        </Grid>
    )
}

export default SearchInput