import { gql } from "@apollo/client";

export const ADDRESS_FRAGMENT = gql`
fragment AddressFragment on CustomerAddress{
  id
  firstname
  lastname
  street
  prefix
  city
  company
  postcode
  country_code
  telephone
  fax
  vat_id
  default_billing
  default_shipping
  region_id
  region {
    region_code
    region
    __typename
  }
  suffix
}
`

export const CUSTOMER_FRAGMENT = gql`
${ADDRESS_FRAGMENT}
fragment CustomerFragment on Customer {
  firstname
  lastname
  email
  is_subscribed
  prefix
  addresses {
    ...AddressFragment
  }
  orders {
    total_count
  }
}
`

