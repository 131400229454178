import { useEffect } from 'react';

const useProtectAgainstOldLocalStorage = () => {
    useEffect(() => {
        if (!localStorage.getItem('visitedBefore')) {
            const keysToKeep = ['customer', 'menu', 'stores', 'logo', 'recentlyViewed', 'langAuth', 'paymentStore']
            const allKeys = Object.keys(localStorage)
            allKeys.forEach(key => {
                if (!keysToKeep.includes(key)) {
                    localStorage.removeItem(key)
                }
            })
            localStorage.setItem('visitedBefore', 'true')
        }
    }, [])
}

export default useProtectAgainstOldLocalStorage;