import { gql } from "@apollo/client";
import { COMPARE_LIST_FRAGMENT } from "./fragments";

export const GET_COMPARE_LIST = gql`
${COMPARE_LIST_FRAGMENT}
query compareList($uid: ID!){
  compareList:compareList(uid: $uid){
    ...compareListFragment
  }
}
`
