import { Box, Fade, Grid, List, ListItemButton, ListItemText, Popper, Typography } from "@mui/material";
import PersonIcon from "../icons/person-icon";
import UserName from "./userName";
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import VpnKeyOutlinedIcon from '@mui/icons-material/VpnKeyOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import ListItemIcon from '@mui/material/ListItemIcon';
import GridViewIcon from '@mui/icons-material/GridView';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import LogoutIcon from '@mui/icons-material/Logout';
import { useRouter } from "next/router";
import useTranslation from "../../hooks/useTranslation";
import useCss from "../../hooks/useCss";
import { useContext, useRef, useState } from "react";
import { CustomerContext } from "../../Providers/CustomerProvider";
import { useAuthStore } from "../../stores/authStore";
import styles from '../../styles/topBar.module.scss'
import cssVars from '../../styles/vars.module.scss'

const MenuUser = (props) => {

    const isAuthenticated = useAuthStore(state => Boolean(state.token))
    const router = useRouter()
    const { t } = useTranslation()
    const { c } = useCss()
    const { logOut } = useContext(CustomerContext)

    const [open, setOpen] = useState()
    const anchorEl = useRef()
    const El = useRef()
    let hideAfter


    const closeMenu = (e, cond = false) => {
        const condition = [document.getElementById('menu-popper'), document.getElementById('main-menu-caller'), document.getElementById('pop2')].indexOf(e?.relatedTarget) > -1
        if (!condition || cond) {
            setOpen(false)
            const bodyElement = document.querySelector('body')
            document.getElementById('scroll_UpButton').style.right = "12px"
            document.getElementById('question_btn').style.right = "12px"
            bodyElement.style.paddingRight = "unset"
            bodyElement.style.overflowY = "scroll"
        }
    }

    const handleOpenPopper = () => {
        const bodyElement = document.querySelector('body')
        document.getElementById('scroll_UpButton').style.right = "20px"
        document.getElementById('question_btn').style.right = "20px"
        bodyElement.style.overflowY = "hidden"
        bodyElement.style.paddingRight = "6px"
        setOpen(true);
    }

    return (
        <>
            <Box
                id="person-icon"
                sx={{ display: { xs: 'none', md: 'flex' }, flexDirection: "column", alignItems: "center", cursor: "pointer", position: "relative", color: c("dark-gray") }}
                onClick={() => { router.push(isAuthenticated ? '/customer/account/my-dashboard.html' : '/customer/login.html') }}
                ref={anchorEl}
                onMouseEnter={(e) => { handleOpenPopper() }}
                onMouseLeave={(e) => hideAfter = setTimeout(() => closeMenu(e), 300)}
            >
                <Box>
                    <PersonIcon />
                </Box>
                <UserName styles={styles} />
            </Box>

            <Grid className={props.styles.headerLink} sx={{ display: { md: 'none !important' } }}>
                <PersonIcon onClick={() => router.push(isAuthenticated ? '/customer/account/my-dashboard.html' : '/customer/login.html')}
                    style={{ width: "22px" }} />
                <Typography variant="span" display={{ xs: "none", lg: "block" }}>{"KONTO"}</Typography>
            </Grid>
            {isAuthenticated ?
                <Popper
                    id="customer-popper"
                    open={open}
                    onMouseEnter={(e) => clearTimeout(hideAfter)}
                    onMouseLeave={(e) => { hideAfter = setTimeout(() => closeMenu(e), 300) }}
                    anchorEl={anchorEl.current}
                    onAbort={() => { setOpen(false) }}
                    placement="bottom-end"
                    className={styles.beeb}
                    disablePortal={false}
                    sx={{ background: c('white'), zIndex: 1405, borderRadius: cssVars.radiusX2 }}
                >
                    <List ref={El} className={styles.customer_list + " bg-white"} id="customer-pop">
                        <ListItemButton className={styles.list_button} onClick={() => { router.push('/customer/account/my-dashboard.html') }}>
                            <ListItemIcon className={styles.list_icon}>
                                <GridViewIcon />
                            </ListItemIcon>
                            <ListItemText className={styles.list_text} primary={t("My dashboard")} />
                        </ListItemButton>

                        <ListItemButton className={styles.list_button} onClick={() => router.push('/customer/account/orders.html')}>
                            <ListItemIcon className={styles.list_icon}>
                                <ShoppingBagOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText className={styles.list_text} primary={t("My Orders")} />
                        </ListItemButton>

                        <ListItemButton className={styles.list_button} onClick={() => router.push('/customer/account/changepassword.html')}>
                            <ListItemIcon className={styles.list_icon}>
                                <VpnKeyOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText className={styles.list_text} primary={t("Change Password")} />
                        </ListItemButton>

                        <ListItemButton className={styles.list_button} onClick={() => router.push('/customer/account/addresses.html')}>
                            <ListItemIcon className={styles.list_icon}>
                                <FmdGoodOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText className={styles.list_text} primary={t("Addresses")} />
                        </ListItemButton>

                        <ListItemButton className={styles.list_button} onClick={() => router.push('/customer/account/favorites.html')}>
                            <ListItemIcon className={styles.list_icon}>
                                <FavoriteBorderOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText className={styles.list_text} primary={t("Favourites")} />
                        </ListItemButton>

                        <ListItemButton className={styles.list_button} onClick={() => router.push('/customer/account/profileinformation.html')}>
                            <ListItemIcon className={styles.list_icon}>
                                <PersonOutlineIcon />
                            </ListItemIcon>
                            <ListItemText className={styles.list_text} primary={t("profile information")} />
                        </ListItemButton>

                        <ListItemButton className={styles.list_button}
                            onClick={(e) => {
                                closeMenu(null, true)
                                logOut()
                            }}>
                            <ListItemIcon className={styles.list_icon}>
                                <LogoutIcon />
                            </ListItemIcon>
                            <ListItemText className={styles.list_text} primary={t("log out")} />
                        </ListItemButton>

                    </List>
                </Popper>
                :
                null
            }
        </>
    );
}

export default MenuUser;