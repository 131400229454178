import {
    ApolloClient,
    InMemoryCache,
    createHttpLink,
    from
} from "@apollo/client"
import { setContext } from "@apollo/client/link/context"
import { onError } from "@apollo/client/link/error"
import { useAuthStore } from "../stores/authStore"
import { PHASE_PRODUCTION_BUILD } from 'next/constants'
import possibleTypes from '../possibleTypes.json'

export const getClient = (locale, token = null, useGETForQueries = true) => {

    const ssrMode = typeof window === 'undefined'
    const signOut = useAuthStore.getState().signOut

    const graphqlUri = process.env.NEXT_PHASE === PHASE_PRODUCTION_BUILD
        ?
        process.env.BACKEND_URL + "/graphql"
        :
        ssrMode
            ?
            "http://127.0.0.1:3334" + "/api/graphql"
            :
            window.location.origin + "/api/graphql"


    const httpLink = createHttpLink({
        uri: graphqlUri,
        credentials: 'same-origin',
        useGETForQueries
    })

    const errorLink = onError(({ graphQLErrors, networkError }) => {
        if (token && graphQLErrors) {
            graphQLErrors.map(({ extensions }) => {
                if (extensions && extensions.category === `graphql-authorization`) {
                    signOut()
                }
            })
        }
    })

    const authLink = setContext((_, { headers }) => {
        return {
            headers: {
                authorization: token ? `Bearer ${token}` : "",
                store: locale,
                ...headers,
            }
        }
    })



    const client = new ApolloClient({
        ssrMode: ssrMode,
        cache: new InMemoryCache({
            possibleTypes
        }),
        link: from([authLink, errorLink, httpLink]),
        ...(
            ssrMode
                ?
                {
                    defaultOptions: {
                        query: {
                            fetchPolicy: 'no-cache'
                        }
                    }
                }
                :
                {}

        ),
    })

    return client
}