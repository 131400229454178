const useAddZeroes = () => {
    const groupNumber = (n, s = "'") => {
        let [a, b] = n.split('.')
        if (a.length < 4)
            return n
        let aa = ""
        for (let i = a.length; i > 0; i = i - 3) {
            if (i - 3 >= 0)
                aa = a.slice(i - 3, i) + (aa ? "'" : "") + aa;
            else
                aa = a.slice(0, i) + "'" + aa;
        }
        return aa + (b ? "." + b : "")
    }

    const z = (num) => {
        var n = Number(num)
        n = n.toFixed(2)
        return groupNumber(n)
    }
    return { z }
}


export default useAddZeroes;