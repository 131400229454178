import { useRouter } from 'next/router';
import { createContext, useState, useEffect, useCallback, useMemo } from 'react';
import translationsDicLocally from '../lang/translations.json'

export const LangContext = createContext({});

export const LangProvider = (props) => {
    const [dictionary1, setDictionary1] = useState()
    const [toSave, setToSave] = useState({})
    const [timeCount, setTimeCount] = useState(1)

    const [translationsDic, setTranslationsDic] = useState(translationsDicLocally)

    const langs = useRouter().locales.reduce((acc, curr) => (acc[curr] = '', acc), {});

    const AddWordToDictionary = useCallback((word) => {
        setDictionary1({ ...dictionary1, [word]: langs })
        setToSave({ ...toSave, [word]: langs })
    }, [dictionary1, toSave])


    useEffect(() => {
        fetch('/api/dictionary/get', {
            method: "POST",
            body: JSON.stringify({
                secret: process.env.NEXT_PUBLIC_SECRET_KEY,
            }),
            headers: { 'Content-Type': 'application/json' }
        }).then(data => data.json()).then((data) => {
            if (data.result) {
                setTranslationsDic({ ...translationsDic, ...JSON.parse(data.result) })
            }
            else {
                console.log(data?.message)
            }
        }).catch((err) => {
            console.log(err)
        })
    }, [])

    useEffect(() => {
        if (process.env.NEXT_PUBLIC_SAVE_DICTIONARY1 !== "false") {
            const timeout = setTimeout(() => {
                setTimeCount(prev => prev + 1)
            }, 5000)
            if (timeCount > 1) {

                if (Object.keys(toSave).length > 0) {
                    fetch('/api/dictionary/update', {
                        method: "POST",
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({
                            secret: process.env.NEXT_PUBLIC_SECRET_KEY,
                            keys: toSave
                        }),
                    }).then((res) => {
                        setToSave({})
                    }).catch((err) => {
                        console.log("err", err)
                    })
                }
            }
            return () => clearTimeout(timeout)
        }
    }, [timeCount])

    const values = useMemo(()=>{
        return { dictionary: dictionary1, translations: translationsDic, AddWordToDictionary}
    },[dictionary1,translationsDic])

    return (
        <LangContext.Provider
            value={values}
        >
            {props.children}
        </LangContext.Provider>
    );
};
