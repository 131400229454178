import { Grid, List, ListItem, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { Link } from '../my-link/link';
import useCss from '../../hooks/useCss'
import useTranslation from '../../hooks/useTranslation'
import styles from "../../styles/menuDrawer.module.scss"
import { useRouter } from 'next/router';

const SelectedCategoryChilds = ({ setOpen, setAncestor, node, ancestor, setNode, stack, mainCategories }) => {

    const router = useRouter()
    const { c } = useCss()
    const { t } = useTranslation()
    const goBackToMain = () => {
        setNode("undefined")
        setAncestor([])
    }

    const UpdateTree = (item) => {
        stack.push(item)
        setNode(item)
        setAncestor([...ancestor, item])
    }
    const goBack = () => {
        setNode(ancestor.length === 1 ? ancestor.at(-1) : ancestor.at(-2))
        ancestor.splice(-1)
    }


    /********************* search for current cat  *****************************/
    /*     const getCurrentCat = (item) => {
            if (item?.url_path === router?.query?.slug?.join('/').toString().split(".")[0]) {
                setNode(item)
                ancestor.at(-1)
                return 1
            } else if (item?.children !== []) {
                for (let j = 0; j < item?.children?.length; j++) {
                    // if (!ancestor.find((obj) => obj.url_path === item.url_path))
                    ancestor.push(item)
                    getCurrentCat(item.children[j])
                }
                return 0
            }else{
                return 0
            }
        }
    
        const searchInMainCats = () => {
            for (let i = 0; getCurrentCat(mainCategories[i]) === 0 && i < mainCategories.length; i++) {
                getCurrentCat(mainCategories[i])
            }
        }
    
        useEffect(() => {
            if (mainCategories && document.getElementById("plp_right_side")) {
                searchInMainCats()
            }
        }, [router?.query?.slug]) */

    /*     useEffect(() => {
            if (node !== "undefined") {
                stack.push(node)
                if (stack.length > 1)
                    setAncestor(stack.pop())
            }
        }, [node]) */


    return (
        <Grid>
            {ancestor.length === 1 ?
                <Grid className={styles.drawerbody + " " + "bg-light-gray"} pr={4} pl={2} py={1}>
                    <Typography className={styles.category_Link} >
                        <ListItem className={styles.backToCats} sx={{ px: "unset", py: 1, color: "black", pl: 2.5 }} onClick={(e) => { goBackToMain() }}>
                            {t('Our collections')}
                        </ListItem>
                    </Typography>
                </Grid>
                :
                null
            }
            {ancestor.length && ancestor.length !== 1 ?
                <Grid className={styles.drawerbody + " " + "bg-light-gray"} pr={4} pl={2} py={1}>
                    <Typography className={styles.category_Link}>
                        <ListItem className={styles.backToCats} sx={{ px: "unset", py: 1, color: "black", pl: 2.5 }}
                            onClick={(e) => { goBack() }}
                        >
                            {ancestor.length === 1 ?
                                ancestor.at(-1).name?.toUpperCase()
                                :
                                ancestor.at(-2).name?.toUpperCase()
                            }
                        </ListItem>
                    </Typography>
                </Grid>
                :
                null
            }
            {node?.name ?
                <Grid className={styles.cat_name} pr={4} pl={2} pt={3.5} pb={0.5} display="block"  >
                    <Typography component="p" sx={{ width: "100%", px: "unset", color: "black", fontSize: "1rem !important", fontWeight: 700 }}>
                        {node?.name?.toUpperCase()}
                    </Typography>
                </Grid>
                :
                null
            }
            <List id="menu_drawer_body" className={styles.drawerbody} sx={{ width: { xs: "85%", sm: "93%" } }}>
                {node?.name ?
                    <Grid onClick={(e) => setOpen(false)}>
                        <Link className={styles.category_Link} to={"/" + node.url_path + ".html"} >
                            <ListItem ListItem
                                sx={{ px: "unset", py: 2, color: "black", borderBottom: `1px solid ${c('gray')}`, width: "100%", display: "flex-wrap" }}
                                className={styles.category_item} >
                                {t('All Products In') + " " + node.name}
                            </ListItem>
                        </Link>
                    </Grid>
                    :
                    null
                }
                {node !== "undefined" ?
                    node.children?.map(item =>
                        item.children?.length > 0 ?
                            <Typography key={item.url_path} className={styles.category_Link} >
                                <ListItem ListItem className={styles.category_item + " " + (item.children?.length > 0 ? styles.chevron : "")}
                                    onClick={(e) => { UpdateTree(item) }}
                                    sx={{ px: "unset", py: 2, color: "black", borderBottom: `1px solid ${c('gray')}` }}>
                                    {item.name}
                                </ListItem>
                            </Typography >
                            :
                            <Link key={item.url_path} className={styles.category_Link} onClick={() => { setOpen(false) }} to={(item.url_path + ".html")}>
                                <ListItem className={styles.category_item + " " + (item.children?.length > 0 ? styles.chevron : "")}
                                    sx={{ px: "unset", py: 2, color: "black", borderBottom: `1px solid ${c('gray')}` }}>
                                    {item.name}
                                </ListItem>
                            </Link >
                    )
                    :
                    mainCategories.map(item =>
                        item.children?.length > 0 ?
                            <Typography key={item.url_path} className={styles.category_Link} >
                                <ListItem ListItem className={styles.category_item + " " + (item.children?.length > 0 ? styles.chevron : "")}
                                    onClick={(e) => { UpdateTree(item) }}
                                    sx={{ px: "unset", py: 2, color: "black", borderBottom: `1px solid ${c('gray')}` }}>
                                    {item.name}
                                </ListItem>
                            </Typography >
                            :
                            <Link key={item.url_path} className={styles.category_Link} onClick={() => { setOpen(false) }} to={(item.url_path + ".html")}>
                                <ListItem className={styles.category_item + " " + (item.children?.length > 0 ? styles.chevron : "")}
                                    sx={{ px: "unset", py: 2, color: "black", borderBottom: `1px solid ${c('gray')}` }}>
                                    {item.name}
                                </ListItem>
                            </Link >
                    )
                }
            </List >
        </Grid >
    )
}

export default SelectedCategoryChilds;