import { ApolloProvider } from "@apollo/client";
import { useRouter } from "next/router";
import { useAuthStore } from "../stores/authStore";
import { getClient } from "./graphqlClient";
import { useMemo, memo } from "react";

const GraphqlProvider = props => {
    const token = useAuthStore((state) => state.token)
    const router = useRouter()
    const { locale } = router
    const client = useMemo(() => (getClient(locale, token, false)), [locale, token]);

    return (
        <ApolloProvider client={client}>
            {props.children}
        </ApolloProvider>
    );
}

export default memo(GraphqlProvider);