import React, { createContext, useContext, useEffect, useState } from "react";
import { useQuery, useMutation } from '@apollo/client';
import { GET_CUSTOMER } from "../graphql/customer/queries";
import { CREATE_CUSTOMER_ADDRESS, UPDATE_CUSTOMER_ADDRESS, DELETE_CUSTOMER_ADDRESS, UPDATE_CUSTOMER_EMAIL, UPDATE_CUSTOMER, SUBSCRIBE_EMAIL_TO_LETTER } from "../graphql/customer/mutations";
import { LOGOUT } from '../graphql/mutations';
import { useRouter } from "next/router";
import { COUNTRIES } from "../graphql/queries";
import { useAuthStore } from "../stores/authStore";
import { useCacheStore } from "../stores/cacheStore";

export const CustomerContext = createContext()

export const CustomerProvider = ({ children }) => {
    const [skip, setSkip] = useState(true)

    const router = useRouter()
    const [count, setCount] = useState(1)
    const [customerData, setCustomerData] = useState({ orders: { total_count: 0 } })
    const [recentViewed, setRecentViewed] = useState(null)

    const [createCustomerAddress, { data: createAddressData, loading: createAddressLoading }] = useMutation(CREATE_CUSTOMER_ADDRESS)
    const [updateCustomerAddress, { data: updateAddressData, loading: updateAddressLoading }] = useMutation(UPDATE_CUSTOMER_ADDRESS)
    const [deleteCustomerAddress, { data: deleteAddressData, loading: deleteAddressLoading }] = useMutation(DELETE_CUSTOMER_ADDRESS)
    const [updateCustomer, { data: updateData, loading: updateCustomerLoading, error: updateCustomerError }] = useMutation(UPDATE_CUSTOMER)
    const [changeEmail, { data: changeEmailData, loading: changeEmailLoading, error: changeEmailError }] = useMutation(UPDATE_CUSTOMER_EMAIL)
    const [subscribeNewsLetter, { data: subscribeData, loading: subscribeLoading }] = useMutation(SUBSCRIBE_EMAIL_TO_LETTER)


    const countries = useCacheStore(state => state.countries)
    const setCountries = useCacheStore(state => state.setCountries)
    const cacheReady = useCacheStore(state => state.ready)
    const skip3 = !cacheReady || countries

    const { data: countries1 } = useQuery(COUNTRIES, { skip: skip3, context: { fetchOptions: { method: "GET" } } })
    const isAuthenticated = useAuthStore(state => Boolean(state.token))
    const signOut = useAuthStore(state => state.signOut)

    const { data, error } = useQuery(GET_CUSTOMER, { variables: { count: count }, skip: skip || !isAuthenticated, fetchPolicy: 'network-only' })
    const [revokeCustomerToken, { }] = useMutation(LOGOUT)

    const logOut = () => {
        signOut()
        revokeCustomerToken(
            {
                onCompleted: (data) => {
                    signOut()
                }
            }
        )
        router.replace('/', undefined, {
            scroll: true
        })
    }
    useEffect(() => {
        if (error)
            logOut()
    }, [error])

    useEffect(() => {
        if (countries1) {
            setCountries(countries1.countries)
        }
    }, [countries1])

    useEffect(() => {
        if (isAuthenticated) {
            setSkip(false)
        }
        else if ((!isAuthenticated) && count > 1) {
            setCustomerData({ orders: { total_count: 0 } })
        }
    }, [isAuthenticated])

    useEffect(() => {
        if (data) {
            setCustomerData(data.customer)
            setSkip(true)
            setCount(count + 1)
        }
    }, [data])

    useEffect(() => {
        if (createAddressData)
            refetchCustomerData()
    }, [createAddressData])

    useEffect(() => {
        if (updateAddressData)
            refetchCustomerData()
    }, [updateAddressData])

    useEffect(() => {
        if (deleteAddressData)
            refetchCustomerData()
    }, [deleteAddressData])

    useEffect(() => {
        if (updateData)
            setCustomerData(updateData.action.customer)
    }, [updateData])

    useEffect(() => {
        if (changeEmailData)
            setCustomerData(changeEmailData.action.customer)
    }, [changeEmailData])

    const refetchCustomerData = () => {
        setSkip(false)
        setCount(count + 1)
    }

    return (
        <CustomerContext.Provider value={{
            customerData,
            recentViewed,
            refetchCustomerData,
            createCustomerAddress,
            createAddressLoading,
            updateCustomerAddress,
            updateAddressLoading,
            deleteCustomerAddress,
            deleteAddressLoading,
            setRecentViewed,
            changeEmail,
            changeEmailData,
            changeEmailLoading,
            changeEmailError,
            updateCustomer,
            updateCustomerLoading,
            updateCustomerError,
            subscribeNewsLetter,
            subscribeLoading,
            logOut,
            countries,
            customerLoading: !skip,
        }} >
            {children}
        </CustomerContext.Provider>
    )
}