import { useEffect, createContext, useState } from "react";
import { useMutation, useQuery } from '@apollo/client';
import { GET_WISHLIST } from "../graphql/wishlistsGraphqls/queries";
import { ADD_PRODUCT_TO_WISH_LIST, REMOVE_PRODUCT_FROM_WISHLIST, UPDATE_WISHLIST } from "../graphql/wishlistsGraphqls/mutations";
import { useSnackbar } from "notistack";
import useTranslation from "../hooks/useTranslation";
import { useRouter } from "next/router";
import { useAuthStore } from "../stores/authStore";
import parseSearchParams, { strEncode } from "../helpers/SearchParams";

export const WishListContext = createContext({})

export const WishListProvider = ({ children }) => {

    const [skip, setSkip] = useState(true)
    const [count, setCount] = useState(0)
    const isAuthenticated = useAuthStore(state => Boolean(state.token))
    const [wishList, setWishList] = useState({ items_count: 0, items_v2: { items: [] } })
    const [wishListSku, setWishListSku] = useState([])
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation()
    const router = useRouter()
    const searchParams = parseSearchParams(router)
    const [pageNumber, setPageNumber] = useState(searchParams.page || 1)
    const { data, loading: wishListDataLoading } = useQuery(GET_WISHLIST, { variables: { currentPage: 1, pageSize: 300, isAuthenticated, count }, skip })

    useEffect(() => {
        if (isAuthenticated) {
            setSkip(false);
        } else {
            setWishList({ items_count: 0, items_v2: { items: [] } })
            setWishListSku([])
        }
    }, [isAuthenticated])

    useEffect(() => {
        if (data) {
            setWishListData(data.customer.wishlists[0])
            setSkip(true)
        }
    }, [data])

    useEffect(() => {
        if ((searchParams.page !== pageNumber) && isAuthenticated) {
            setPageNumber(searchParams.page)
            setSkip(false);
        }

    }, [searchParams])

    const setWishListData = (data) => {
        setWishList(data || {})
        const s = []
        data.items_v2.items.map((item) =>
            s.push(item.product.sku)
        )
        setWishListSku(s)
    }

    const [addProductToWishlist, { loading: addProductToWishlistLoading, data: addData }] = useMutation(
        ADD_PRODUCT_TO_WISH_LIST,
        { refetchQueries: [GET_WISHLIST] }
    )

    const [removeProductFromWishlist, { loading: removeProductFromWishlistLoading, data: removeData }] = useMutation(
        REMOVE_PRODUCT_FROM_WISHLIST,
        { refetchQueries: [GET_WISHLIST] }
    )

    const [updateNewWishList, { loading: updateWishListLoading, data: updateData }] = useMutation(UPDATE_WISHLIST)

    useEffect(() => {
        if (addData) {
            setWishListData(addData.action.wishlist)
        }
    }, [addData])

    useEffect(() => {
        if (removeData) {
            setWishListData(removeData.action.wishlist)
            if (wishList.items_v2.items.length === 1 && searchParams.page > 1) {
                searchParams.page = searchParams.page - 1
                router.push(`${router.asPath.endsWith('.html') ? router.asPath : router.asPath.split('/').slice(0, -1).join('/')}/${strEncode(JSON.stringify(searchParams))}`)
            }
        }
    }, [removeData])

    useEffect(() => {
        if (updateData) {
            setWishListData(updateData.action.wishlist)
        }
    }, [updateData])

    const isFav = (sku = "") => {
        return wishListSku.includes(sku)
    }

    const handleAddProductToWishList = (sku) => {
        if (isAuthenticated) {
            if (isFav(sku)) {
                const itemId = wishList.items_v2.items.filter((item) => item.product?.sku === sku)[0].id;
                removeProductFromWishlist({
                    variables: {
                        wishlistId: wishList.id,
                        id: itemId
                    },
                    onCompleted: (data) => {
                        if (data) {
                            enqueueSnackbar(t("Product removed successfully"), { variant: 'success' });
                        }
                    },
                    onError: (err) => {
                        enqueueSnackbar(err.message, { variant: 'error' })
                    }
                })
            } else {
                addProductToWishlist({
                    variables: {
                        wishlistId: wishList.id, sku: sku, quantity: 1
                    },
                    onCompleted: (data) => {
                        if (data) {
                            enqueueSnackbar(t("The product has been added successfully"), { variant: 'success' });
                        }
                    },
                    onError: (err) => {
                        enqueueSnackbar(err.message, { variant: 'error' })
                    }
                })
            }
        } else {
            router.push('/customer/login.html');
        }
    }


    return (
        <WishListContext.Provider value={{
            wishListReady: Boolean(wishList?.id),
            wishList,
            isFav,
            wishListSku,
            addProductToWishlist,
            removeProductFromWishlist,
            updateNewWishList,
            addProductToWishlistLoading,
            removeProductFromWishlistLoading,
            updateWishListLoading,
            handleAddProductToWishList,
            wishListLoading: !skip,
            wishListDataLoading
        }} >
            {children}
        </WishListContext.Provider>
    )
}