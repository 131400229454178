import { Box, Button, ButtonGroup, Divider, Paper, Popover, Popper } from "@mui/material";
import useCss from "../../hooks/useCss";
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import { useRouter } from "next/router";
import Link from "next/link";
import { useEffect, useRef, useState } from "react";
import { useQuery } from "@apollo/client";
import { GET_AVAILABLE_STORE } from "../../graphql/queries";
import { useCacheStore } from "../../stores/cacheStore";

const LocationLang = ({ sx = {} }) => {
    const router = useRouter()
    const { c } = useCss()
    const [openStores, setOpenStores] = useState(false)
    const anchorEl = useRef()

    const cacheReady = useCacheStore(state => state.ready)
    const stores = useCacheStore(state => state.availableStores)
    const setStores = useCacheStore(state => state.setAvailableStores)

    const skip = !cacheReady || stores

    const { data: storeData } = useQuery(GET_AVAILABLE_STORE, { skip, context: { fetchOptions: { method: "GET" } } })

    useEffect(() => {
        if (storeData) {
            setStores(storeData.availableStores)
        }
    }, [storeData])

    return (
        <>
            <Box
                sx={{
                    height: "38px",
                    borderRadius: "5px",
                    display: "flex",
                    bgcolor: c("light-gray"),
                    "a, span": { px: 1.3, textAlign: "center", cursor: "pointer" },
                    px: 0.5,
                    alignItems: "center",
                    ...sx
                }}
            >
                <Link href="/filialen.html">
                    <PlaceOutlinedIcon sx={{ color: c("mid-gray"), fontSize: "20px" }} />
                </Link>
                <Divider orientation="vertical" variant="middle" flexItem sx={{ borderColor: c("mid-gray") }} />
                <Box component="span" ref={anchorEl} onClick={() => setOpenStores(true)} style={{ fontSize: "14px", textTransform: "uppercase", fontWeight: "600", color: c("blue") }}>
                    {router.locale}
                </Box>
                <Popover open={openStores}
                    onClose={() => setOpenStores(false)}
                    anchorEl={anchorEl.current}
                    sx={{ zIndex: 102 }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <Paper>
                        {stores?.filter(obj => obj.store_code !== router.locale)?.map((store, i) =>
                            <Box key={store.store_code}>
                                {i > 0 ?
                                    <Divider flexItem key={store.store_code + "Divader"} />
                                    :
                                    null
                                }
                                <Box sx={{ cursor: "pointer", textTransform: "uppercase", fontSize: "13px", fontWeight: "500", color: c('dark-gray') }}>
                                    <Link style={{ textDecoration: "none", padding: "8px 16px", display: "block" }} href={router.asPath} locale={store.store_code} >
                                        {store.store_code}
                                    </Link>
                                </Box>
                            </Box>
                        )
                        }
                    </Paper>
                </Popover>
            </Box>
        </>
    );
}

export default LocationLang;