import { Box, Grid, Typography } from "@mui/material"
import useAddZeroes from "../../hooks/useAddZeros"
import useCss from "../../hooks/useCss"
import useTranslation from "../../hooks/useTranslation"
import { Link } from "../my-link/link"
import Stockstate from "../stockStatus/stockstate"
import { useGlobalStore } from "../../stores/globalStore"
import { useCacheStore } from "../../stores/cacheStore"

const SearchItem = ({ product, setSearch }) => {

	const { c } = useCss()
	const { t } = useTranslation()
	const { z } = useAddZeroes()

	const storeConfig = useCacheStore(state => state.storeConfig)
	const isSale = product?.gspunna_regular_price > 0 && product.special_price > 0

	return (
		<Link onClick={() => setSearch('')} to={'/' + product?.canonical_url} >
			<Grid container columns={120} sx={{ borderTop: '1px solid ' + c('gray'), p: 3, pr: 4, '&:hover': { backgroundColor: `${c('light-gray')}`, WebkitBorderBottomLeftRadius: "8px", borderBottomRightRadius: "8px" } }} >
				<Grid item className="image" lg={90} xs={120} sx={{ display: "flex" }}>
					<Grid sx={{ height: 100, width: 140, mr: "10px", backgroundImage: `url(${product.small_image.url})`, backgroundSize: "contain", backgroundRepeat: "no-repeat", backgroundPosition: 'center' }} />
					<Grid sx={{ width: "calc(100% - 110px)" }}>
						<Box variant="p" color={c('dark-gray') + 90} sx={{ lineHeight: "30px", display: 'block', fontSize: '12px' }} className='fw-600'>{product.sku}</Box>
						<Box variant="p" color={c('light-black')} sx={{ lineHeight: "20px", display: 'block', fontSize: '16px' }} pb={1} className='fw-600'>{product.name}</Box>
						<Grid display="flex" alignItems="center" width={{ md: "100%" }} >
							<Grid>
								<Stockstate product={product} />
							</Grid>
							<Grid pl={1}>
								{product?.categories.filter((catId) => catId.id === parseInt(storeConfig?.new_products_category_ids)).length > 0 ?
									<Typography variant="span" className="bg-yellow" sx={{ color: "white", ml: 1, fontSize: "13px", fontWeight: "600", padding: "3px 7px", borderRadius: "2px" }} >
										{t("New")}
									</Typography>
									:
									null
								}
								{
									isSale ?
										<Typography variant="span" className="bg-blue" sx={{ color: "white", ml: 1, fontSize: "13px", fontWeight: "600", padding: "3px 7px", borderRadius: "2px" }}>
											{t("Sale")}
										</Typography>
										:
										null
								}
							</Grid>
						</Grid>

					</Grid>
				</Grid>
				<Grid item lg={30} xs={120} >
					<Grid height="100%" alignItems="center" display="flex" justifyContent="end">
						<Typography variant="span" className="fw-600 fs-20" mr={0.8} color={c('black')}>
							{product.price_range?.maximum_price?.final_price?.currency}
						</Typography>
						<Typography variant="span" className="fw-700 fs-20" color={c('black')}>
							{z(product.price_range?.maximum_price?.final_price.value)}
						</Typography>
					</Grid>
				</Grid>
			</Grid>
		</Link >
	)
}

export default SearchItem;