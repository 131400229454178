import NextLink from 'next/link';

export { Link };

function Link({ to, children, ...props }) {
    return (
        <NextLink href={to} {...props} legacyBehavior >
            <a {...props} >
                {children}
            </a>
        </NextLink>
    );
}