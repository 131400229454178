import { Badge, Grid, Tooltip, Typography } from '@mui/material'
import React, { useContext } from 'react'
import useCss from '../../hooks/useCss'
import CompareIcon from '../icons/compare'
import FavIcon from '../icons/fav'
import BasketIcon from '../icons/basket'
import { CartContext } from '../../Providers/Cartprovider'
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { useRouter } from 'next/router'
import useTranslation from '../../hooks/useTranslation'
import { CompareListContext } from '../../Providers/CompareListProvider'
import { WishListContext } from '../../Providers/WishListContext'
import Link from 'next/link'
import { CustomerContext } from '../../Providers/CustomerProvider'
import { useAuthStore } from '../../stores/authStore'
import styles from '../../styles/topBar.module.scss'
import MenuUser from './menuUser'
import PhonelinkSetupOutlinedIcon from '@mui/icons-material/PhonelinkSetupOutlined';

const QuickLinks = (props) => {

    const isAuthenticated = useAuthStore(state => Boolean(state.token))
    const { c } = useCss()
    const { t } = useTranslation()
    const router = useRouter()
    const { total_quantity } = useContext(CartContext)
    const { compareList } = useContext(CompareListContext)
    const { wishList } = useContext(WishListContext)

    const { customerData } = useContext(CustomerContext)

    return (
        <>
            <Grid className="quick_links" alignItems="center" display="flex" justifyContent="space-between" sx={{ ">*": { ml: { lg: "52px", sm: "45px", xs: "0px" } }, "a>svg:hover,#person-icon:hover": { color: c("blue") } }} >
                {router.asPath !== "/check-out" ?
                    <>
                        <MenuUser styles={styles} />
                            <Link href='https://rma.frankenspalter.ch' style={{ color: c("dark-gray") }} >
                                <PhonelinkSetupOutlinedIcon />
                            </Link>
                            <Link className={props.styles.headerLink} href='/productCompare.html' >
                                <Badge badgeContent={compareList?.items?.length ? compareList?.items?.length : 0} sx={{ "& .MuiBadge-badge": { backgroundColor: c('yellow'), right: "-3px", top: "-8px !important", fontSize: "12px !important", color: c('black') } }} >
                                    <CompareIcon />
                                </Badge>
                            </Link>
                            <Link className={props.styles.headerLink} href='/customer/account/favorites.html' >
                                <Badge badgeContent={isAuthenticated ? wishList.items_count : 0} sx={{ "& .MuiBadge-badge": { backgroundColor: c('yellow'), right: "-3px", top: "-8px !important", fontSize: "12px !important", color: c('black') } }}>
                                    <FavIcon />
                                </Badge>
                            </Link>
                    </>
                    :
                    <Grid className={props.styles.headerLink} sx={{ display: "flex", flexDirection: { lg: "column !important", xs: "row !important" } }}
                        onClick={() => router.push(isAuthenticated ? '/customer/account/my-dashboard.html' : '/customer/login.html')}
                    >
                        <PersonOutlineIcon style={{ width: "24px", height: "24px", marginRight: "2px" }} />
                        {
                            isAuthenticated ?
                                <Typography variant='span' sx={{ textTransform: 'capitalize' }}>
                                    {customerData?.firstname}
                                </Typography>
                                :
                                <Typography variant='span'>
                                    {t("login")}
                                </Typography>
                        }
                    </Grid>
                }
                    <Link className={props.styles.headerLink} href='/cart.html'>
                        <Badge badgeContent={total_quantity() || null} sx={{ "& .MuiBadge-badge": { backgroundColor: c('yellow'), right: "-3px", top: "-8px !important", fontSize: "12px !important", color: c('black') } }} >
                            <BasketIcon />
                        </Badge>
                    </Link>
            </Grid >
        </>
    )
}

export default QuickLinks