import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"
import cssVars from "../../styles/vars.module.scss"
import { useState } from "react"

const MoreInfo = ({ data }) => {
    const [open, setOpen] = useState(false)
    return (
        <>
            <Grid sx={{ width: "100%", mt:"5px", display: "flex", justifyContent: "flex-end" }}>
                <Typography sx={{ cursor: "pointer", color: cssVars.blue, fontSize:13, fontStyle:"italic" }} onClick={() => { setOpen(true) }}>More Information</Typography>
            </Grid>
            <Dialog open={open} onClose={() => setOpen(false)} fullWidth = {true} sx={{maxWidth:"100%"}} maxWidth="lg">
                <DialogTitle sx={{fontWeight:700}}>{data.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText sx={{fontSize:14, fontWeight:400}}>
                        {data.description}
                    </DialogContentText>
                    <TableContainer sx={{px:2}}>
                        <Table sx={{ width: "100%", th:{textAlign:"start", fontWeight:600} }} size="small" aria-label="cookie details table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Cookie Name</TableCell>
                                    <TableCell align="right">Cookie Provider</TableCell>
                                    <TableCell align="right" sx={{minWidth:"300px"}}>Cookie Description</TableCell>
                                    <TableCell align="right">Cookie Lifetime</TableCell>
                                    <TableCell align="right">Cookie Type</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    data.items.map(item => 
                                        <TableRow
                                            key={item.name}
                                        >
                                            <TableCell>{item.name}</TableCell>
                                            <TableCell>{item.provider}</TableCell>
                                            <TableCell>{item.description}</TableCell>
                                            <TableCell>{item.lifeTime}</TableCell>
                                            <TableCell>{item.type}</TableCell>
                                        </TableRow>
                                    )
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
                <DialogActions sx={{pr:5, pb:3}}>
                    <Button variant="outlined" sx={{px:4}} onClick={() => { setOpen(false) }}>close</Button>
                </DialogActions>

            </Dialog>
        </>
    )
}

export default MoreInfo
