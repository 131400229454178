import { Grid } from '@mui/material'
import React from 'react'
import Logo from '../logo/logo'
import MenuDrawer from '../menu-drawer/menuDrawer'
import SearchInput from './searchInput'
import LocationLang from './location-lang'

const MobileHeader = ({ specials, categoryList }) => {
	return (
		<>
			<Grid id="mob-top-bar" display="block" order="2" displayPrint="none">
				<Grid display="flex" justifyContent={{ lg: "unset", xs: "space-between" }} sx={{ "img": { zoom: 0.8 }, my: 1 }}>
					<Logo />
					<LocationLang sx={{ mt: 2, mr: 1 }} />
				</Grid>
			</Grid>
			<Grid order="1" sx={{ mt: 1, display: { md: "none", xs: "flex" }, width: { lg: '500px !important', md: "300px !important", xs: "100% !important" } }} columns={20} displayPrint="none">
				<MenuDrawer mainCategories={categoryList} specialCatData={specials} />
				<SearchInput />
			</Grid>
		</>
	)
}

export default MobileHeader