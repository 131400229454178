import { gql } from "@apollo/client";
export const simpleFields = `
  id
  name
  sku
  brand
  media_gallery {
    url
    thumbnail_url
    label
    disabled
    position
  }
    itg_202403041353396029910469
    itg_202403041352474933797563
    itg_202303151629545861692577
    energy_efficiency_stage
    energy_efficiency_stage2
    energy1: energy_icon(position: 1)
    energy2: energy_icon(position: 2)
  energy_efficiency_label_1 {
    url
  }
  energy_efficiency_label_2 {
    url
  }
  price_range {
    maximum_price {
      discount {
        amount_off
        percent_off
      }
      final_price {
        currency
        value
      }
      fixed_product_taxes {
        amount {
          currency
          value
        }
        label
      }
      regular_price {
        currency
        value
      }
    }
  }
  stock_status
  stock_color
  stock_text
  custom_stock_item {
    qty_increments
    enable_qty_increments
    max_sale_qty
    min_sale_qty
    qty
  }
  energy_efficiency_stage
  energy_efficiency_stage2
  eu_norm_60: itg_202402051411156623960939
  no_frost: itg_202303151630144343490582
  rating_summary
  canonical_url
  categories {
    id
    url_path
    level
  }
  small_image {
    url
    label
  }
`

export const CATEGORY_FRAGMENT = gql`
fragment CategoryFragment on CategoryTree {
  id
  uid
  image
  description
  name
  meta_title
  meta_keywords
  meta_description
  url_path
  is_special
  product_count
  infinite_scroll
  breadcrumbs {
    category_id
    category_name
    category_url_path
    category_level
  }
  children{
    uid
    image
    description
    name
    url_path
    id
    infinite_scroll
    breadcrumbs {
      category_id
      category_uid
      category_name
      category_url_path
      category_level
      __typename
    }
    product_count
    __typename
  }
  __typename
}
`;

export const CUSTOM_ATTRIBUTE = gql`
fragment custom_attribute on ProductInterface {
  custom_attributes {
        attribute_metadata {
          code
          label
          ui_input {
            ui_input_type
          }
          storefront_properties {
            visible_on_catalog_pages
          }
        } 
        entered_attribute_value {
          value
        }
        selected_attribute_options {
          attribute_option {
            label
            swatch_image
          }
        }
      }
}
`;


export const PRICE_FRAGMENT = gql`
fragment PriceFragment on ProductInterface {
  price_range {
    maximum_price {
      discount {
        amount_off
        percent_off
      }
      final_price {
        currency
        value
      }
      fixed_product_taxes {
        amount {
          currency
          value
        }
        label
      }
      regular_price {
        currency
        value
      }
    }
  }
  price_tiers {
    discount {
      amount_off
      percent_off
    }
    final_price {
      currency
      value
    }
    quantity
  }
}
`;


export const CONFIGUREABLE_PRODUCT_FRAGMENT = gql`
fragment ConfigurableProductFragment on ConfigurableProduct {
  configurable_options {
    attribute_code
    label
    values {
      # uid
      label
      use_default_value
      swatch_data {
        value
      }
    }
  }
  variants{
    attributes{
      code
      # uid
      label
    }
    product{
      id
      # uid
      name
      sku
      canonical_url
      categories{
        url_path
        level
      }
      small_image {
        url
        __typename
      }
      stock_status
      # rating_summary
      __typename
      url_key
    }
  }
}
`;

export const EXTENDED_CONFIGUREABLE_PRODUCT_FRAGMENT = gql`
 fragment ExtendedConfigurableProductFragment on ConfigurableProduct {
   configurable_options {
        attribute_code
        label
        values {
            uid
            label
            swatch_data {
                value
            }
        }
    }
    variants {
        attributes {
            code
            uid
            label
        }
        product {
            canonical_url
            meta_title
            meta_description
            meta_keyword
            media_gallery{
              thumbnail_url
              url
              label
              disabled
              position
              __typename
            }
            small_image{
                url
            }
            sku                      
            name
            short_description{
              html
            }
            stock_status
        }
    }
    configurable_product_options_selection {
        options_available_for_selection {
            attribute_code
            option_value_uids
        }
    }
  }
`;


export const PRODUCT_FRAGMENT_DETAILS = gql`
fragment ProductFragmentDetails on ProductInterface {
    eu_norm_60: itg_202402051411156623960939
    no_frost: itg_202303151630144343490582
}
`;

export const PP_PRODUCT_FRAGMENT = gql`
 ${CUSTOM_ATTRIBUTE}
 fragment PPProductFragment on ProductInterface{
    cash_back_label
    visible_custom_attributes{
        attribute_code
        attribute_label
        attribute_value
        attribute_values
        frontend_input               
    }
    gspunna_regular_price
    special_price
    mp_product_alert{
      mp_productalerts_stock_notify
    }
    display_tax {
          price
          tax
          rate
      }
  files {
    title
    url
    __typename
  }
    id
    uid
    name
    brand
    sku
    url_key
    canonical_url
    meta_title
    meta_description
    meta_keyword
    __typename
    image {
      url
    }
    description{
      html
    }
    short_description{
      html
    }
    itg_202403041353396029910469
    itg_202403041352474933797563
    itg_202303151629545861692577
    energy_efficiency_stage
    energy_efficiency_stage2
    energy1: energy_icon(position: 1)
    energy2: energy_icon(position: 2)
    energy_efficiency_label_1 {
      url
    }

    energy_efficiency_label_2 {
      url
    }
    ...custom_attribute
    review_count
    stock_status
    only_x_left_in_stock
    custom_stock_item {
      use_config_qty_increments
      qty_increments
      enable_qty_increments
      use_config_enable_qty_inc

      use_config_max_sale_qty
      max_sale_qty
      use_config_min_qty
      min_qty
      use_config_min_sale_qty
      min_sale_qty
      qty
    }    
    rating_summary
    reviews {
          items {
              average_rating
              summary
              text
              created_at
              nickname
                }
           }
          # material
          # warranty
          eu_norm_60: itg_202402051411156623960939
          no_frost: itg_202303151630144343490582
    media_gallery{
        url
        thumbnail_url
        ... on ProductVideo {
              video_content {
                  video_url
              }
          }
        label
        disabled
        position
        __typename
    }
    related_products {
        ${simpleFields}     
        ...custom_attribute
    }

    upsell_products {
     ${simpleFields} 
      ...custom_attribute
    }

  crosssell_products {
    ${simpleFields} 
    ...custom_attribute
  }
  extra_options {
  guarantee_option_list {
  code
  label
  price
  }
  shipping_method_list {
    code
    label
    price
    flat_price
  }
}
 }
`

export const PP_CATEGORY_FRAGMENT = gql`
fragment PPCategoryFragment on ProductInterface{
  categories {
    id
    name
    url_path
    breadcrumbs {
      category_id
      category_uid
      category_name
      category_url_path
      category_level
    }
  }
}
`

export const DOWNLOADABLE_FRAGMENT = gql`
fragment DownloadableFragment on DownloadableProduct {
  links_title
  links_purchased_separately
  downloadable_product_links {
    sample_url
    sample_file 
    sort_order
    title
  }
  downloadable_product_samples {
    sample_file
    sample_url
    title
  }
}
`

export const BUNDLE_PRODUCT_FRAGMENT = gql`
fragment BundleProductFragment on BundleProduct{
  items{
    options{
      quantity
    }
  }
}
`
export const PRODUCTS_FRAGMENT = gql`
fragment ProductsFragment on Products {
    items {
      brand_label
      cash_back_label
      gspunna_regular_price
      custom_stock_item {
        use_config_qty_increments
        qty_increments
        enable_qty_increments
        use_config_enable_qty_inc

        use_config_max_sale_qty
        max_sale_qty
        use_config_min_qty
        min_qty
        use_config_min_sale_qty
        min_sale_qty
        qty
      }
      id
      name
      sku
      canonical_url
      brand
      eu_norm_60: itg_202402051411156623960939
      no_frost: itg_202303151630144343490582
      stock_status
      energy_efficiency_stage
      energy_efficiency_stage2
      energy_efficiency_label_1 {
        url
      }
      energy_efficiency_label_2 {
       url
      }
      country_of_manufacture
      special_price
      price_range {
        maximum_price {
          discount {
            amount_off
            percent_off
          }
          final_price {
            currency
            value
          }
          fixed_product_taxes {
            amount {
              currency
              value
            }
            label
          }
          regular_price {
            currency
            value
          }
        }
      }
      price_tiers {
        discount {
          amount_off
          percent_off
        }
        final_price {
          currency
          value
        }
        quantity
      }
      categories{
        url_path
        level
        id
      }
      small_image {
        url
        __typename
      }
      media_gallery{
        url
        thumbnail_url
        label
        disabled
        position
        __typename
      }
      stock_status
      rating_summary
      custom_attributes {
        attribute_metadata {
          code
          label
          ui_input {
            ui_input_type
          }
          storefront_properties {
            visible_on_catalog_pages
          }
        } 
        entered_attribute_value {
          value
        }
        selected_attribute_options {
          attribute_option {
            label
            swatch_image
          }
        }
      }
      __typename
      url_key
      ...ConfigurableProductFragment
      ...DownloadableFragment
    }
    page_info {
      total_pages
      page_size
      current_page
      __typename
    }
    sort_fields {
      default
      options {
        label
        value
      }
    }
    total_count
    __typename
  }
  ${CONFIGUREABLE_PRODUCT_FRAGMENT}
  ${DOWNLOADABLE_FRAGMENT}
`;

export const BANNER_FRAGMENT = gql`
fragment BannerItemFragment on Banner {
  id
  alignment
  banner_title
  button_color
  button_text
  description
  html_content
  image
  animated_image
  name
  open_new_tab
  type
  url
  position
  mobile_image
}
`;