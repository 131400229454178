import { useEffect } from 'react'
import Layout from '../components/layout/layout'
import useRouterEvents from '../hooks/useRouterEvents'
import '../styles/globals.scss'
import 'nprogress/nprogress.css'
import useProtectAgainstOldLocalStorage from '../hooks/useProtectAgainstOldLocalStorage'

function MyApp({ Component, pageProps }) {
  const router = useRouterEvents()
  useProtectAgainstOldLocalStorage()


  return (
    <Layout key={router.locale}>
      <Component {...pageProps} />
    </Layout>
  )
}

export default MyApp
