import { Box, Typography } from "@mui/material";
import Link from "next/link";
import CloseIcon from '@mui/icons-material/Close';
import useTranslation from "../../hooks/useTranslation";

const Questions = ({ handleClose }) => {
    const { t } = useTranslation()
    return (
        <Box sx={{
            bgcolor: "#fff", width: "300px",position:"relative",
            height: "170px",  border: "1px solid #e2e2e2", borderRadius: "6px",
            display: "flex", alignItems: "center", justifyContent: "center",
            boxShadow: "rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px;"
        }}>
            <Box>
                <CloseIcon
                    className="f-hover-black gray "
                    sx={{
                        position: "absolute",
                        top: "6px",
                        right: "6px",
                        width: "27px",
                        height: "27px",
                        cursor: "pointer"
                    }}
                    onClick={handleClose}
                />
                <Typography className="black fs-18 fw-700" variant="body1" textAlign="center" >
                    {t('Questions?')}
                </Typography>

                <Typography className="dark-gray fs-14 fw-500" sx={{ mt: "8px" }} variant="body1" textAlign="center" >
                    {t('I am happy to help you!?')}
                </Typography>

                <Typography className="blue fs-14 fw-500" sx={{ mt: "8px" }} variant="body1" textAlign="center" >
                    <Link href='tel:+41 81 740 57 97' >+41 81 740 57 97</Link>
                </Typography>

                <Typography className="blue fs-14 fw-500" sx={{ mt: "8px" }} variant="body1" textAlign="center" >
                    <Link href='mailto:info@frankenspalter.ch' >info@frankenspalter.ch</Link>
                </Typography>
            </Box>
        </Box>
    );
}

export default Questions;