import { gql } from "@apollo/client";
import { CUSTOMER_FRAGMENT } from "./fragments";

export const GET_CUSTOMER = gql`
${CUSTOMER_FRAGMENT}
query customer {
  customer {
    ...CustomerFragment
  }
}
`
