import { load, save, remove } from "react-cookies"
import { StateStorage } from "zustand/middleware"
// Custom storage object
export const cookieStorage: StateStorage = {
  getItem: ( name: string ): string | null => {
    let ret = load( name ) || null
    return JSON.stringify( ret )
  },
  setItem: ( name: string, value: string ): void => {
    const cookieAge = name === "cookieOptions" ? ( 31536000 ) : name.toLowerCase().includes( 'guest' ) ? ( 2 * 60 * 60 ) : ( 23 * 60 * 60 )
    save( name, value, { path: "/", maxAge: cookieAge } )
  },
  removeItem: ( name: string ): void => {
    remove( name, { path: "/" } )
  },
};

