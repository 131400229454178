import HttpsRedirect from 'react-https-redirect';
import GraphqlProvider from "../../Providers/GraphqlProvider";
import Footer from '../footer/footer';
import Header from '../header/header';
import { SnackbarProvider } from 'notistack';
import { CartProvider } from '../../Providers/Cartprovider';
import { WishListProvider } from '../../Providers/WishListContext';
import { CustomerProvider } from '../../Providers/CustomerProvider';
import { CompareListProvider } from '../../Providers/CompareListProvider';
import Questions from '../rightButtons.jsx/questionButton';
import FabButton from '../fab-button/fabButton';
import { LangProvider } from '../../Providers/Lang';
import CookieBar from '../cookie-bar';
import Cookies from '../cookies/cookies';
import { Grid } from '@mui/material';
import QuickLinksMobile from '../header/quickLinksMobile';
import styles from '../../styles/header.module.scss'

const Layout = ({ children }) => {

    return (
        <HttpsRedirect>
            <GraphqlProvider>
                <LangProvider>
                    <SnackbarProvider maxSnack={3} autoHideDuration={3000} style={{ marginBottom: "3rem" }} anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
                        <CustomerProvider>
                            <CompareListProvider>
                                <WishListProvider>
                                    <CartProvider>
                                        <div className="page-cover" />
                                        <Header />
                                        <main>
                                            {children}
                                            <Questions />
                                        </main>

                                        <Footer />
                                        <CookieBar />
                                        <Grid className={styles.quicklinksmobile} sx={{ display: { md: 'none' }, displayPrint: "none" }} >
                                            <QuickLinksMobile styles={styles} />
                                        </Grid>
                                        {/* <Cookies /> */}
                                    </CartProvider>
                                </WishListProvider>
                            </CompareListProvider>
                        </CustomerProvider>
                    </ SnackbarProvider>
                </LangProvider>
            </GraphqlProvider>
        </HttpsRedirect>
    );
}

export default Layout;


