import { Backdrop, Box, Button, Grid, Switch, Typography } from "@mui/material"
import useTranslation from "../../hooks/useTranslation";
import cookiesListEn from "../../cookies.json"
import cookiesListDe from "../../cookies-de.json"
import cookiesListFr from "../../cookies-fr.json"

import MoreInfo from "./more-info";
import Link from "next/link";
import { useState, useCallback, useEffect } from "react"
import { styled } from "@mui/material/styles";
import { useCookieSettingsStore } from "../../stores/cookieBar";
import { useRouter } from "next/router";

const CookieBar = () => {
    const { locale } = useRouter()
    const { t } = useTranslation()
    const cookiesList = locale === "de" ? cookiesListDe : locale === "fr" ? cookiesListFr : cookiesListEn
    const allCookies = cookiesList.cookies.map(c => ({ [c.cookieKey]: (c.enabled === 1 || c.enabled === -1) })).reduce((a, b) => ({ ...a, ...b }))
    const [cookieSettings, setCookieSettings] = useState(allCookies)
    const showCookieBar = useCookieSettingsStore(state => state.showCookieBar)
    const setCookieOptions = useCookieSettingsStore((state) => state.setCookieOptions)
    const [showMe, setShowMe] = useState(false)

    useEffect(() => {
        setShowMe(true)
    }, [])

    useEffect(() => {
        if (document.getElementById("descBox")) {
            window.scrollTo({ top: 60 })
            document.getElementById("descBox").scrollTo({ top: 5, behavior: 'smooth' })
        }
    }, [typeof window !== "undefined" && document?.getElementById("descBox")])

    const Apply = useCallback((option) => {
        let options
        switch (option) {
            case "all cookies":
                options = cookiesList.cookies.map(c => ({ [c.cookieKey]: true })).reduce((a, b) => ({ ...a, ...b }))
                break;
            case "applied cookies":
                options = cookieSettings
                break;
            case "decline all":
                options = cookiesList.cookies.map(c => ({ [c.cookieKey]: (c.enabled === -1) })).reduce((a, b) => ({ ...a, ...b }))
                break;
        }
        setCookieOptions(options)
    }, [cookieSettings])

    const IOSSwitch = styled((props) => (
        <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
    ))(({ theme }) => ({
        width: 42,
        height: 26,
        padding: 0,
        '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 2,
            transitionDuration: '300ms',
            '&.Mui-checked': {
                transform: 'translateX(16px)',
                color: '#fff',
                '& + .MuiSwitch-track': {
                    backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
                    opacity: 1,
                    border: 0,
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                    opacity: 0.5,
                },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
                color: '#33cf4d',
                border: '6px solid #fff',
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
                color:
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[600],
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
            },
        },
        '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 22,
            height: 22,
        },
        '& .MuiSwitch-track': {
            borderRadius: 26 / 2,
            backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
            opacity: 1,
            transition: theme.transitions.create(['background-color'], {
                duration: 500,
            }),
        },
    }));

    const style = {
        "left": {
            left: { xs: "5%", md: 0 },
            top: { xs: 50, md: 0 },
            bottom: { xs: 50, md: 0 },
            width: "450px",
        },
        "right": {
            right: { xs: "5%", md: 0 },
            top: { xs: 50, md: 0 },
            bottom: { xs: 50, md: 0 },
            width: "450px",
        },
        "center": {
            left: { xs: "5%", md: "calc(50% - 350px)" },
            top: { xs: 50, md: "calc(50% - 330px)" },
            bottom: { xs: 50, md: "calc(50% - 330px)" },
            width: "700px",
            borderRadius: "7px"
        }
    }[cookiesList?.options?.position || "left"]

    return (
        showMe && showCookieBar
            ?
            <Backdrop
                sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={true}
            >
                <Grid sx={{
                    bgcolor: "#fff",
                    position: { xs: "absolute", md: "fixed" },
                    zIndex: 9999999,
                    maxWidth: "90%",
                    display: "flex",
                    flexDirection: "column",
                    py: 2,
                    px: 3,
                    borderRadius: { xs: "7px", md: 0 },
                    ...style
                }}
                >
                    <Box sx={{ overflowY: "scroll", pl: 1, border: "1px solid #eee" }} id="descBox">
                        {cookiesList.cookies.map(cookieNode =>
                            cookieNode.display !== "none"
                                ?
                                <Box key={cookieNode.cookieKey} px={1} py={1.2} >
                                    <Grid display="flex" justifyContent="space-between" alignItems="center">
                                        <Typography sx={{ fontSize: { xs: 18, md: 24 }, fontWeight: 700 }}>{cookieNode.title}</Typography>
                                        <IOSSwitch
                                            checked={cookieSettings[cookieNode.cookieKey]}
                                            disabled={cookieNode.enabled === -1}
                                            onChange={(e) => {
                                                let value = e.target.checked
                                                setCookieSettings(prev => ({ ...prev, [cookieNode.cookieKey]: value }))
                                            }
                                            } />
                                    </Grid>
                                    <Typography sx={{ display: { xs: "block", lg: "block" }, fontSize: { xs: 10, md: 14 }, fontWeight: 400 }}>{cookieNode.description}</Typography>
                                    {
                                        cookieNode.display === "all"
                                            ?
                                            <MoreInfo data={cookieNode} />
                                            :
                                            null
                                    }

                                </Box>
                                :
                                null
                        )}
                    </Box>

                    <Box p={2} flex="1">
                        <Typography sx={{ fontSize: "13px" }}>
                            {t("cookie bottom text")} <Link href={locale === "de" ? "/datenschutz.html" : "/" + locale + "/datenschutz.html"} style={{ textDecoration: "underline" }}>{t("cookie policy")}</Link>.
                        </Typography>
                    </Box>
                    <Box p={2} sx={{ "& button": { fontSize: { xs: "12px", lg: "unset" } } }}>
                        <Button className="w-100 white bg-blue" onClick={() => Apply("all cookies")}>{t("accept specific cookies")}</Button>
                        <Button className="w-100 red bg-white border-1-black my-3" onClick={() => Apply("all cookies")}>{t("decline all cookies")}</Button>
                        <Button className="w-100 white bg-green" onClick={() => Apply("all cookies")}>{t("accept all cookies")}</Button>
                    </Box>
                </Grid>
            </Backdrop>
            :
            null
    )
}

export default CookieBar
