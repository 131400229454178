import { gql } from "@apollo/client";

export const COMPARE_LIST_FRAGMENT = gql`
fragment compareListFragment on CompareList{
    uid
    item_count
    attributes{
      code
      label
    }
    items{
      uid
      product {
        id
        canonical_url
        name
        brand
        eu_norm_60: itg_202402051411156623960939
        no_frost: itg_202303151630144343490582
        special_price
        sku
        stock_status
        rating_summary
        small_image {
          url
          __typename
        }
        categories {
          id
          url_path
          level
        }
        custom_stock_item {
          use_config_qty_increments
          qty_increments
          enable_qty_increments
          max_sale_qty
          min_qty
          min_sale_qty
          qty
        }
        custom_attributes {
          attribute_metadata {
            code
            label
            ui_input {
              ui_input_type
            }
            storefront_properties {
              visible_on_catalog_pages
            }
          } 
          entered_attribute_value {
            value
          }
          selected_attribute_options {
            attribute_option {
              label
              swatch_image
            }
          }
        }
        price_range {
          maximum_price {
            discount {
              amount_off
              percent_off
            }
            final_price {
              currency
              value
            }
            fixed_product_taxes {
              amount {
                currency
                value
              }
              label
            }
            regular_price {
              currency
              value
            }
          }
        }
        uid
      }
      attributes{
        code
        value
      }
    }
}
`