import { gql } from '@apollo/client'
import {
  CATEGORY_FRAGMENT,
  CONFIGUREABLE_PRODUCT_FRAGMENT,
  PRODUCTS_FRAGMENT,
  PP_PRODUCT_FRAGMENT,
  EXTENDED_CONFIGUREABLE_PRODUCT_FRAGMENT,
  DOWNLOADABLE_FRAGMENT,
  PP_CATEGORY_FRAGMENT,
  PRICE_FRAGMENT,
  PRODUCT_FRAGMENT_DETAILS,
  CUSTOM_ATTRIBUTE,
  simpleFields,
  BANNER_FRAGMENT,
} from './fragments'

export const GET_STORE_CONFIG = gql`
query{
  storeConfig {
    minimum_order_amount_small_fee
    header_logo_src
    base_currency_code
    base_url
    base_link_url
    base_media_url
    default_title
    default_description
    default_keywords
    default_display_currency_code
    head_includes
    infinite_scroll
    search_infinite_scroll
    locale
    no_route
    store_code
    store_name
    timezone
    on_sale_category_ids
    new_products_category_ids
    type_recaptcha_public_key
    type_recaptcha_private_key
    free_item_counter_enabled
    free_item_setting{
      free_item_counter_total      
      free_item_counter_stock
      free_item_counter_percentage
    }
    black_friday_enabled
    black_friday_backend_color1
    black_friday_backend_color2
    black_friday_color
    black_friday_product_label
    black_friday_category_id
    black_friday_countdown_enabled
    black_friday_countdown_due_date
    small_order_fee_enabled
    small_order_fee_before_discount
    small_order_fee_min
    small_order_fee_amount
  }
}
`

export const GET_AVAILABLE_STORE = gql`
  query {
    availableStores {
      store_name
      store_code
      base_currency_code
      locale
      is_default_store
    }
  }
`

export const GET_LOCALE = gql`
  query getLocale {
    storeConfig {
      autocomplete_on_storefront
      base_currency_codestoreConfig
      base_link_url
      base_media_url
      base_static_url
      catalog_default_sort_by
      category_fixed_product_tax_display_setting
      category_url_suffix
      cms_home_page
      cms_no_cookies
      cms_no_route
      code
      copyright
      default_description
      default_display_currency_code
      default_keywords
      default_title
      demonotice
      front
      grid_per_page
      grid_per_page_values
      head_includes
      head_shortcut_icon
      header_logo_src
      id
      list_mode
      list_per_page
      list_per_page_values
      locale
      minimum_password_length
      no_route
      product_fixed_product_tax_display_setting
      product_url_suffix
      required_character_classes_number
      root_category_id
      sales_fixed_product_tax_display_setting
      secure_base_url
      show_cms_breadcrumbs
      store_name
      title_prefix
      title_separator
      title_suffix
      website_id
      weight_unit
      welcome
    }
  }
`

export const GET_CAT_CHILDREN = gql`
query categories($ids: [String!]) {
  categories(filters: { ids: { in: $ids } }) {
    items {
      id
      name
      url_path
      level
      children {
        id
        name
        url_path
        level
        product_count
      }
    }
  }
}
`

export const CATEGORY_LIST = gql`
  query categories($parent: String!) {
    categories(filters: { parent_id: { eq: $parent } }) {
      items {
        uid
        name
        include_in_menu
        image
        canonical_url
        url_path
        level
        is_special
        children {
          uid
          name
          include_in_menu
          image
          canonical_url
          url_path
          level
          is_special
          children {
            uid
            name
            include_in_menu
            image
            canonical_url
            url_path
            level
            children {
            uid
            name
            include_in_menu
            image
            canonical_url
            url_path
            level
            is_special
            children {
              uid
              name
              include_in_menu
              image
              canonical_url
              url_path
              level
            }
            }
          }
        }
      }
    }
  }
`

export const ROUTE = gql`
  query route($url: String!) {
    route(url: $url) {
      type
      relative_url
      ... on ProductInterface {
        url_key
        meta_title
        meta_keyword
        meta_description
        name
      }
      ... on CategoryInterface {
        uid
        display_mode
        landing_page
        description
        meta_title
        meta_keywords
        meta_description
        name
      }
      ... on CmsPage {
        meta_title
        meta_keywords
        meta_description
        identifier
        title
      }
    }
  }
`


export const PLP_CATEGORY = gql`
  ${CATEGORY_FRAGMENT}
  query plpCategory($uid: String!) {
    categories(filters: { category_uid: { in: [$uid] } }) {
      items {
        level
        uid
          cms_block {
          identifier
          title
          content
        }
        ...CategoryFragment
        __typename
      }
      __typename
    }
  }
`

export const GET_CATEGORIES = gql`
  query GetCategories(
    $pageSize: Int!
    $currentPage: Int!
    $filters: ProductAttributeFilterInput!
    $sort: ProductAttributeSortInput
  ) {
    products(
      pageSize: $pageSize
      currentPage: $currentPage
      filter: $filters
      sort: $sort
    ) {
      total_count
        items {
          id
          name
          sku
          canonical_url
          brand
          eu_norm_60: itg_202402051411156623960939
          no_frost: itg_202303151630144343490582
          cash_back_label
          stock_status
          custom_stock_item {
            qty_increments
            enable_qty_increments
            max_sale_qty
            min_sale_qty
            qty
          } 
          itg_202403041353396029910469
          itg_202403041352474933797563
          itg_202303151629545861692577
          energy_efficiency_stage
          energy_efficiency_stage2
          energy1: energy_icon(position: 1)
          energy2: energy_icon(position: 2)
          energy_efficiency_label_1{
            url
          }

          energy_efficiency_label_2 {
          url
          }
          media_gallery{
            thumbnail_url
            url
            label
            disabled
            position
          }
          stock_color
          stock_text
          myassist_image_count
          categories{
            url_path
            level
            id
          }
          small_image {
            url
          }
          special_price
          stock_status
          rating_summary
          stock_text
          stock_color
          stock_color_label
          brand
          brand_label
          gspunna_regular_price
          price_range {
            maximum_price {
              discount {
                amount_off
                percent_off
              }
              final_price {
                currency
                value
              }
              fixed_product_taxes {
                amount {
                  currency
                  value
                }
                label
              }
              regular_price {
                currency
                value
              }
            }
          }
          url_key
      }
        page_info {
          total_pages
          page_size
          current_page
        }
        sort_fields {
          default
          options {
            label
            value
          }
        }
        total_count
    }
  }
`

export const GET_FILTERS = gql`
  query getFilters($filters: ProductAttributeFilterInput!) {
    products(filter: $filters) {
      aggregations {
        attribute_code
        count
        label
        position
        options {
          count
          label
          value
        }
      }
    }
  }
`

export const PPQ = gql`
  ${PRICE_FRAGMENT}
  ${PP_PRODUCT_FRAGMENT}
  ${EXTENDED_CONFIGUREABLE_PRODUCT_FRAGMENT}
  ${DOWNLOADABLE_FRAGMENT}
  ${PRODUCT_FRAGMENT_DETAILS}
  query getProduct($url: String!) {
    products(filter: { url_key: { eq: $url } }) {
      items {

        ...PriceFragment
        ...PPProductFragment
        categories {
          uid
          image
          description
          name
          url_path
          id
          level
          infinite_scroll
          breadcrumbs {
            category_id
            category_uid
            category_name
            category_url_path
            category_level
          }
          product_count
          ... on CategoryTree{
            children{
              uid
              image
              description
              name
              url_path
              id
              infinite_scroll
              breadcrumbs {
                category_id
                category_uid
                category_name
                category_url_path
                category_level
              }
              product_count
            }
          }
        }
        ...ExtendedConfigurableProductFragment
        ...DownloadableFragment
        ...ProductFragmentDetails
        __typename
      }
    }
  }
`

export const getMaterialQ = gql`
  query getMaterial {
    customAttributeMetadata(
      attributes: [{ attribute_code: "brand", entity_type: "catalog_product" }]
    ) {
      items {
        attribute_options {
          value
          label
        }
      }
    }
  }
`

// john
export const COMPACT_CUSTOMER_INFORMATION = gql`
  query compactCustomerInformation {
    customer {
      firstname
      lastname
      email
      # telephone
      gender

      # avatar_base64
      is_subscribed
      addresses {
        id
        firstname
        lastname
        street
        city
        company
        postcode
        country_code
        telephone
        vat_id
        default_billing
        default_shipping
        region_id
        region {
          region_code
          region
          __typename
        }
        prefix
        suffix
        __typename
      }
      orders {
        total_count
        __typename
      }
      __typename
    }
  }
`

export const CUSTOMER_INFORMATION = gql`
query orders($currentPage: Int, $pageSize: Int)
{
  customer {
    orders(currentPage: $currentPage, pageSize: $pageSize) {
      page_info {
          total_pages
          current_page
          page_size
        }
      items {
        id
        order_date
        number
        status
        shipping_method
        extra_comments {
          code
          value
        }
        billing_address {
          prefix
          firstname
          lastname
          city
          fax
          company
          country_code
          postcode
          telephone
          street
          
        }
        shipping_address {
          firstname
          lastname
          city
          company
          country_code
          postcode
          telephone
          street
        }
        payment_methods {
          name
        }
        files {
          title
          url
        }
        total {
          total_tax{
            currency
            value
          }
          subtotal_incl_tax {
            value
            currency
          }
          total_shipping_including_tax{
            currency
            value            
          }
          grand_total {
            currency
            value
          }
        }
        invoices {
          files {
            title
            url
          }
          total {
            # subtotal_incl_tax {
            #   value
            #   currency
            # }
            # total_shipping_including_tax{
            #   currency
            #   value            
            # }
            base_grand_total{
              value
              currency 
            }
            grand_total {
              value
              currency
            }
            subtotal {
              value
              currency
            }
            total_shipping {
              value
              currency
            }
            total_tax{
              value
            }
            taxes{
              amount{
                value
              }
              title
            }
          }
          items {
            product_name
            product_sku
            product_sale_price_incl_tax {
              value
              currency
            }
            quantity_invoiced
          }
        }
        shipments {
          files {
            title
            url
            __typename
          }
          tracking{
            number
          }
          items {
            product_name
            product_sku
            quantity_shipped
          }
        }
        total {
          fooman_applied_surcharges {
            amount{
              currency
              value
              __typename
            }
            amount_including_tax {
              currency
              value
            }
            label
          }
          grand_total {
            value
            currency
          }
          subtotal {
            value
            currency
          }
          taxes {
            amount {
              currency
              value
            }
          }
          total_shipping {
            value
            currency
          }
          discounts {
              amount {
                currency
                value
              }
            }
        }
        items {
          product_url_key
          product_name
          product_sku
          product_sale_price_incl_tax {
              value
              currency
            }
          quantity_ordered
        }
        __typename
      }
      __typename
    }
    __typename
  }
}
`;

export const PRODUCTS_FAVORITE = gql`
query wishlists($currentPage: Int, $pageSize: Int){
  customer{
    wishlists{
      id
      items_v2(currentPage: $currentPage, pageSize: $pageSize){
        page_info {
          total_pages
          current_page
          page_size
        }
        items{
          id
          ... on ConfigurableWishlistItem {
            child_sku
            configured_variant{
              id
              url_key
              color
              name
              # material_filter
              thumbnail {
                url
              }
            }
            product {
              uid
              canonical_url
              categories {
                url_path
                level
              }
              url_key
              name
              # pack
              sku
              # material_filter
              # material_filter_actual
              thumbnail {
                url
                label
              }
              __typename
              color
              # color_filter
            }
          }
          product{
            uid
            canonical_url
            energy_efficiency_label_1 {
            url
          }

          energy_efficiency_label_2 {
            url
          }
            rating_summary
            energy_efficiency_stage 
            energy_efficiency_stage2
            review_count
            reviews {
            items {
              average_rating
              summary
              text
              created_at
              nickname
                }
           }
            small_image {
              url
            }
            price_range {
              maximum_price {
                final_price {
                  currency
                  value
                }
              }
            }
            categories {
              id
              url_path
              level
            }
            url_key
            name
            # pack
            sku
            # material_filter
            # material_filter_actual
            thumbnail{
              url
              label
            }
            __typename
            color
            # color_filter
          }
        }
      }
    }
  }
}
`;

export const GET_AUTO_COMPLETE_RESULTS = gql`
  query getAutocompleteResults($inputText: String!) {
    products(search: $inputText, currentPage: 1, pageSize: 4) {
      items {
      id
      name
      sku
      canonical_url
      brand
      uid
      stock_status
      gspunna_regular_price
      special_price
      energy_efficiency_stage
      energy_efficiency_stage2
      price_range {
        maximum_price {
          discount {
            amount_off
            percent_off
          }
          final_price {
            currency
            value
          }
          fixed_product_taxes {
            amount {
              currency
              value
            }
            label
          }
          regular_price {
            currency
            value
          }
        }
      }
      categories{
        url_path
        level
        id
      }
      small_image {
        url
        __typename
      }
      stock_status
      rating_summary
      __typename
      url_key
      custom_attributes {
        attribute_metadata {
          code
          label
          ui_input {
            ui_input_type
          }
          storefront_properties {
            visible_on_catalog_pages
          }
        } 
        entered_attribute_value {
          value
        }
        selected_attribute_options {
          attribute_option {
            label
            swatch_image
          }
        }
      }
    }
    page_info {
      total_pages
      page_size
      current_page
      __typename
    }
    sort_fields {
      default
      options {
        label
        value
      }
    }
    total_count
      __typename
    }
  }
`

export const PRODUCTS_SEARCH = gql`
  ${PRODUCTS_FRAGMENT}
  ${CONFIGUREABLE_PRODUCT_FRAGMENT}
  ${DOWNLOADABLE_FRAGMENT}
  query ProductSearch(
    $currentPage: Int = 1
    $inputText: String!
    $pageSize: Int = 6
    $filters: ProductAttributeFilterInput!
    $sort: ProductAttributeSortInput
  ) {
    products(
      currentPage: $currentPage
      pageSize: $pageSize
      search: $inputText
      filter: $filters
      sort: $sort
    ) {
      ...ProductsFragment
      __typename
    }
  }
`

export const GET_SEARCH_FILTERS = gql`
  query getFilters($inputText: String!, $catID: String) {
    products(filter:{category_id: { eq: $catID }},search: $inputText) {
      aggregations(filter:{category: {includeDirectChildrenOnly:true}}) {
        attribute_code
        count
        label
        attribute_code
        position
        options {
          count
          label
          value
        }
      }
    }
  }
`
export const GET_SEARCH_SORT_FIELDS = gql`
query getSortFields($inputText: String!){
  products(search: $inputText){
    sort_fields {
      default
      options {
        label
        value
      }
    }
  }
}
`

export const GET_CMS_PAGE = gql`
  query GetCmsPage($id: String!) {
    cmsPage(identifier: $id) {
      url_key
      content
      content_heading
      title
      page_layout
      meta_title
      meta_keywords
      meta_description
      identifier
    }
  }
`

export const GET_CMS_BLOCK = gql`
  query cmsBlocks($id: String!) {
    cmsBlocks(identifiers: [$id]) {
      items {
        content
        identifier
        title
      }
    }
  }
`

export const GET_BANNER_ITEMS = gql`
  query{
  bannerItems{
    id
    image
  	background_image
    title
    content
  }
}
`

export const GET_CAMPAIGNS = gql`
  query campaigns($location: String!) {
    campaigns(filters: { location: $location }) {
      color
      description
      icon
      id
      image
      isEnabled
      link
      title
      toDate
      location
    }
  }
`

export const GET_RANDOM_PRODUCTS = gql`
  ${PRICE_FRAGMENT}
  ${CUSTOM_ATTRIBUTE}
  query($currentPage: Int, $pageSize: Int, $key: String!, $project: String) {
    productListByQuery(
      currentPage: $currentPage
      pageSize: $pageSize
      key: $key
      project: $project
    ) {
      items {
        id
        uid
        gspunna_regular_price
        canonical_url
        name
        brand
        brand_label
        stock_color_label
        eu_norm_60: itg_202402051411156623960939
        no_frost: itg_202303151630144343490582
        special_price
        sku
        stock_status
          itg_202403041353396029910469
          itg_202403041352474933797563
          itg_202303151629545861692577
          energy_efficiency_stage
          energy_efficiency_stage2
          energy1: energy_icon(position: 1)
          energy2: energy_icon(position: 2)
          energy_efficiency_label_1{
            url
          }

          energy_efficiency_label_2 {
          url
          }
        custom_stock_item {
          use_config_qty_increments
          qty_increments
          enable_qty_increments
          use_config_enable_qty_inc

          use_config_max_sale_qty
          max_sale_qty
          use_config_min_qty
          min_qty
          use_config_min_sale_qty
          min_sale_qty
          qty
    } 
        rating_summary
        media_gallery{
          thumbnail_url
          url
          label
          disabled
          position
          __typename
        }
        small_image {
          url
          __typename
        }
        categories {
          id
          url_path
          level
        }
        ...PriceFragment
        ...custom_attribute
      }

      page_info {
        current_page
        page_size
        total_pages
      }
      total_count
    }
  }
`

export const CUSTOM_ATTRIBUTE_META_DATA = gql`
  query {
    customAttributeMetadata(
      attributes: [
        { attribute_code: "brand", entity_type: "catalog_product" }
        {
          attribute_code: "energy_efficiency_stage"
          entity_type: "catalog_product"
        }
        {
          attribute_code: "energy_efficiency_stage2"
          entity_type: "catalog_product"
        }
      ]
    ) {
      items {
        attribute_code
        attribute_type
        entity_type
        input_type
        attribute_options {
          value
          label
        }
      }
    }
  }
`

export const GET_SORT_FiELDS = gql`
  query getSortFields($uid: String!) {
    products(filter: { category_uid: { eq: $uid } }) {
      sort_fields {
        default
        options {
          label
          value
        }
      }
    }
  }
`

export const GET_CATEGORY_CHILDREN = gql`
  query($category_id: Int) {
    category(id: $category_id) {
      products {
        total_count
        page_info {
          current_page
          page_size
        }
      }
      children_count
      children {
        id
        level
        name
        path
        url_path
        product_count
        breadcrumbs {
          category_id
          category_level
          category_name
          category_uid
          category_url_key
          category_url_path
        }
        children {
          id
          level
          name
          path
          url_path
          product_count
          breadcrumbs {
            category_id
            category_level
            category_name
            category_uid
            category_url_key
            category_url_path
          }
          children {
            id
            level
            name
            path
            url_path
            product_count
            breadcrumbs {
              category_id
              category_level
              category_name
              category_uid
              category_url_key
              category_url_path
            }
            children {
              id
              level
              name
              path
              url_path
              product_count
              breadcrumbs {
                category_id
                category_level
                category_name
                category_uid
                category_url_key
                category_url_path
              }
            }
          }
        }
      }
    }
  }
`

export const FAQ_QUESTION_LIST = gql`
  query {
    faqQuestionList {
      items {
        id
        title
        description
        sort_order
      }
    }
  }
`;

export const COUNTRIES = gql`
query {
    countries {
        id
        two_letter_abbreviation
        three_letter_abbreviation
        full_name_locale
        full_name_english
    }
}`;

export const REGIONS = gql`
query ($id: String! = "CH"){
      country(id: $id) {
        id
        available_regions {
            id
            code
            name
        }
}
}`;

export const GET_PRODUCT_BY_SKU = gql`
query productsBySku ($sku: [String]) {
  products(filter:{sku:{in: $sku}}) { 
    items {
      id
      sku
      uid
      name
      url_key
      small_image{
        url
      }
      thumbnail{
        url
      }
      custom_attributes {
        attribute_metadata {
          code
          label
          ui_input {
            ui_input_type
          }
          storefront_properties {
            visible_on_catalog_pages
          }
        } 
        entered_attribute_value {
          value
        }
        selected_attribute_options {
          attribute_option {
            label
            swatch_image
          }
        }
      }
      price_range {
        maximum_price {
          discount {
            amount_off
            percent_off
          }
          final_price {
            currency
            value
          }
          fixed_product_taxes {
            amount {
              currency
              value
            }
            label
          }
          regular_price {
            currency
            value
          }
        }
      }
      price_tiers {
        discount {
          amount_off
          percent_off
        }
        final_price {
          currency
          value
        }
        quantity
      }
      files {
        title
        url
        __typename
      }
      brand
      sku
      url_key
      canonical_url
      __typename
      stock_color
      stock_text
      stock_status 
      }
    }
}
`;

export const IS_EMAIL_AVAILABLE = gql`
query ($email: String!){
  isEmailAvailable(email: $email) {
    is_email_available
  }
}
`;

export const PRODUCT_REVIEW_RATINGS_METADATA = gql`
query {
  productReviewRatingsMetadata {
    items {
      id
      name
      values {
        value_id
        value
      }
    }
  }
}
`

export const CATEGORY_LIST_BY_ATTRIBUTE = gql`
query GetCategoriesByAttibute{
  categoryListByAttribute(
    custom_attributes:[
         {
            attribute_code: "is_special"
            attribute_value: "1"
        }
      ]
  ) {
      id
      uid
      level
      name
      path
      url_path
      url_key
      is_special
      
 }
}
`
export const SPECIAL_CATEGORIES_KEYS = gql`
query specialCategoriesKeys{
  homePageSections{
    keys{
        key
        label
    }
    categories{
        category_id
        label
    }
  }
}
`

export const RECENT_VIEWED_PRODUCTS = gql`
query {
  recentViewedProducts {
    total_count
    currentPage
    pageSize
    totalPages
    items {
      id
      sku
      uid
      name
      url_key
      small_image{
        url
      }
      custom_attributes {
        attribute_metadata {
          code
          label
          ui_input {
            ui_input_type
          }
          storefront_properties {
            visible_on_catalog_pages
          }
        } 
        entered_attribute_value {
          value
        }
        selected_attribute_options {
          attribute_option {
            label
            swatch_image
          }
        }
      }
      price_range {
        maximum_price {
          discount {
            amount_off
            percent_off
          }
          final_price {
            currency
            value
          }
          fixed_product_taxes {
            amount {
              currency
              value
            }
            label
          }
          regular_price {
            currency
            value
          }
        }
      }
      price_tiers {
        discount {
          amount_off
          percent_off
        }
        final_price {
          currency
          value
        }
        quantity
      }
      files {
        title
        url
        __typename
      }
      brand
      sku
      url_key
      canonical_url
      __typename
      stock_color
      stock_text
      stock_status
    }
  }  
}
`

export const GET_PRODUCTS_PRICES = gql`
query getProductsPrices(
  $pageSize: Int!
  $currentPage: Int!
  $filters: ProductAttributeFilterInput!
  $sort: ProductAttributeSortInput
) {
  getProductsPrices: products(
    pageSize: $pageSize
    currentPage: $currentPage
    filter: $filters
    sort: $sort
  ) {
    items {
      sku
      gspunna_regular_price
      custom_stock_item {
        qty_increments
        enable_qty_increments
        max_sale_qty
        min_sale_qty
        qty
      } 
      price_range {
        maximum_price {
          discount {
            amount_off
            percent_off
          }
          final_price {
            currency
            value
          }
          fixed_product_taxes {
            amount {
              currency
              value
            }
            label
          }
          regular_price {
            currency
            value
          }
        }
      }
      special_price
      stock_text
      stock_color
      stock_color_label
      brand
      brand_label
    }
  }
}
`
export const SLIDERS = gql`
${BANNER_FRAGMENT}
query banner($position: String!){
  sliders(input: { position: { eq: $position } }) {
    id
    name
    banners {
      ...BannerItemFragment
    }
  }
}`