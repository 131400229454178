import zipCodes from '../zip_codes.json'

// const validateEmail = (email) => {
//     return Boolean(String(email)
//         .toLowerCase()
//         .match(
//             /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
//         );
// };

const validateEmail = (email) => {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return regex.test(email);
};


const validatePassword = (password) => {
    return password?.length >= 8
};

const validateConfirmPassword = (password, confirmPassword) => {
    return password === confirmPassword
};

const validateCurrentAndNewPassword = (currentPassword, newPassword) => {
    return currentPassword !== newPassword
};

const validatePostCode = (postCode, countryCode) => {
    let postLists = zipCodes[countryCode]
    return postLists?.Type === "list" ? postLists?.Value.includes(postCode) : postLists?.Value?.filter(pattern => { const regex = new RegExp(pattern); return regex.test(postCode) }).length > 0
}

const validAddressAndHomeNumber = (address) => {
    address = Array.isArray(address) ? address[0] : address
    const [a, b] = [address?.split(' ')?.slice(0, -1)?.join(' '), address?.split(' ')?.at(-1)] || ["", ""]
    return (a && b && a.length >= 2 && b.length > 0)
}

const validAdress = (address) => {

    if (address) {
        const addressField = address?.address1 || address.street
        switch (address.prefix || "Herr") {
            case "Herr":
            case "Frau":
                return (validatePostCode(address.postcode, address.country_code || address.country?.code) &&
                    Boolean(address.city) &&
                    validAddressAndHomeNumber(addressField) &&
                    Boolean(address.telephone) &&
                    Boolean(address.firstname) && Boolean(address.lastname))
            default:
                return (validatePostCode(address.postcode, address.country_code || address.country?.code) &&
                    ((address?.company || "").length >= 2) &&
                    validAddressAndHomeNumber(addressField) &&
                    Boolean(address.telephone) &&
                    Boolean(address.city) &&
                    Boolean(address.firstname) && Boolean(address.lastname))
        }
    }
    return false
}


export { validAdress, validateEmail, validatePassword, validateConfirmPassword, validateCurrentAndNewPassword, validatePostCode };