import { Box, Button, Grid, Typography } from '@mui/material';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react'
import useCss from '../../hooks/useCss';
import useTranslation from '../../hooks/useTranslation';
import SearchItem from './searchItem';
import { Close } from '@mui/icons-material';

const SearchRes = ({ srchData, search, setSearch }) => {

	const [srch, setSrch] = useState()

	useEffect(() => {
		setSrch(search)
	}, [search])

	const router = useRouter()
	const { t } = useTranslation()
	const { c } = useCss()

	const searchHandler = () => {
		router.push(`/catalogsearch/result/{"q":"${search}"}`)
		setSearch('')
	}
	return (
		<Box sx={{
			width: { lg: "45vw", sm: "95vw", xs: "94vw" },
			maxWidth: { lg: "45vw", sm: "100vw", md: "98vw" },
			borderRadius: c('borderRadius'),
			boxShadow: c('shadow')
		}}>
			{srchData ?
				<Box id="srch-res" className={"bg-white"} sx={{ borderRadius: c('borderRadius') }}>
					<Box sx={{ display: 'flex', justifyContent: { lg: 'end', sm: "center" }, px: { lg: 2, sm: 1 }, height: { lg: " 6vh", xs: "9vh" } }}>
						<Box sx={{
							display: { lg: 'none', xs: 'flex' }, height: "100%", alignItems: "center"
						}}>
							<Button
								onClick={() => setSearch('')
								}>
								<Close style={{ color: `${c('mid-gray')}` }} />
							</Button>
						</Box>
						<Box sx={{ display: { lg: 'flex', xs: 'block' }, alignItems: "center", justifyContent: 'end', my: { xs: "auto", lg: "0" }, ml: { xs: "3rem", lg: 0 } }}>
							<Typography variant="p" className='black fw-500'
								sx={{ p: { lg: "10px", xs: "auto" }, my: { xs: "auto", lg: "unset" }, display: "block", fontFamily: "Roboto", fontSize: '15px', py: { lg: "0px", sm: 0 } }}>
								{srchData?.products.total_count + ' ' + t('Items found')}
							</Typography>
							<Typography variant="a" sx={{ p: { lg: "10px", xs: "auto", display: "block" }, marginBottom: 0.5, lineHeight: { lg: "16px", xs: "0" }, py: { lg: "0px", sm: 0 }, display: (srchData.products?.total_count ? '' : 'none'), textDecoration: 'underline', fontSize: '15px' }}
								style={{ cursor: "pointer" }}
								onClick={() => {
									searchHandler()
								}}
								className={'fw-500'}>
								{t('Show everything')}
							</Typography>
						</Box>

					</Box>
					<Box className="items" >
						{
							srchData?.products?.items.map((product) =>
								<SearchItem product={product} setSearch={setSearch} key={product?.canonical_url} />
							)
						}
					</Box>
				</Box>
				:
				null
			}
		</Box>
	)
}

export default SearchRes