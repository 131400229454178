import cookie from 'react-cookies'

export const getCookie = (name, parse = false) => {
    let ret = cookie.load(name)
    if (ret) {
        if (parse)
            return JSON.parse(ret)
        else
            return ret
    } else
        return ""
}