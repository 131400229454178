import { gql } from "@apollo/client";
import { CUSTOM_ATTRIBUTE, PRICE_FRAGMENT } from "../fragments";

export const CART_PAYMENTS_FRAGMENT = gql`
fragment cartPayments on Cart {
  email
  available_payment_methods {
    instructions
    code
    title
  }
  selected_payment_method {
          code
  }
}
`

export const CART_FRAGMENT = gql`
fragment CartFragment on Cart {
  cart_message
  error_message
  applied_coupons {
    code
  }
  extra_comments {
    code
    value
  }
  email
  comment
  id
  delivery_date_values {
    delivery_date_value
    delivery_time_value
    floor_delivery_date_value
    floor_delivery_time_value
    house_security_code
    floor_house_security_code
  }
  items {
    uid
    id
    errors{
      message
    }
    quantity
    warranty_option
    shipping_method_option
    selected_shipping_method_option {
      code
      label
    }
    prices {
      price_including_tax {
        currency
        value
      }
      row_total_including_tax {
        currency
        value
      }
      price {
        currency
        value
      }
    }

    product {
      categories{
        id
      }
      id
      sku
      post_sperrgut
      post_sperrgut2
      ship_by_dpd_possible
      disable_give_old_item_back
      custom_attributes {
            attribute_metadata {
              code
              label
              ui_input {
                ui_input_type
              }
              storefront_properties {
                visible_on_catalog_pages
              }
            } 
            entered_attribute_value {
              value
            }
            selected_attribute_options {
              attribute_option {
                label
                swatch_image
              }
            }
          }
      sku
      name
      custom_stock_item {
        use_config_qty_increments
        qty_increments
        enable_qty_increments

        max_sale_qty
        min_qty
        min_sale_qty
        qty
      }
      image {
        url
      }
      small_image {
        url
      }
      canonical_url
      extra_options {
        guarantee_option_list {
          code
          label
          price
        }
        shipping_method_list {
          code
          label
          price
          flat_price
          description
        }
      }
    }
  }

  shipping_addresses {
    prefix
    firstname
    lastname
    street
    city
    fax
    region {
      code
      label
    }
    country {
      code
      label
    }
    telephone
    company
    postcode
    available_shipping_methods {
      amount {
        currency
        value
      }
      price_incl_tax{
        currency
        value
      }
      image
      description
      method_title
      carrier_code
      method_code
    }
    selected_shipping_method {
      amount {
        value
        currency
      }
      method_title
      carrier_code
      method_code
    }
  }

  give_item_back_options {
    code
    label
    price
  }

  billing_address {
    prefix
    firstname
    lastname
    street
    city
    region {
      code
      label
    }
    country {
      code
      label
    }
    telephone
    fax
    company
    postcode
  }
  storePickupList {
    code
    label
    store_email
    map_url
    store_telephone
    company
    address
    opening_hours {
      key
      value
    }
  }
  selected_store_code
}
`

export const CART_PRICES_FRAGMENT = gql`
fragment CartPricesFragment on Cart {
  prices {
      fooman_applied_surcharges {
        amount {
          currency
          value
        }
        amount_including_tax{
            currency
          value
        }
        label
      }
      warranty {
        amount{
            currency
            value
          }
          label
      }
      smallorderfee {
        amount{
            currency
            value
          }
          label
      }
      applied_taxes {
        amount {
          currency
          value
        }
        label
      }
      discounts {
        amount {
          currency
          value
        }
        label
      }
      grand_total {
        currency
        value
      }
      subtotal_including_tax {
        currency
        value
      }
    }
}
`
export const ADD_TO_CART_FRAGMENT = gql`
fragment AddToCartFragment on Cart {
  id
  items {
    uid
    id
    quantity
    warranty_option
    shipping_method_option
    selected_shipping_method_option {
      code
      label
    }
    prices {
      price_including_tax {
        currency
        value
      }
      row_total_including_tax {
        currency
        value
      }
      price {
        currency
        value
      }
    }

    product {
      disable_give_old_item_back
      post_sperrgut
      post_sperrgut2
      ship_by_dpd_possible
      sku
      name
      custom_attributes {
            attribute_metadata {
              code
              label
              ui_input {
                ui_input_type
              }
              storefront_properties {
                visible_on_catalog_pages
              }
            } 
            entered_attribute_value {
              value
            }
            selected_attribute_options {
              attribute_option {
                label
                swatch_image
              }
            }
        }
      custom_stock_item {
        use_config_qty_increments
        qty_increments
        enable_qty_increments

        max_sale_qty
        min_qty
        min_sale_qty
        qty
      }
      image {
        url
      }
      small_image {
        url
      }
      canonical_url
      extra_options {
        guarantee_option_list {
          code
          label
          price
        }
        shipping_method_list {
          code
          label
          price
          flat_price
        }
      }
    }
  }
  shipping_addresses {
    prefix
    firstname
    lastname
    street
    city
    fax
    region {
      code
      label
    }
    country {
      code
      label
    }
    telephone
    company
    postcode
    available_shipping_methods {
      amount {
        currency
        value
      }
      image
      description
      method_title
      carrier_code
      method_code
    }
    selected_shipping_method {
      amount {
        value
        currency
      }
      method_title
      carrier_code
      method_code
    }
  }
  prices {
    fooman_applied_surcharges {
      amount {
        currency
        value
      }
      amount_including_tax {
        currency
        value
      }
      label
    }
    warranty {
      amount {
        currency
        value
      }
      label
    }
    smallorderfee {
      amount {
        currency
        value
      }
      label
    }
    applied_taxes {
      amount {
        currency
        value
      }
      label
    }
    discounts {
      amount {
        currency
        value
      }
      label
    }
    grand_total {
      currency
      value
    }
    subtotal_including_tax {
      currency
      value
    }
  }
}

`