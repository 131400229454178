import Image from 'next/image';
import Link from 'next/link';
import React, { useEffect, useState } from 'react'
import { useGlobalStore } from '../../stores/globalStore';
import { useCacheStore } from '../../stores/cacheStore';

const Logo = () => {

	const [logo, setLogo] = useState()
	const storeConfig = useCacheStore(state => state.storeConfig)


	const whiteDot = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="

	useEffect(() => {
		setLogo(localStorage.getItem('logo'))
	}, [])

	useEffect(() => {
		if (storeConfig) {
			setLogo(storeConfig.header_logo_src)
			localStorage.setItem('logo', storeConfig.header_logo_src)

		}
	}, [storeConfig])

	return (

		<Link href={'/'}>
			<Image width={270} height={70} className="logo nonselect"
				alt={"Frankenspalter logo"}
				src={(!logo ? whiteDot : process.env.NEXT_PUBLIC_LOGO_BASE_URL + logo)}
				style={{ maxWidth: "100%", objectFit: "contain", objectPosition: "left" }}
			/>
		</Link>
	)
}

export default Logo