import React from 'react'

const BasketIcon = () => {
  return <svg width="22" height="22" viewBox="0 0 29 26" fill="currentcolor" xmlns="http://www.w3.org/2000/svg">
    <path d="M27.8859 5.74314C27.355 5.09455 26.5294 4.70034 25.6749 4.70034H7.72244L7.1628 2.61473C6.83805 1.40234 5.71877 0.556641 4.42096 0.556641H1.41434C0.913422 0.556641 0.5 0.950849 0.5 1.43098C0.5 1.90998 0.912243 2.30532 1.41434 2.30532H4.42096C4.86316 2.30532 5.24663 2.58722 5.36407 3.01007L8.96029 16.711C9.28503 17.9233 10.4043 18.769 11.7021 18.769H23.4639C24.7605 18.769 25.9109 17.9233 26.2057 16.711L28.4167 8.02816C28.6228 7.23752 28.4467 6.39174 27.8859 5.74314ZM26.6192 7.63164L24.4082 16.3144C24.2907 16.7373 23.9073 17.0192 23.4651 17.0192C23.4651 17.0192 22.5 16.6094 17.5836 16.6094C12.6673 16.6094 11.7022 17.0192 11.7022 17.0192C11.26 17.0192 10.8765 16.7373 10.759 16.3144L8.19455 6.4767H25.6762C25.971 6.4767 26.2658 6.61765 26.4432 6.8434C26.6194 7.06804 26.7067 7.34974 26.6192 7.63164Z" fill="currentcolor" />
    <path d="M15.2534 22.8251C15.2534 21.145 13.9287 19.7598 12.3222 19.7598C10.7157 19.7598 9.39104 21.1451 9.39104 22.8251C9.38994 24.5052 10.7146 25.8903 12.3214 25.8903C13.9281 25.8903 15.2534 24.505 15.2534 22.8251ZM11.1659 22.8251C11.1659 22.1468 11.6735 21.6159 12.3221 21.6159C12.9707 21.6159 13.9924 22.1468 13.9924 22.8251C13.9924 23.5033 12.9707 24.0342 12.3221 24.0342C11.7011 24.033 11.1659 23.4734 11.1659 22.8251Z" fill="currentcolor" />
    <path d="M22.4625 25.7559C24.1426 25.7559 25.5278 24.4312 25.5278 22.8247C25.5278 21.2182 24.1425 19.8935 22.4625 19.8935C20.7826 19.8935 19.3973 21.2182 19.3973 22.8247C19.426 24.4302 20.7826 25.7559 22.4625 25.7559ZM22.4625 21.6683C23.1408 21.6683 23.6717 22.176 23.6717 22.8246C23.6717 23.4731 23.1408 23.9808 22.4625 23.9808C21.7843 23.9808 20.9546 23.4731 20.9546 22.8246C20.9546 22.2035 21.8142 21.6683 22.4625 21.6683Z" fill="currentcolor" />
  </svg>
}

export default BasketIcon