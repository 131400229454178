import { Avatar, Box, Fab, Modal, Popover } from "@mui/material";
import CallIcon from '@mui/icons-material/Call';
import NorthIcon from '@mui/icons-material/North';
import { useState } from "react";
import Questions from "./questions";
import useCss from "../../hooks/useCss";
import { useRouter } from "next/router";
const QuestionButton = () => {

    const { c } = useCss()
    const [anchorEl, setAnchorEl] = useState(null);
    const router = useRouter()
    const [open, setOpen] = useState(false)
    const [visible, setVisible] = useState(false)

    const toggleVisible = () => {

        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 450 && !visible) {
            setVisible(true)
        }
        else if (scrolled <= 200 && visible) {
            setVisible(false)
        }

    };
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setOpen(true)
        document.getElementById('question_btn').style.right = "18px"
        document.getElementById('scroll_UpButton').style.right = "18px"
    };

    const handleClose = () => {
        setAnchorEl(null);
        setOpen(false)
        document.getElementById('question_btn').style.right = "12px"
        document.getElementById('scroll_UpButton').style.right = "12px"
    };



    if (typeof window !== 'undefined') {
        window.addEventListener('scroll', toggleVisible);
    }

    return (
        <>
            <Fab id="scroll_UpButton" onClick={scrollToTop}
                sx={{
                    display: visible && !(router.asPath.split("/")[1].startsWith("check-out") || (router.asPath.split("/")[1] === "cart.html")) ? 'inline' : 'none',
                    displayPrint: "none",
                    position: 'fixed',
                    left: "calc(100vw - 55px)",
                    bottom: { xs: 'calc(13% + 85px)' },
                    width: { xs: "40px", md: "29px" },
                    height: { xs: "40px", md: "29px" },
                    minHeight: "unset",
                    boxShadow: '0px 3px 3px -1px rgb(0 0 0 / 20%), 0px 6px 5px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%) !important',
                }} >
                <NorthIcon sx={{ width: "16px", height: "18px" }} />
            </Fab>
            <Avatar
                id="question_btn"
                className="bg-black bg-hover-dark-gray "
                sx={{
                    display: !(router.asPath.split("/")[1].startsWith("check-out") || (router.asPath.split("/")[1] === "cart.html")) ? "flex" : "none",
                    position: "fixed",
                    bottom: { xs: 'calc(13% + 35px)' },
                    left: "calc(100vw - 55px)",
                    zIndex: "12",
                    width: { xs: "40px", md: "29px" },
                    height: { xs: "40px", md: "29px" },
                    borderRadius: "50%",
                    cursor: "pointer",
                    alignItems: "center",
                    justifyContent: "center",
                    displayPrint: "none",
                }}
                onClick={handleClick}
            >
                <CallIcon className="white" sx={{ width: "18px", height: "18px" }} />
            </Avatar>

            <Modal
                open={open}
                onClose={(e) => { setOpen(false); }}
                sx={{ "& .MuiModal-backdrop": { opacity: "0.3 !important" }, top: "calc(100vh - 240px)", left: "calc(100vw - 370px)" }}
            >
                <Questions handleClose={handleClose} />
            </Modal>
            {/* <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
                sx={{
                    displayPrint: "none",
                    '& .MuiPaper-elevation':
                    {
                        py: "30px",
                        px: "50px",
                        ml: "-16px",
                        border: `1px solid ${c('gray')}`,
                        borderRadius: "8px"
                    }
                }}
            >
                <Questions handleClose={handleClose} />
            </Popover> */}
        </>
    );
}

export default QuestionButton;