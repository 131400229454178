import { Fab } from '@mui/material'
import React, { useState } from 'react'
import NorthIcon from '@mui/icons-material/North';
import { useRouter } from 'next/router';

const FabButton = () => {

    const router = useRouter()
    const [visible, setVisible] = useState(false)

    const toggleVisible = () => {

        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 450 && !visible) {
            setVisible(true)
        }
        else if (scrolled <= 200 && visible) {
            setVisible(false)
        }

    };
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };
    if (typeof window !== 'undefined') {
        window.addEventListener('scroll', toggleVisible);
    }

    return (
        <Fab id="scroll_UpButton" onClick={scrollToTop}
            sx={{
                display: visible && !(router.asPath.split("/")[1].startsWith("check-out") || (router.asPath.split("/")[1] === "cart.html")) ? 'inline' : 'none',
                displayPrint: "none",
                position: 'fixed',
                right: '12px',
                bottom: { xs: 'calc(13% + 85px)' },
                width: { xs: "40px", md: "29px" },
                height: { xs: "40px", md: "29px" },
                minHeight: "unset",
                boxShadow: '0px 3px 3px -1px rgb(0 0 0 / 20%), 0px 6px 5px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%) !important',
            }} >
            <NorthIcon sx={{ width: "16px", height: "18px" }} />
        </Fab>
    )
}

export default FabButton