import { gql } from "@apollo/client";
import { WISHLIST_FRAGMENT } from "./fragments";

export const GET_WISHLIST = gql`
${WISHLIST_FRAGMENT}
query getWishlist($currentPage: Int!, $pageSize: Int!) {
  customer{
    wishlists{
      ...WishlistsFragment
    }
  }
}
`;
