import { Box, Button } from '@mui/material';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import useTranslation from '../../hooks/useTranslation';

const Cookies = () => {
    const [cookieAccept, setCookieAccept1] = useState(true);

    useEffect(() => {
        setCookieAccept1(document.cookie.includes("Cookie=Accept") || false)
    }, [])

    const setCookieAccept = () => {
        document.cookie = "Cookie=Accept; expires=Sun, 1 Jan 2030 00:00:00 UTC; path=/"
        setCookieAccept1(true)
    }

    const { t } = useTranslation()

    return (
        <Box id="cookies-accept" sx={{
            fontSize: "11px",
            backgroundColor: "rgba(0, 0, 0, 0.85)",
            position: "fixed",
            zIndex: "99999999999",
            bottom: 0,
            left: 0,
            right: 0,
            color: "#fff",
            display: cookieAccept ? "none" : "block",
            p: "25px 15px 15px 15px", textAlign: "center",
            displayPrint: "none"
        }}>
            <Box component="p" m={0} textAlign="center">
                <Box component="strong">
                    {t("We use cookies and similar technologies to improve your experience on our website")}.
                </Box>
                <Box component="span" ml={0.5}>
                    {t("We collect data about pages viewed, purchases made or buttons clicked. This enables us to offer you the best possible offer and to use personalized content in a targeted manner")}.
                </Box>
                <Link href="/datenschutz" style={{ fontWeight: 600, color: "#008DCF", marginLeft: "8px" }}>
                    {t("Learn more here")}
                </Link>
            </Box>
            <Button onClick={() => { setCookieAccept(true) }}
                sx={{
                    padding: "0 20px", height: "37px", mt: 2,
                    border: "1px solid #fff",
                    color: "#FFF", bgcolor: "rgba(0, 0, 0, 0.85)",
                    ":hover": { color: "rgba(0, 0, 0, 0.85)", bgcolor: "#FFF" }
                }}
            >
                {t("Close")}
            </Button>
        </Box>
    );
}

export default Cookies;
