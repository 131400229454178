import { create } from "zustand"
import { createJSONStorage, persist } from "zustand/middleware"
import { cookieStorage } from "./cookieStorage"
import { mountStoreDevtool } from "simple-zustand-devtools"

interface AuthStoreType {
  ready: boolean
  setReady: ( status: boolean ) => void
  token: string
  isAuthenticated: () => boolean
  signIn: ( token: string ) => void
  signOut: () => void
}



export const useAuthStore = create<AuthStoreType>()(
  persist(
    ( set, get ) => ( {
      ready: false,
      setReady: ( status ) => {
        set( () => ( { ready: status } ) )
      },
      token: "",
      isAuthenticated: () => {
        return get().ready && Boolean( get().token )
      },
      signIn: ( token: string ) => {
        set( () => ( { token: token } ) )
      },
      signOut: () => {
        set( () => ( { token: "" } ) )
      },
    } ),
    {
      onRehydrateStorage: ( state ) => {
        // optional
        return ( state, error ) => {
          if ( error )
          {
            console.log( 'an error happened during hydration', error )
          } else
          {
            state?.setReady( true )
          }
        }
      },
      storage: createJSONStorage( () => cookieStorage ),
      name: "Auth",
      partialize: ( state ) => ( { token: state.token } ),
    }
  )
)

// if ( process.env.NODE_ENV === "development" )
// {
//   mountStoreDevtool( "AuthStore", useAuthStore )
// }
