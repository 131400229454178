import styles from "../../styles/menuDrawer.module.scss"
import { useEffect, useState } from 'react';
import { Button, Drawer, Grid, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Close from '@mui/icons-material/Close';
import Logo from "../logo";
import useTranslation from '../../hooks/useTranslation';
import useCss from '../../hooks/useCss';
import SelectedCategory from './selectedCategory';
import { useRouter } from "next/router";
import Link from "next/link";

const MenuDrawer = ({ mainCategories, specialCatData }) => {

    const [open, setOpen] = useState(false)
    const { t } = useTranslation()
    const { c } = useCss()
    const router = useRouter()

    /* *********** get currentCat ***********/

    const [node, setNode] = useState("undefined")
    const [ancestor, setAncestor] = useState([])
    let stack = []

    return (
        <>
            <Button
                onClick={() => setOpen(true)}
                sx={{
                    display: { xl: 'none ', lg: 'none', xs: 'block', sm: "block", md: 'none' }
                    , minWidth: '45px !important', maxHeight: '43px !important', mt: 0.6, ml: "-5px", mr: 0.4
                }}>
                <MenuIcon style={{ color: `${c('mid-gray')}`, width: "32px", height: "32px" }} />
            </Button>
            <Drawer open={open} id={styles.MainMenuDrawer} sx={{ height: "100%", '& .MuiPaper-root.MuiPaper-elevation': { maxWidth: "100%" } }}>
                <Grid role="presentation" className={styles.menudrawer} sx={{ minHeight: "88%" }}>
                    <Grid className={styles.drawer_header} columns={12} sx={{
                        display: "flex", height: "3.4rem", pr: 1, borderBottom: `1px solid ${c('gray')}`
                    }}>
                        <Grid item xs={4}>
                            <Button onClick={() => setOpen(false)}
                                sx={{
                                    color: "black", backgroundColor: c('light-gray'),
                                    borderRadius: "50%", minWidth: '45px !important', height: '45px !important', ml: 1, mt: '10px', mr: 2
                                }}>
                                <Close />
                            </Button>
                        </Grid>
                        <Grid className={styles.special_cats_container} xs={8} item>
                            <Grid className={styles.special_items} >
                                {specialCatData?.categoryListByAttribute ? specialCatData.categoryListByAttribute.map((item) =>
                                    <Typography onClick={() => setOpen(false)} variant='span' sx={{ mr: 3, fontSize: "0.7rem", pt: 0.5, fontWeight: "600", '& a': { color: "black !important" } }} key={item.url_path}>
                                        <Link href={`${item.url_path}` + ".html"} prefix="/" >
                                            {item.name?.toUpperCase()}
                                        </Link>
                                    </Typography>
                                )
                                    :
                                    null
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid sx={{ height: "100%" }}>
                        <SelectedCategory mainCategories={mainCategories} setAncestor={setAncestor} setOpen={setOpen}
                            node={node} setNode={setNode} ancestor={ancestor} stack={stack}
                        />
                        <Grid sx={{ justifyContent: "center", display: "flex", width: "100%", height: "25%", alignItems: "end" }}>
                            <Logo />
                        </Grid>
                    </Grid>
                </Grid>

            </Drawer>
        </>

    )
}

export default MenuDrawer