import React from 'react'

const useCss = () => {
  const color = {
    black: '#404040',
    'light-black': '#575758',
    'dark-gray': '#777777',
    gray: '#e2e2e2',
    'light-gray': '#F6F6F8',
    white: '#fefefe',
    blue: '#008DCF',
    lemon: '#CAD108',
    green: '#03943E',
    'light-green': '#5ECE7B',
    yellow: '#FFBB00',
    red: '#D12727',
    'mid-gray': '#8E8E8E',
    'extra-light-gray': '#f6f6f6',
    'light-green': '#D9FFE4',
    shadow:
      '2px -6px 10px 0px #00000014, -5px 2px 10px 0px #00000014, 5px 2px 10px 0px #00000014, 2px 8px 10px 0px #00000014',
    borderRadius: '8px !important',
  }
  const c = (n) => {
    return color[n] || color['black']
  }
  return { c }
}

export default useCss
