import { gql } from '@apollo/client'
import { ADD_TO_CART_FRAGMENT, CART_FRAGMENT, CART_PAYMENTS_FRAGMENT, CART_PRICES_FRAGMENT } from './fragments'

export const ADD_PRODUCT_TO_CART = gql`
${ADD_TO_CART_FRAGMENT}
  mutation addProductsToCart(
    $cart_id: String!
    $sku: String!
    $quantity: Float!
  ) {
    action: addProductsToCart(
      cartId: $cart_id
      cartItems: { sku: $sku, quantity: $quantity }
    ) {
      user_errors {
        message
      }
      cart {
        ...AddToCartFragment
      }
    }
  }
`

export const ADD_BUNDLE_PRODUCT_TO_CART = gql`
  mutation addBundleProductsToCart(
    $cart_id: String!
    $sku: String!
    $quantity: Float!
  ) {
    action: addBundleProductsToCart(
      input: {
        cartId: $cart_id
        cart_items: {
          data: { sku: $sku, quantity: $quantity }
          bundle_options: { id: 1, quantity: 1, value: "1" }
        }
      }
    ) {
      user_errors {
        message
      }
      cart {
        items {
          product {
            uid
            sku
            extra_options {
              shipping_method_list {
                code
              }
            }
          }
        }
      }
    }
  }
`

export const REMOVE_PRODUCT_FROM_CART = gql`
  mutation removeItemFromCart($cart_id: String!, $cart_item_uid: ID!) {
    action: removeItemFromCart(
      input: { cart_id: $cart_id, cart_item_uid: $cart_item_uid }
    ) {
      cart {
        __typename
      }
    }  
  }
`
export const UPDATE_CART_ITEMS = gql`
  ${CART_PRICES_FRAGMENT}
  ${ADD_TO_CART_FRAGMENT}
  mutation updateCartItems(
    $cart_id: String!
    $cart_item_uid: ID!
    $quantity: Float!
  ) {
    action: updateCartItems(
      input: {
        cart_id: $cart_id
        cart_items: { cart_item_uid: $cart_item_uid, quantity: $quantity }
      }
    ) {
      cart {
        ...AddToCartFragment
      }
      cartPrices: cart {
        ...CartPricesFragment
      }
    }
  }
`

export const UPDATE_ALL_CART_ITEMS = gql`
mutation updateCartItems(
  $cartId: String!
  $cartItems: [CartItemUpdateInput!]!
) {
  action: updateCartItems(input: { cart_id: $cartId, cart_items: $cartItems }) {
    cart {
      id
    }
  }
}
`

export const CREATE_GUEST_CART = gql`
  mutation {
    cart_id: createEmptyCart
  }
`

export const EMPTY_CART_ADDRESSES = gql`
  mutation emptyQuoteAddresses($cart_id: String!) {
  emptyQuoteAddresses(
    cart_id: $cart_id
  )
}`

export const MERGE_CARTS = gql`
  mutation mergeCarts($source_cart_id: String!) {
    mergeCarts(source_cart_id: $source_cart_id) {
      id
    }
  }
`

export const ASSIGN_GUEST_CART_TO_CUSTOMER = gql`
  mutation assignCustomerToGuestCart($cart_id: String!) {
    assignCustomerToGuestCart(cart_id: $cart_id) {
      id
    }
  }
`

export const SET_CUSTOMER_SHIPPING_ADDRESS_ON_CART = gql`
  ${CART_PRICES_FRAGMENT}
  mutation setShippingAddressesOnCart(
    $cart_id: String!
    $customer_address_id: Int!
  ) {
    setShippingAddressesOnCart(
      input: {
        cart_id: $cart_id
        shipping_addresses: [
          { customer_address_id: $customer_address_id }
        ]
      }
    ) {
      cart {
        shipping_addresses {
          prefix
          firstname
          lastname
          street
          city
          fax
          region {
            code
            label
          }
          country {
            code
            label
          }
          telephone
          company
          postcode
          available_shipping_methods {
            amount {
              currency
              value
            }
            image
            description
            method_title
            carrier_code
            method_code
          }
          selected_shipping_method {
            amount {
              value
              currency
            }
            method_title
            carrier_code
            method_code
    }
  }
      }
      cartPrices: cart {
        ...CartPricesFragment
      }
    }
  }
`

export const SET_SHIPPING_ADDRESS_ON_CART = gql`
  ${CART_PRICES_FRAGMENT}
  mutation setShippingAddressesOnCart(
    $cart_id: String!
    $firstname: String!
    $lastname: String!
    $telephone: String!
    $fax: String
    $prefix: String
    $company: String
    $country_code: String!
    $postcode: String!
    $city: String!
    $street: [String]!
    $region_id: Int
    $vat_id:String
  ) {
    setShippingAddressesOnCart(
      input: {
        cart_id: $cart_id
        shipping_addresses: [
          {
            address: {
              firstname: $firstname
              lastname: $lastname
              company: $company
              street: $street
              city: $city
              postcode: $postcode
              country_code: $country_code
              telephone: $telephone
              fax: $fax
              prefix: $prefix
              save_in_address_book: false
              region_id: $region_id
              vat_id: $vat_id
            }
          }
        ]
      }
    ) {
      cart {
        shipping_addresses {
          prefix
          firstname
          lastname
          street
          city
          fax
          vat_id
          region {
            code
            label
          }
          country {
            code
            label
          }
          telephone
          company
          postcode
          available_shipping_methods {
            amount {
              currency
              value
            }
            image
            description
            method_title
            carrier_code
            method_code
          }
          selected_shipping_method {
            amount {
              value
              currency
            }
            method_title
            carrier_code
            method_code
    }
  }
      }
      cartPrices: cart {
        ...CartPricesFragment
      }
    }
  }
`

export const SET_CUSTOMER_BILLING_ADDRESS_ON_CART = gql`
mutation setCustomerBillingAddressOnCart(
  $cart_id: String!
  $customer_address_id: Int!
) {
  setBillingAddressOnCart(
    input: {
      cart_id: $cart_id
      billing_address:{customer_address_id: $customer_address_id }
    }
  ) {
      cart {
        billing_address {
          prefix
          firstname
          lastname
          street
          city
          region {
            code
            label
          }
          country {
            code
            label
          }
          telephone
          company
          fax
          postcode
        }
      }
    }
  }
`

export const SET_BILLING_ADDRESS_ON_CART = gql`
  mutation setBillingAddressOnCart(
    $cart_id: String!
    $firstname: String!
    $lastname: String!
    $telephone: String!
    $fax: String
    $prefix: String
    $company: String
    $country_code: String!
    $region_id: Int
    $postcode: String!
    $city: String!
    $street: [String]!
    $vat_id:String
  ) {
    setBillingAddressOnCart(
      input: {
        cart_id: $cart_id
        billing_address: {
          address: {
            firstname: $firstname
            lastname: $lastname
            company: $company
            street: $street
            city: $city
            region_id: $region_id
            postcode: $postcode
            country_code: $country_code
            telephone: $telephone
            fax: $fax
            prefix: $prefix
            save_in_address_book: false
            vat_id: $vat_id
          }
        }
      }
    ) {
      cart {
        billing_address {
          prefix
          firstname
          lastname
          street
          city
          vat_id
          region {
            code
            label
          }
          country {
            code
            label
          }
          telephone
          company
          fax
          postcode
        }
      }
    }
  }
`

export const SET_SHIPPING_METHOD_ON_CART = gql`
  mutation setShippingMethodsOnCart(
    $cart_id: String!
    $carrier_code: String!
    $method_code: String!
  ) {
    setShippingMethodsOnCart(
      input: {
        cart_id: $cart_id
        shipping_methods: [
          { carrier_code: $carrier_code, method_code: $method_code }
        ]
      }
    ) {
      __typename
    }
  }
`

export const SET_PAYMENT_METHOD = gql`
  mutation setPaymentMethodOnCart(
    $cart_id: String!
    $payment_method: PaymentMethodInput!
  ) {
    setPaymentMethodOnCart(
      input: { cart_id: $cart_id, payment_method: $payment_method }
    ) {
      cart {
        selected_payment_method {
          code
        }
      }
    }
  }
`

export const PLACE_ORDER = gql`
  mutation placeOrder($cart_id: String!) {
    placeOrder(input: { cart_id: $cart_id }) {
      order {
        order_entity_id
        order_number
        email
        firstname
        lastname
      }
    }
  }
`

export const SET_EMAIL_ON_GUEST_CART = gql`
  mutation setGuestEmailOnCart($cart_id: String!, $email: String!) {
    setGuestEmailOnCart(input: { cart_id: $cart_id, email: $email }) {
      cart {
        email
      }
    }
  }
`

export const APPLY_COUPON_TO_CART = gql`
  ${CART_FRAGMENT}
  ${CART_PRICES_FRAGMENT}
  mutation applyCouponToCart($cart_id: String!, $coupon_code: String!) {
    action: applyCouponToCart(
      input: { cart_id: $cart_id, coupon_code: $coupon_code }
    ) {
      cart {
        ...CartFragment
      }
      cartPrices: cart {
        ...CartPricesFragment
      }
    }
  }
`

export const REMOVE_COUPON_FROM_CART = gql`
  ${CART_FRAGMENT}
  ${CART_PRICES_FRAGMENT}
  mutation removeCouponFromCart($cart_id: String!) {
    action: removeCouponFromCart(input: { cart_id: $cart_id }) {
      cart {
        ...CartFragment
        __typename
      }
      cartPrices: cart {
        ...CartPricesFragment
        __typename
      }
    }
  }
`

//======================= PayPal =======================//

export const CREATE_PAYPAL_EXPRESS_TOKEN = gql`
  mutation createPaypalExpressToken($cart_id: String!) {
    createPaypalExpressToken(
      input: {
        cart_id: $cart_id
        code: "paypal_express"
        express_button: true
        urls: { return_url: "check-out", cancel_url: "check-out" }
      }
    ) {
      token
      paypal_urls {
        start
        edit
      }
    }
  }
`

export const UPDATE_CART_ITEM_WARRANTY_OPTION = gql`
  ${CART_FRAGMENT}
  ${CART_PRICES_FRAGMENT}
  mutation($cart_id: String!, $cart_item_uid: ID!, $warranty_option: String!) {
    action: updateCartItemWarrantyOption(
      input: {
        cart_id: $cart_id
        cart_item_uid: $cart_item_uid
        warranty_option: $warranty_option
      }
    ) {
      cart {
        ...CartFragment
      }
      cartPrices: cart {
        ...CartPricesFragment
      }
    }
  }
`

export const UPDATE_CART_INSURANCE = gql`
mutation updateCartInsurance(
  $cart_id: String!
  $item_value: String!
) {
  action: updateCartInsurance(
    input: {
      cart_id: $cart_id
       item_type: "insurance"
        item_value: $item_value
     
    }
  ) {
    cart {  
     __typename
    }
  }
}
`

export const UPDATE_CART_DONATION = gql`
${CART_FRAGMENT}
${CART_PRICES_FRAGMENT}
mutation updateCartDonation(
  $cart_id: String!
  $item_value: String!
) {
  action: updateCartDonation(
    input: {
      cart_id: $cart_id
       item_type: "donation"
        item_value: $item_value
     
    }
  ) {
    cart {
     ...CartFragment
    }
    cartPrices: cart {
      ...CartPricesFragment
      __typename
    }
  }
}
`

export const UPDATE_CART_ITEM_SHIPPING_OPTION = gql`
  ${CART_FRAGMENT}
  ${CART_PRICES_FRAGMENT}
  mutation updateCartItemShippingOption(
    $cart_id: String!
    $cart_item_uid: ID!
    $shipping_method: String!
    $warranty_option: String
  ) {
    action: updateCartItemShippingOption(
      input: {
        cart_id: $cart_id
        cart_item_uid: $cart_item_uid
        shipping_method: $shipping_method
        warranty_option: $warranty_option
      }
    ) {
      cart {
        ...CartFragment
        __typename
      }
      cartPrices: cart {
        ...CartPricesFragment
        __typename
      }
      __typename
    }
  }
`

export const UPDATE_CART_OLD_ITEM_BACK = gql`
  ${CART_FRAGMENT}
  ${CART_PRICES_FRAGMENT}
  mutation updateCartOldItemBack(
    $cart_id: String!
    $item_type: String!
    $item_value: String!
  ) {
    action: updateCartOldItemBack(
      input: {
        cart_id: $cart_id
        item_type: $item_type
        item_value: $item_value
      }
    ) {
      cart {
        ...CartFragment
        __typename
      }
      cartPrices: cart {
        ...CartPricesFragment
        __typename
      }
      __typename
    }
  }
`

export const SET_DILEVERY_DATE_AND_TIME = gql`
  mutation MpDeliveryTime(
    $cart_id: String!
    $delivery_date: String
    $delivery_time: String
    $house_security_code: String
    $delivery_comment: String
    $deliveryFloor_date: String
    $deliveryFloor_time: String
  ) {
    MpDeliveryTime(
      cart_id: $cart_id
      mp_delivery_time: {
        mp_delivery_date: $delivery_date
        mp_delivery_time: $delivery_time
        mp_house_security_code: $house_security_code
        mp_delivery_comment: $delivery_comment
        mp_floor_delivery_date: $deliveryFloor_date
        mp_floor_delivery_time: $deliveryFloor_time
      }
    )
  }
`

export const UPDATE_CART_ITEMS_SHIPPING_OPTION = gql`
  ${CART_FRAGMENT}
  ${CART_PRICES_FRAGMENT}
  mutation updateCartItemsShippingOption(
    $cart_id: String!
    $cart_item_uids: [ID]!
    $shipping_method: String!
    $warranty_option: String
  ) {
    action: updateCartItemsShippingOption(
      input: {
        cart_id: $cart_id
        cart_item_uids: $cart_item_uids
        shipping_method: $shipping_method
        warranty_option: $warranty_option
      }
    ) {
      cart {
        ...CartFragment
        __typename
      }
      cartPrices: cart {
        ...CartPricesFragment
        __typename
      }
      __typename
    }
  }
`

export const UPDATE_CART_STORE_CODE = gql`
  mutation updateCartStoreCode($cart_id: String!, $pickup_store_code: String!) {
    action: updateCartStoreCode(
      input: { cart_id: $cart_id, pickup_store_value: $pickup_store_code }
    ) {
      __typename
    }
  }
`

export const ADD_PROMO_ITEM = gql`
  mutation addPromoItem($cartId: String!, $promoItems: [amPromoItemInput!]!) {
    action: amPromoAddFreeGiftsToCart(
      cartId: $cartId
      promoItems: $promoItems
    ) {
      cart {
        __typename
      }
    }
  }
`

export const REMOVE_PROMO_ITEM = gql`
  mutation removePromoItem($cart_id: String!, $cart_item_id: Int!) {
    action: removeItemFromCart(
      input: { cart_id: $cart_id, cart_item_id: $cart_item_id }
    ) {
      cart {
        __typename
      }
    }
  }
`

export const WALLEE_SET_TRANSACTION_URLS = gql`
  mutation SetTransactionUrls(
    $cart_id: String!
    $success_url: String!
    $failure_url: String!
  ) {
    updateTransactionUrls(
      input: {
        cart_id: $cart_id
        success_url: $success_url
        failure_url: $failure_url
      }
    ) {
      result
    }
  }
`

export const CANCEL_WALLEE = gql`
  mutation cancelWallee($orderId: String!, $token: String!) {
  message:processFailure(input: { order_id: $orderId, token: $token })
}
`;


export const RESTORE_WALLEE = gql`
mutation RestoreQuote($cartId: String!) {
restoreQuote(input: {cart_id: $cartId}) {
  result
  cart_id
}
}
`

export const SUCCESS_WALLEE = gql`
  mutation successWallee($orderId: String!, $token: String!) {
    order:processSuccess(input: { order_id: $orderId, token: $token }){
      order_entity_id
      order_number
      email
      firstname
      lastname
      message
    }
}
`;

export const ADD_COMMENT_TO_CART = gql`
  mutation updateCartComment($cart_id: String!, $comment: String!) {
    action: updateCartComment(input: { cart_id: $cart_id, comment: $comment }) {
      __typename
    }
  }
`

export const ADD_NOTE_TO_CART = gql`
mutation addExtraOrderComments($cart_id: String!,$extra_comments:[ExtraOrderCommentInput]!){
addExtraOrderComments(
    input: {
      cart_id: $cart_id
      extra_comments: $extra_comments
    }
  ) {
    extra_comments {
        code
        value
    }
  }
}
`;