import { useContext } from 'react';
import { useRouter } from 'next/router';
import { LangContext } from '../Providers/Lang';

const useTranslation = () => {

    const { translations, dictionary, AddWordToDictionary } = useContext(LangContext)

    const router = useRouter()

    const t = (word) => {
        if (Object.keys(translations)?.length > 0) {
            if (translations[word]) {
                if (translations[word][router.locale])
                    return translations[word][router.locale]
                else
                    return word
            }
            else {
                if (process.env.NEXT_PUBLIC_SAVE_DICTIONARY !== "false" && process.env.NODE_ENV === "development" && (dictionary ? !dictionary[word] : true)) {
                    AddWordToDictionary(word)
                }
                return word
            }
        } else {
            return word
        }
    }

    return { t }
}

export default useTranslation;