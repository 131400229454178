import { useMutation, useQuery } from '@apollo/client';
import { useSnackbar } from 'notistack';
import React, { createContext, useEffect } from 'react';
import { useState } from 'react';
import { ADD_PRODUCT_TO_COMPARE_LIST, ASSIGN_COMPARE_LIST_TO_CUSTOMER, CREATE_COMPARE_LIST, REMOVE_PRODUCT_FROM_COMPARE_LIST, } from '../graphql/compareListsGraphql/mutaitions';
import { GET_COMPARE_LIST } from '../graphql/compareListsGraphql/queries';
import useTranslation from '../hooks/useTranslation';
import { useAuthStore } from '../stores/authStore';
import cookie from "react-cookies"
import { getCookie } from '../helpers/cookie';

export const CompareListContext = createContext({})

export const CompareListProvider = ({ children }) => {
	const cookieOption = { path: "/", maxAge: 23 * 60 * 60 }
	const isAuthenticated = useAuthStore(state => Boolean(state.token))
	const AuthReady = useAuthStore(state => state.ready)
	const token = useAuthStore(state => state.token)
	const [customerCompareListID, setCustomerCompareListID] = useState('undefined');
	const [guestCompareListID, setGuestCompareListID] = useState('undefined');
	const [compareList, setCompareList] = useState({})
	const [skip, setSkip] = useState(true)
	const [count, setCount] = useState(0)
	const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation()
	const [addRemoveLoading, setAddRemoveLoading] = useState("")

	/******************** compareList mutations and queries ***********************/
	const { data: data1 } = useQuery(GET_COMPARE_LIST, { variables: { uid: (isAuthenticated ? customerCompareListID : guestCompareListID) }, skip: skip, onError: () => { } })

	const [removeProductFromCompareList, { data: removeData, loading: removeProductFromComparelistLoading }] = useMutation(REMOVE_PRODUCT_FROM_COMPARE_LIST)
	const [addProductToComparelist, { data: addData, loading: addProductToComparelistLoading }] = useMutation(ADD_PRODUCT_TO_COMPARE_LIST)
	const [mergeCompareLists] = useMutation(ASSIGN_COMPARE_LIST_TO_CUSTOMER)
	console.log("fdgfdg");
	
	const [createEmptyCompareList, { data: createData }] = useMutation(CREATE_COMPARE_LIST, {
		onCompleted: (data) => {
			if (!isAuthenticated) {
				setGuestCompareListID(data.action.uid)
				cookie.save('guestCompareListID', data.action.uid, 2 * 60 * 60)
				setCustomerCompareListID('')
				cookie.save('customerCompareListID', '', cookieOption)
			} else {
				setGuestCompareListID("")
				cookie.save('guestCompareListID', '', 2 * 60 * 60)
				setCustomerCompareListID(data.action.uid)
				cookie.save('customerCompareListID', data.action.uid, cookieOption)
			}
			refetchData()
		}, onError: (error) => {
			setGuestCompareListID("")
			cookie.save('guestCompareListID', '', 2 * 60 * 60)
			setCustomerCompareListID("")
			cookie.save('customerCompareListID', '', cookieOption)
			console.log(error)
		}
	})



	useEffect(() => {
		const guestCID = getCookie('guestCompareListID')
		const customerCID = getCookie('customerCompareListID')
		setGuestCompareListID(guestCID)
		setCustomerCompareListID(customerCID)

		if (AuthReady) {
			if ((!isAuthenticated && !guestCID) || (isAuthenticated && !guestCID)) {
				createEmptyCompareList()
			}
			else if (isAuthenticated && guestCID) {
				mergeCompareLists({
					variables: {
						uid: guestCID,
					},
					onCompleted: (data) => {
						if (data.action?.compare_list?.uid) {
							setCustomerCompareListID(data.action.compare_list.uid)
							cookie.save('customerCompareListID', data.action.compare_list.uid, cookieOption)
							setGuestCompareListID('')
							cookie.save('guestCompareListID', '', 2 * 60 * 60)
							refetchData()
						} else {
							createEmptyCompareList()
						}
					},
					onError: (err) => {
						console.log(err.message)
						createEmptyCompareList()
					}
				})
			} else {
				refetchData()
			}
		}
	}, [isAuthenticated])

	useEffect(() => {
		if (data1) {
			setCompareList(data1.compareList)
			setSkip(true)
		}
	}, [data1])

	useEffect(() => {
		if (addData) {
			setCompareList(addData.action)
		}
	}, [addData])

	useEffect(() => {
		if (removeData) {
			setCompareList(removeData.action)
		}
	}, [removeData])

	useEffect(() => {
		if (createData)
			setCompareList(createData.action)
	}, [createData])

	const addToCompareList = (ProductId,) => {
		if (isCompared(ProductId)) {
			setAddRemoveLoading(ProductId)
			removeProductFromCompareList({
				variables: {
					uid: compareList.uid,
					productID: [ProductId]
				},
				onCompleted: (data) => {
					if (data) {
						setAddRemoveLoading("")
						enqueueSnackbar(t("Product was removed from comparison list"), { variant: 'success' });
					}
				},
				onError: (err) => {
					enqueueSnackbar(err.message, { variant: 'error' })
				}
			})
		}
		else {
			addProductToComparelist({
				variables: {
					uid: compareList.uid,
					productID: ProductId,
				},
				onCompleted: (data) => {
					if (data) {
						enqueueSnackbar(t("Product was added to compare list"), { variant: 'success' });
					}
				},
				onError: (err) => {
					enqueueSnackbar(t("An error occurred while adding the product to the comparison list"), { variant: 'error' })
				}
			});
		}
	}

	const refetchData = () => {
		setCount((prev) => prev + 1)
		setSkip(false)
	}

	const isCompared = (id) => {
		return compareList?.items?.filter(obj => obj.uid === String(id)).length > 0
	}
	// console.log(compareList?.uid);
	
	return (
		<CompareListContext.Provider value={{
			compareReady: Boolean(compareList?.uid),
			compareList,
			isCompared,
			addToCompareList,
			addProductToComparelist,
			removeProductFromCompareList,
			addProductToComparelistLoading,
			removeProductFromComparelistLoading,
			addRemoveLoading
		}} >
			{children}
		</CompareListContext.Provider>
	)
}

