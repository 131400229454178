import React from 'react'

const CheckCircleIcon = (props) => {
    return (
        <svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M0 13.3438C0 6.43945 5.5957 0.84375 12.5 0.84375C19.4043 0.84375 25 6.43945 25 13.3438C25 20.248 19.4043 25.8438 12.5 25.8438C5.5957 25.8438 0 20.248 0 13.3438ZM18.1543 11.1855C18.6865 10.6533 18.6865 9.78418 18.1543 9.25195C17.6221 8.71973 16.7529 8.71973 16.2207 9.25195L10.9375 14.5352L8.7793 12.377C8.24707 11.8447 7.37793 11.8447 6.8457 12.377C6.31348 12.9092 6.31348 13.7783 6.8457 14.3105L9.9707 17.4355C10.5029 17.9678 11.3721 17.9678 11.9043 17.4355L18.1543 11.1855Z" fill="currentcolor" />
        </svg>

    )
}

export default CheckCircleIcon