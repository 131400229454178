import { Box, Button, CircularProgress, Grid, Modal, Typography } from '@mui/material';
import useAddZeroes from '../../hooks/useAddZeros';
import useCss from '../../hooks/useCss';
import useTranslation from '../../hooks/useTranslation';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { useState, useEffect } from 'react';
import { useRouter } from "next/router";
import { calcSubTotalAndSmallorderfee } from '../../helpers/cart';

const PopUp = ({ sx, content = "", productInfo, storeData, button1, button2, open, setOpen, handleButton1 = () => { }, handleButton2 = () => { }, popupErrorMessage, title }) => {

	const handleClose = () => setOpen(false);
	const { c } = useCss()
	const { t } = useTranslation()
	const { z } = useAddZeroes()
	const router = useRouter()
	const [dis1, setDis1] = useState(false)
	const [dis2, setDis2] = useState(false)
	const { subTotal, smallorderfee } = calcSubTotalAndSmallorderfee(productInfo?.product?.price_range?.maximum_price?.final_price?.value, productInfo?.product?.custom_stock_item?.min_sale_qty || 1, productInfo?.cartPrices, productInfo?.items, storeData?.storeConfig)
	const borderColor = (storeData?.storeConfig?.black_friday_backend_enabled && storeData?.storeConfig?.black_friday_backend_color1) ?
		(storeData?.storeConfig?.black_friday_backend_color1.includes('gradient') ?
			"" : storeData?.storeConfig?.black_friday_backend_color1)
		: c('yellow')
	const borderImage = (storeData?.storeConfig?.black_friday_backend_enabled && storeData?.storeConfig?.black_friday_backend_color1) ?
		storeData?.storeConfig?.black_friday_backend_color1.includes('gradient') ?
			storeData?.storeConfig?.black_friday_backend_color1 + " 1" : ""
		: "unset"

	useEffect(() => {

		setDis1(!open)
		setDis2(!open)

	}, [open])

	console.log(productInfo);
	return (
		<Modal open={open} onClose={(e) => { handleClose(); e.stopPropagation() }} sx={{ outline: "none" }}>
			<Box sx={{
				width: { md: '60%', xs: '85%', lg: "50%", xl: "40%" }, left: '50%', position: "absolute", pb: 3,
				minHeight: { sm: '300px', xs: '40vh' }, boxShadow: 24, top: '50%',
				transform: 'translate(-50%, -50%)', bgcolor: 'white', borderRadius: c('borderRadius')
			}} >
				<Grid
					sx={{
						borderBottom: `2px solid ${borderColor}`,
						borderImage: borderImage,
						borderTopLeftRadius: c('borderRadius'), borderTopRightRadius: c('borderRadius'),
						minHeight: "80px", background: c('light-gray'), width: "100%", display: "flex", alignItems: "center", justifyContent: "center",
						p: { xs: 2, md: 1 }
					}}>
					<Typography variant='p' sx={{ fontWeight: "600", fontSize: { xs: "0.7rem", sm: "0.9rem" }, color: c('black') }}>
						{
							title ?
								title
								:
								productInfo.product?.name + t("Added to cart")
						}
					</Typography>
				</Grid>
				{content === "" ?
					<Grid container columns={{ xs: 1, sm: 2 }} sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center", px: 5, minHeight: "100px", my: 4, mt: 5 }}>
						<Grid item xs={1} sm={0.7}
							sx={{
								backgroundImage: `url(${productInfo?.product?.small_image?.url || productInfo?.product?.image?.url || productInfo?.product?.media_gallery?.at(0)?.url})`, backgroundSize: "contain",
								backgroundRepeat: "no-repeat", height: { sm: "160px", xs: '16vh' }
								, backgroundPosition: { xs: "center", sm: "unset" }
							}}
						>
						</Grid>
						<Grid item xs={1} sm={1.3} sx={{ pl: { xs: 0, sm: 4 } }}>
							<Typography item xs={1} variant='p' sx={{ fontSize: { md: "0.9rem", xs: "0.7rem", sm: "0.8rem" }, fontWeight: "700" }}>
								{t("CART TOTAL") + ': CHF '} {z(subTotal)}
							</Typography>
							{(productInfo.product.stock_color_label ? productInfo.product.stock_color_label !== "orange" : productInfo?.product?.custom_attributes.filter((obj) => obj?.selected_attribute_options?.attribute_option?.at(0)?.label === "orange").length <= 0) && smallorderfee > 0 ?
								<Grid sx={{ bgcolor: c("yellow"), p: 1, px: 2, textAlign: "left", borderRadius: "8px", display: "flex", justifyContent: "space-between", mt: { xs: 2, sm: 0 }, }}>
									<Box component="span" sx={{ fontSize: { xs: "0.8rem", sm: "1rem" }, fontWeight: "500", color: c("white") }}>
										{t("save small quantity surcharge")} ?
										<br />
										{t("still for") + " CHF " + z(smallorderfee) + " " + t("shopping")}
									</Box>
									<Box sx={{ alignItems: "center", display: "flex" }}>
										<ReportProblemIcon sx={{ fontSize: "2rem", color: c("white") }} />
									</Box>
								</Grid>
								:
								null
							}
							{
								(productInfo?.product?.custom_attributes?.filter((obj) => obj?.selected_attribute_options?.attribute_option?.at(0)?.label === "orange").length > 0 || productInfo.product.stock_color_label === "orange") ?
									<Grid sx={{ bgcolor: c("yellow"), p: 1, textAlign: "left", borderRadius: "8px", display: "flex", justifyContent: "space-between", mt: { xs: 2, sm: 0 }, alignItems: "center" }}>
										<Box component="span" sx={{ fontSize: { xs: "0.8rem", sm: "1rem" }, fontWeight: "500", color: c("white") }}>
											{(productInfo?.product?.custom_attributes?.filter((obj) => obj.attribute_metadata?.code === "stock_text").at(0)?.entered_attribute_value?.value || productInfo.product.stock_text)}
										</Box>
										<Box sx={{ alignItems: "center", display: "flex" }}>
											<ReportProblemIcon sx={{ fontSize: "2rem", color: c("white") }} />
										</Box>
									</Grid>
									:
									null
							}
							{popupErrorMessage !== "" ?
								<Grid sx={{ bgcolor: c("red"), p: 1, px: 2, textAlign: "left", borderRadius: "8px", display: "flex", alignItems: "center", justifyContent: "space-between", mt: 2 }}>
									<Box component="span" sx={{ fontSize: "1rem", fontWeight: "600", color: c("white") }}>
										{popupErrorMessage}
									</Box>
									<Box sx={{ alignItems: "center", display: "flex" }}>
										<ReportProblemIcon sx={{ fontSize: "2rem", color: c("white") }} />
									</Box>
								</Grid>
								:
								null
							}
						</Grid>

					</Grid>
					:
					/* ****************  CMS contents here  *************/
					<Grid sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center", px: router.asPath === "/check-out" ? 0 : 5, my: 4, maxHeight: "500px", minHeight: "150px", overflowY: "auto", ...sx }}>{content}</Grid>
				}
				<Grid container columns={{ sm: 2, xs: 1 }} sx={{ justifyContent: "center", alignItems: "center", px: { sm: 10, xs: 5 } }}>
					{button1 > "" ?
						<Grid item xs={1} px={2}>
							<Button onClick={() => { setOpen(false); handleButton1() }}
								sx={{
									backgroundColor: c('white'), borderRadius: '5px', color: c('black'),
									border: `1px solid ${c("dark-gray")}`, fontWeight: "600", width: "100%",
									':hover': { backgroundColor: c('gray'), borderColor: c("black") }, fontSize: { md: "0.9rem", xs: "0.7rem" }
								}}
								disabled={dis1}
							>
								{button1}
							</Button>
						</Grid>
						:
						null
					}
					{button2 > "" ?
						<Grid item xs={1} px={2} sx={{ display: "flex", justifyContent: "end", mt: { sm: 0, xs: 3 } }}>
							<Button
								onClick={(e) => {
									handleButton2();
									router.events.on("routeChangeComplete", () => {
										setOpen(false)
									});
								}}
								sx={{
									backgroundColor: c('blue'), borderRadius: '5px', color: c('white'),
									fontWeight: "600", width: "100%", ':hover': { backgroundColor: c('black') },
									fontSize: { md: "0.9rem", xs: "0.7rem" }, zIndex: 100000
								}}
							// disabled={addProductLoading || addBundleProductLoading || dis2}
							>
								{button2}
							</Button>
						</Grid>
						:
						null
					}
				</Grid>

			</Box>
		</Modal >
	)
}

export default PopUp