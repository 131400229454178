import { useEffect, useRef, useState } from "react";
import { Backdrop, Container, Divider, Grid, Popper, Typography } from '@mui/material';
import { useSpring, animated } from 'react-spring';
import useCss from '../../hooks/useCss';
import styles from '../../styles/header.module.scss';
import useTranslation from '../../hooks/useTranslation';
import Link from "next/link";
import { useGlobalStore } from "../../stores/globalStore";
import { useGesture } from 'react-use-gesture';
import { useCacheStore } from "../../stores/cacheStore";

const DesktopCatagoryMenu = ({ categoryList, specialCatData }) => {
	const { c } = useCss();
	const { t } = useTranslation();
	const storeConfig = useCacheStore(state => state.storeConfig);
	const [categories, setCategories] = useState([]);
	const [open, setOpen] = useState(false);
	const anchorEl = useRef();
	const El = useRef();
	let hideAfter;

	// Use react-spring for animation
	const menuAnimation = useSpring({
		opacity: open ? 1 : 0,
		transform: open ? 'translate3d(0,0,0)' : 'translate3d(100%,0,0)',
		from: { opacity: 0, transform: 'translate3d(100%,0,0)' },
		config: { tension: 300, friction: 26 },
	});

	const bind = useGesture({
		onDragEnd: ({ direction: [xDelta] }) => {
			if (xDelta > 100) {
				closeMenu();
			}
		},
	});

	const closeMenu = () => {
		hideAfter = setTimeout(() => {
			setOpen(false);
			const bodyElement = document.querySelector('body');
			document.getElementById('scroll_UpButton').style.right = "12px";
			document.getElementById('question_btn').style.right = "12px";
			bodyElement.style.paddingRight = "unset";
			bodyElement.style.overflowY = "scroll";
		}, 500);
	};

	const handleOpenPopper = () => {
		clearTimeout(hideAfter);
		const bodyElement = document.querySelector('body');
		document.getElementById('scroll_UpButton').style.right = "20px";
		document.getElementById('question_btn').style.right = "20px";
		bodyElement.style.overflowY = "hidden";
		bodyElement.style.paddingRight = "6px";
		setOpen(true);
	};

	const handleMouseLeave = (e) => {
		hideAfter = setTimeout(() => {
			closeMenu();
		}, 200);
	};

	const handleMouseEnter = () => {
		clearTimeout(hideAfter);
	};

	useEffect(() => {
		if (categoryList) {
			setCategories(categoryList);
		}
	}, [categoryList]);

	return (
		<Grid className="bg-light-gray" display={{ xs: "none", md: "block", lg: "block", xl: "block", sm: "none" }} {...bind()}>
			<Container maxWidth="xl">
				<Grid className={styles.menu_bar_left} display={"flex"} justifyContent="space-between" alignItems="center" height="45px">
					<Typography
						id="main-menu-caller"
						onMouseEnter={handleOpenPopper}
						onMouseLeave={handleMouseLeave}
						ref={anchorEl}
						variant='span'
						style={{ cursor: "pointer", display: "flex", alignItems: "center", height: "100%", width: "300px", position: "relative", paddingLeft: "1rem", zIndex: "1406" }}
						className={styles.mainCat + " " + (open ? styles.opened : "")}
						sx={{ borderTopLeftRadius: "3px", borderEndStartRadius: "3px", letterSpacing: 0.4, fontWeight: 600, fontSize: "0.9rem !important", my: 0.23 }}
					>
						{t("ALL CATEGORIES").toUpperCase()}
					</Typography>
					{
						open
							?
							<Backdrop
								sx={{ zIndex: (theme) => theme.zIndex.drawer + 120, backgroundColor: "rgba(0, 0, 0, 0.1) !important" }}
								open={open}
							>
								<animated.div style={menuAnimation}>
									<Popper
										id="menu-popper"
										open={open}
										onMouseEnter={handleMouseEnter}
										onMouseLeave={handleMouseLeave}
										anchorEl={anchorEl.current}
										placement="right-end"
										sx={{
											zIndex: 1406, borderTopRightRadius: "3px", borderEndStartRadius: "3px", borderBottomRightRadius: "3px",
											background: c('light-gray'),
											position: "fixed"
										}}
									>
										<Grid id="pop2" ref={El} sx={{ p: "4px 27px", width: "212px" }} >
											{categories.map((item, i) =>
												<Grid key={item.uid} >
													<Link href={`/${item.url_path}.html`} onClick={closeMenu} >
														<Typography variant="span" sx={{
															'&:hover': { color: c('dark-gray') },
															lineHeight: "40px", fontSize: "14px", fontWeight: "500", color: c('black')
														}}>
															{item.name}
														</Typography>
													</Link>
													{i < (categories.filter(cat => cat.include_in_menu).length - 1) ? <Divider /> : null}
												</Grid>
											)}
										</Grid>
									</Popper>
								</animated.div>
							</Backdrop>
							:
							null
					}
					<Grid className={styles.menu_bar}  >
						<Grid justifyContent="space-between">
							{
								specialCatData?.categoryListByAttribute
									?
									specialCatData.categoryListByAttribute.map((item) => {
										const blackFridaySx = (storeConfig?.black_friday_enabled && item.id == storeConfig?.black_friday_category_id) ?
											{
												background: storeConfig?.black_friday_backend_color1 + " !important",
												px: 2, py: 1, borderRadius: "7px",
												"& a": { color: storeConfig?.black_friday_color + " !important" },
												"&:hover": {
													background: storeConfig?.black_friday_backend_color2 + " !important",
												}
											}
											: {}

										return (
											<Typography variant="span" key={item.url_path} sx={{ fontWeight: 600, fontSize: "0.9rem", ...blackFridaySx }}>
												<Link href={`/${item.url_path}` + ".html"} >
													{item.name?.toUpperCase()}
												</Link>
											</Typography>
										)
									})
									: null
							}
						</Grid>
					</Grid>
				</Grid>
			</Container >
		</Grid >
	)
}

export default DesktopCatagoryMenu