export const withoutShippingMethods = (items) => {
    return realItems(items).filter(item => !item.selected_shipping_method_option.code > "") || []
}

export const heavyItems = (items) => {
    return items?.filter(item => item?.product?.extra_options?.shipping_method_list?.length > 0) || []
}

export const smallItems = (items) => {
    return realItems(items)?.filter(item => item?.product?.extra_options?.shipping_method_list?.length === 0).filter(item => (item.product.post_sperrgut !== 1 && item.product.post_sperrgut2 !== 1 && !item.product.ship_by_dpd_possible)) || []
}

export const selectedShippingMethodsSet = (items, addPosts = true) => {
    let array = realItems(items)?.map(item => item.selected_shipping_method_option).map(item => (addPosts && !item?.code) ? { code: "posts", label: "Post" } : item)
    if (!addPosts) array = array.filter(item => item?.code)
    let distinct = []
    let ret = []
    for (var i = 0; i < array.length; i++)
        if (!distinct.includes(array[i].code)) {
            distinct.push(array[i].code)
            ret.push(array[i])
        }
    return ret
}

export const realItems = (items, withoutSperrgutItems = false) => {

    if (withoutSperrgutItems)
        return sortItems(items?.filter(item => item.__typename !== "VirtualCartItem" && item.product.post_sperrgut !== 1 && item.product.post_sperrgut2 !== 1 && item.product.ship_by_dpd_possible !== 1))
    else
        return sortItems(items?.filter(item => item.__typename !== "VirtualCartItem"))
}



export const postShippingMethods = (cart, filter) => {
    let availables = cart?.shipping_addresses[0]?.available_shipping_methods?.filter(method => method?.method_code.includes("post_") && !method?.method_code.includes('post_spurget'))
    if (filter) availables = availables?.filter(method => filter(method))
    return availables?.map(method => { let temp = { ...method }; temp.fee = parseFloat(temp?.method_title.split('CHF')?.at(1) || "0"); return temp })
}


export const itemsByShippingOptions = ({ items, eq, startWith, include = "" }) => {
    if (eq === "posts")
        return sortItems(realItems(items).filter(item => !item.selected_shipping_method_option?.code && item.product.post_sperrgut !== 1 && item.product.post_sperrgut2 !== 1 && !item.product.ship_by_dpd_possible))
    else
        return sortItems(realItems(items).filter(item => item.selected_shipping_method_option?.code && (eq ? item.selected_shipping_method_option.code === eq : (startWith ? item.selected_shipping_method_option.code?.startsWith(startWith) : item.selected_shipping_method_option.code?.includes(include)))))
}

export const itemsByAvailableShippingMethod = ({ items, eq, startWith, include = "" }) => {
    return items.filter(item => item.product.extra_options?.shipping_method_list.filter(method =>
        (eq ? method.code === eq : (startWith ? method.code?.startsWith(startWith) : method.code?.includes(include)))).length > 0)
}

export const availableShippingMethodSet = (items) => {
    let distinct = []
    let itms = items.map(item => item.product.extra_options.shipping_method_list.filter(method => method.code !== "pickup")?.at(0))
    return itms.map(method => {
        if (distinct.includes(method.code))
            distinct.push(method.code)
        return method.code
    })
}

export const hasItemsNot = ({ items, eq, startWith, include = "" }) => {
    return (heavyItems(items).filter(item => (
        eq
            ?
            item.selected_shipping_method_option.code !== eq
            :
            (startWith
                ?
                !(item.selected_shipping_method_option.code.startsWith(startWith))
                :
                !(item.selected_shipping_method_option.code.includes(include))
            )
    )).length > 0)
}

export const sortItems = (items = []) => {
    return items.sort((a, b) => (a.product?.extra_options?.shipping_method_list?.length < b.product?.extra_options?.shipping_method_list?.length ? 1 : -1))
}

export const hasAssembledGroup = (items) => {
    return selectedShippingMethodsSet(items).filter(method => method.code.includes("sieber") || method.code.includes("pickup")).length > 0
}

export const stringToISO = (date) => {
    if (date > "") {
        let a = new Date()
        const [day, month, year] = date.split('-')
        a.day = day; a.month = month; a.year = year;
        return a.toISOString()
    }
    else
        return ""
}

export const getSelectedAddress = (address, addressArray, isShipping = true) => {
    let a = address
    let b = addressArray
    for (let i = 0; i < b?.length; i++) {
        if (
            a.firstname === b[i].firstname &&
            a.lastname === b[i].lastname &&
            a.company === b[i].company &&
            a.postcode === b[i].postcode &&
            a.telephone === b[i].telephone
        ) {
            return addressArray[i]
        }
    }
    return null
}

export const GetPostSperrgutItems = (items) => {
    return items.filter(item => (item.product.post_sperrgut === 1 || item.product.post_sperrgut2 === 1 || item.product.ship_by_dpd_possible === true))
}

export const HasOrangeProduct = (items) => {
    for (let i = 0; i < items?.length; i++) {
        for (let j = 0; j < items[i].product?.custom_attributes?.length; j++) {
            if (items[i].product?.custom_attributes[j]?.selected_attribute_options?.attribute_option?.at(0)?.label === "orange") {
                return true
            }
        }
    }
    return false
}

export const shippingImageUrl = (method, cart) => {
    let url = ""
    if (method.includes("sieber")) {
        url = cart.shipping_addresses?.at(0)?.available_shipping_methods?.filter(obj => obj.method_code.includes("sieber"))[0]?.image
    } else if (method.includes("post")) {
        url = cart.shipping_addresses?.at(0)?.available_shipping_methods?.filter(obj => obj.method_code.includes("post"))[0]?.image
    } else if (method.includes("floor")) {
        url = cart.shipping_addresses?.at(0)?.available_shipping_methods?.filter(obj => obj.method_code.includes("floor"))[0]?.image
    }

    if (url?.startsWith("https://")) {
        return url
    } else {
        return process.env.NEXT_PUBLIC_BASE_URL + url
    }
}

export const customeTimeCost = (cart, code, deliveryTimeData) => {
    if (code?.includes('sieber') && (cart.delivery_date_values.delivery_time_value > "" && !cart.delivery_date_values.delivery_time_value.includes('-')))
        return parseFloat(deliveryTimeData.customTimeDelivery)
    else if (code?.includes('floor') && !code.includes('installation') && (cart.delivery_date_values.floor_delivery_time_value > "" && !cart.delivery_date_values.floor_delivery_time_value.includes('-')))
        return parseFloat(deliveryTimeData.floorCustomTimeDelivery)
    else
        return 0
}

export const shippingMethodDesc = (cart, code) => {
    return cart.items.map(item => item.product.extra_options.shipping_method_list).flat().filter(method => method?.code === code)?.at(0)?.description
}


export const calcShippingCosts = (items) => {
    let subTotal = 0
    let flatRate = 0
    items.map(item => {
        subTotal += parseFloat(item.product?.extra_options.shipping_method_list.filter((method) => item.shipping_method_option === method.code)[0]?.price) * parseFloat(item.quantity)
        flatRate = Math.max(flatRate, parseFloat(item.product?.extra_options.shipping_method_list.filter((method) => item.shipping_method_option === method.code)[0]?.flat_price))
    })
    return { subTotal: subTotal, flatRate: flatRate, code: items?.at(0)?.selected_shipping_method_option?.code || "--" }

}

export const itemsByShippingMethodCount = ({ items, eq, startWith, include = "" }) => {
    let count = 0
    if (eq === "posts")
        return 0
    else
        realItems(items).filter(item =>
            item.selected_shipping_method_option?.code &&
            (eq ?
                item.selected_shipping_method_option.code === eq
                :
                (startWith ?
                    item.selected_shipping_method_option.code?.startsWith(startWith)
                    :
                    item.selected_shipping_method_option.code?.includes(include)
                )
            )
        ).map(product => count += product.quantity)

    return count
}
export const PostSperrgutItemsCount = (items) => {
    let count = 0
    items.filter(item => (item.product.post_sperrgut === 1 || item.product.post_sperrgut2 === 1 || item.product.ship_by_dpd_possible === true)).map(product => count += product.quantity)
    return count
}
export const smallItemsCount = (items) => {
    let count = 0
    const res = realItems(items)?.filter(item => item?.product?.extra_options?.shipping_method_list?.length === 0).filter(item => (item.product.post_sperrgut !== 1 && item.product.post_sperrgut2 !== 1 && !item.product.ship_by_dpd_possible)
    )

    res.map(product => count += product.quantity)

    return count
}

export const calcSubTotalAndSmallorderfee = (price, count = 1, cartPrices, items, storeConfig) => {
    const discount = storeConfig?.small_order_fee_before_discount ? 0 : (cartPrices?.discount || 0)
    const donate = items?.filter((item) => item.product.sku === "donation")?.at(0)?.prices?.row_total_including_tax?.value || 0
    const oldItemPrice = items?.filter((item) => item.product.sku === "giveolditemback")[0]?.prices?.row_total_including_tax?.value || 0
    const insurancePrice = realItems(items).length > 0 ? items?.filter((item) => item.product.sku === "insurance")[0]?.prices?.row_total_including_tax?.value || 0 : 0
    const subTotal = cartPrices?.subtotal_including_tax?.value + (price * count) - (oldItemPrice + insurancePrice + donate)
    const smallorderfee = storeConfig?.small_order_fee_min - (subTotal + discount)
    if (!storeConfig || !storeConfig?.small_order_fee_enabled)
        return { subTotal, smallorderfee: 0 }
    else
        return { subTotal, smallorderfee: (smallorderfee > 0) ? Number(smallorderfee.toFixed(2)) : 0 }
}