import { gql } from "@apollo/client"
import { WISHLIST_FRAGMENT_FOR_MUTATIONS } from "./fragments";


export const ADD_PRODUCT_TO_WISH_LIST = gql`
${WISHLIST_FRAGMENT_FOR_MUTATIONS}
mutation addProductsToWishlist(
  $wishlistId: ID!
  $sku: String!
  $quantity: Float!
) {
  action:addProductsToWishlist(
    wishlistId: $wishlistId
    wishlistItems: [{ sku: $sku, quantity: $quantity }]
  ) {
    wishlist {
      ...WishlistsFragmentM
    }
  }
}
`

export const REMOVE_PRODUCT_FROM_WISHLIST = gql`
${WISHLIST_FRAGMENT_FOR_MUTATIONS}
mutation removeProductsFromWishlist($wishlistId: ID!, $id: ID!) {
  action:removeProductsFromWishlist(wishlistId: $wishlistId, wishlistItemsIds: [$id]) {
    wishlist {
      ...WishlistsFragmentM
    }
  }
}
`;

export const UPDATE_WISHLIST = gql`
${WISHLIST_FRAGMENT_FOR_MUTATIONS}
mutation updateProductsInWishlist(
  $wishlistId: ID!
  $quantity: Float!
  $id: ID!
) {
  action:updateProductsInWishlist(
    wishlistId: $wishlistId
    wishlistItems: [{ wishlist_item_id: $id, quantity: $quantity }]
  ) {
    wishlist {
      ...WishlistsFragmentM
    }
  }
}
`;



