import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import { cookieStorage } from "./cookieStorage";

interface CookiesStoreType {
  showCookieBar: boolean;
  cookieOptions: { [x: string]: boolean };
  setCookieOptions: (appliedCookies: { [x: string]: boolean }) => void;
}

export const useCookieSettingsStore = create<CookiesStoreType>()(
  persist(
    (set) => ({
      showCookieBar: true,
      cookieOptions: {},
      setCookieOptions: (cookieOptions) => {
        set(() => ({ cookieOptions , showCookieBar:false }));
      },
    }),
    {
      storage: createJSONStorage(() => cookieStorage),
      name: "cookieOptions",
      partialize: (state) => ({ cookieOptions:state.cookieOptions , showCookieBar: state.showCookieBar }),
    }
  )
);
