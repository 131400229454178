import { gql } from "@apollo/client"
import { COMPARE_LIST_FRAGMENT } from "./fragments"

export const CREATE_COMPARE_LIST = gql`
${COMPARE_LIST_FRAGMENT}
mutation createCompareList {
  action:createCompareList{
    ...compareListFragment
  }
}
`
export const ADD_PRODUCT_TO_COMPARE_LIST = gql`
${COMPARE_LIST_FRAGMENT}
mutation addProductsToCompareList($productID: ID!, $uid: ID!) {
  action: addProductsToCompareList(
    input: { products: [$productID], uid: $uid }
  ) {
    ...compareListFragment
  }
}
`
export const REMOVE_PRODUCT_FROM_COMPARE_LIST = gql`
${COMPARE_LIST_FRAGMENT}
mutation removeProductsFromCompareList($productID: [ID]!, $uid: ID!) {
  action: removeProductsFromCompareList(
    input: { products: $productID, uid: $uid }
  ) {
    ...compareListFragment
  }
}
`

export const ASSIGN_COMPARE_LIST_TO_CUSTOMER = gql`
${COMPARE_LIST_FRAGMENT}
mutation assignCompareListToCustomer($uid: ID!) {
  action:assignCompareListToCustomer(uid: $uid) {
    compare_list {
      ...compareListFragment
    }
  }
}
`
