import { create } from "zustand"
import { mountStoreDevtool } from "simple-zustand-devtools"

interface GlobalStoreType {
  urls: string[]
  addUrl: ( url: string ) => void
  pastUrl: () => string
  pickup: string
  setPickup: ( id: string ) => void
  invoiceShipmentToPrint: any
  setInvoiceShipmentToPrint: ( invoiceShipmentToPrint: any, invoiceShipmentToPrintType: string ) => void,
  invoiceShipmentToPrintType: any
  storeConfig: any
  setStoreConfig: ( config: any ) => void
  guestEmail: string | null
  setGuestEmail: ( email: string | null ) => void
}

export const useGlobalStore = create<GlobalStoreType>()(
  ( set, get ) => ( {
    urls: [ '' ],
    addUrl: ( url: string ) => {
      if ( url !== get().urls[ 0 ] )
      {
        let arr: string[] = get().urls
        arr.push( url )
        set( () => ( { urls: arr.slice( 0, 2 ) } ) )
      }
    },
    pastUrl: () => {
      return get().urls.at( 1 ) || ""
    },
    pickup: "",
    setPickup: ( id ) => {
      set( () => ( { pickup: id } ) )
    },
    invoiceShipmentToPrint: null,
    setInvoiceShipmentToPrint: ( invoiceShipmentToPrint, invoiceShipmentToPrintType ) => {
      set( () => ( { invoiceShipmentToPrint, invoiceShipmentToPrintType } ) )
    },
    invoiceShipmentToPrintType: null,
    storeConfig: null,
    setStoreConfig: ( config ) => {
      set( () => ( { storeConfig: config } ) )
    },
    guestEmail: null,
    setGuestEmail: ( email ) => {
      set( () => ( { guestEmail: email || "" } ) )
    }
  } )
)

if ( process.env.NODE_ENV === "development" )
{
  mountStoreDevtool( "globalStore", useGlobalStore )
}
