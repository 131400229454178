import { Typography } from "@mui/material";
import { useRouter } from "next/router";
import { useContext } from "react";
import { CustomerContext } from "../../Providers/CustomerProvider";

const UserName = ({ styles }) => {
    const router = useRouter()
    const { customerData } = useContext(CustomerContext)

    return (
        <Typography sx={{fontSize:"10.5px", textTransform: "capitalize",position: "absolute",
        top: "100%",
        marginTop: "4px",
        width: "fit-content",
        whiteSpace: "nowrap" }} variant="span" id={styles.user_name} onClick={() => { router.push('/customer/account/my-dashboard.html') }} >{customerData?.firstname}</Typography>
    );
}

export default UserName;