import { gql } from "@apollo/client";
import { ADDRESS_FRAGMENT, CUSTOMER_FRAGMENT } from "./fragments";

export const CREATE_CUSTOMER = gql`
mutation createCustomerV2(
  $firstname: String!
  $lastname: String!
  $email: String!
  $password: String!
  $prefix: String
) {
  createCustomerV2(
    input: {
      firstname: $firstname
      lastname: $lastname
      email: $email
      password: $password
      prefix: $prefix
    }
  ) {
    __typename
  }
}
`;

export const UPDATE_CUSTOMER = gql`
${CUSTOMER_FRAGMENT}
mutation updateCustomerV2(
  $firstname: String!
  $lastname: String!
  $gender: Int
  $is_subscribed: Boolean
) {
  action:updateCustomerV2(
    input: {
      firstname: $firstname
      lastname: $lastname
      gender: $gender
      is_subscribed: $is_subscribed
    }
  ) {
    customer{
        ...CustomerFragment
    }
  }
}
`;

export const UPDATE_CUSTOMER_EMAIL = gql`
${CUSTOMER_FRAGMENT}
mutation updateCustomerEmail($email:String!,$password:String!){
  action:updateCustomerEmail(email:$email,password:$password){
    customer{
        ...CustomerFragment
    }
  }
}
`;

export const CREATE_CUSTOMER_ADDRESS = gql`
${ADDRESS_FRAGMENT}
mutation createCustomerAddress(
  $suffix:String
  $firstname: String!
  $lastname: String!
  $telephone: String!
  $fax: String
  $company: String
  $country_code: CountryCodeEnum!
  $postcode: String!
  $city: String
  $prefix: String
  $street: [String]
  $default_shipping: Boolean = false
  $default_billing: Boolean = false
  $vat_id: String
) {
  action:createCustomerAddress(
    input: {
      suffix:$suffix
      firstname: $firstname
      lastname: $lastname
      telephone: $telephone
      fax: $fax
      company: $company
      country_code: $country_code
      postcode: $postcode
      city: $city
      prefix: $prefix
      street: $street
      default_shipping: $default_shipping
      default_billing: $default_billing
      vat_id: $vat_id
    }
  ) {
    ...AddressFragment
  }
}
`;

export const UPDATE_CUSTOMER_ADDRESS = gql`
${ADDRESS_FRAGMENT}
mutation updateCustomerAddress(
  $id: Int!
  $suffix:String
  $firstname: String!
  $lastname: String!
  $telephone: String!
  $fax: String
  $company: String
  $country_code: CountryCodeEnum
  $postcode: String!
  $city: String
  $prefix: String
  $street: [String]
  $default_shipping: Boolean
  $default_billing: Boolean
  $vat_id: String
) {
  action:updateCustomerAddress(
    id: $id
    input: {
      suffix:$suffix
      firstname: $firstname
      lastname: $lastname
      telephone: $telephone
      fax: $fax
      company: $company
      country_code: $country_code
      postcode: $postcode
      city: $city
      prefix: $prefix
      street: $street
      vat_id: $vat_id
      default_shipping: $default_shipping
      default_billing: $default_billing
    }
  ) {
    ...AddressFragment
  }
}
`;

export const DELETE_CUSTOMER_ADDRESS = gql`
mutation deleteCustomerAddress($ID:Int!){
  action:deleteCustomerAddress(id:$ID)
}
`;

export const CHANGE_CUSTOMER_PASSWORD = gql`
mutation changeCustomerPassword($currPass:String!,$newPass:String!){
  changeCustomerPassword(currentPassword:$currPass,newPassword:$newPass){
    __typename
  }
}`;

export const SUBSCRIBE_EMAIL_TO_LETTER = gql`
mutation subscribeEmailToNewsletter($email: String!){
  action:subscribeEmailToNewsletter(
    email: $email
  ) {
    status
  }
}
`;

export const CONFIRM_SUBSCRIBE_EMAIL = gql`
mutation confirmSubscriptionToNewsletter($id: String!, $code: String!) {
  action: confirmSubscriptionToNewsletter(confirm_id: $id, code: $code) {
    status
    message
  }
}`;